<template>
  <div class="stop">
    <div class="stop__search">
      <input type="text" class="form-control w-50" placeholder="請求No、ファイル名" v-model="queries.keyword" maxlength="100" />
      <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
        <font-awesome-icon icon="search" />
      </button>
      <b-button variant="warning" size="md" :disabled="searchItems.length === 0" v-on:click="deleteAllHistory">全ての履歴を削除</b-button>
    </div>
    <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:5%"></th>
          <th class="text-center" style="width:10%">区分</th>
          <th class="text-center" style="width:20%">請求No</th>
          <th class="text-center" style="width:30%">ファイル名</th>
          <th class="text-center" style="width:15%">日時</th>
          <th class="text-center" style="width:15%">担当者</th>
          <th class="text-center" style="width:5%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in searchItems" :key="`${index}`">
          <td class="text-center" v-if="item.transferType === 1 && item.isError">
            <font-awesome-icon icon="download" />
          </td>
          <td class="text-center" v-else>
            <a href="javascript:;" @click="downloadAttachment(item)">
              <font-awesome-icon icon="download" />
            </a>
          </td>
          <td :class="{'text-danger': item.isError}" v-if="item.transferType === 0">アップロード</td>
          <td :class="{'text-danger': item.isError}" v-if="item.transferType === 1">データダウンロード</td>
          <td :class="{'text-danger': item.isError}">{{ item.requestNo }}</td>
          <td :class="{'text-danger': item.isError}">{{ item.fileName }}</td>
          <td :class="{'text-danger': item.isError}">{{ item.createDate }}</td>
          <td :class="{'text-danger': item.isError}">{{ item.createName }}</td>
          <td class="text-center">
            <a href="javascript:;" @click.prevent="deleteHistory(item)">
              <font-awesome-icon icon="trash-alt" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="searchItems.length === 0" class="text-left">
      <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
    </div>
    <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.stop {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.stop__search {
  display: flex;
  margin: 10px;
  :last-child {
    margin-left: auto;
  }
}

</style>

<script>
import TransferDataManagement from '@/model/TransferDataManagement'
import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"

export default {
  components: { Pagination, LoadingList },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: false,
      searchItems: [],
      queries: {
        keyword: ''
      }
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.getSearchItems(true);
  },
  methods: {
    getSearchItems(loadAll = false) {
      // mock
      this.loadedList = false;
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }

      TransferDataManagement.getHistoryDownloadAndUpload({ 
        pagination: this.pagination, 
        queries: {keyWord: this.queries.keyword} })
      .then(response => {
        this.searchItems = response.data.items;
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
      this.loadedList = true;
    },
    downloadAttachment(item) {
      TransferDataManagement.download(item.fileCode).then(response => {
        window.location = response.data.data.url;
      })
      .catch(() =>{
      });
    },
    deleteHistory(item) {
      this.$swal.confirm("Do you want delete \"" + item.fileName + "\"?", undefined, () => {
          TransferDataManagement.deleteHistoryDownloadAndUpload(item.transferFileId).then(() => {
            this.$swal.success(undefined,"Deleted!");
            this.getSearchItems();
          })
          .catch(() =>{
          });
      });
    },
    deleteAllHistory() {
      this.$swal.confirm("Do you want delete all?", undefined, () => {
          TransferDataManagement.deleteAllHistoryDownloadAndUpload().then(() => {
            this.$swal.success(undefined,"Deleted all!");
            this.getSearchItems();
          })
          .catch(() =>{
          });
      });
    }
  }
}
</script>