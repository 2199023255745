<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="退会情報の入力"/>
    <PageTitle title="退会情報の入力"></PageTitle>
    <div class="bg-light-blue mx-4 mt-2 p-3">
      <div>
        <p>openから既存への切替：{{isChangeContractStatus ? 'あり' : 'なし'}}</p>
        <p v-if="isChangeContractStatus">初回の切替：{{ changedContractDate ? $moment(changedContractDate).format('yyyy年MM月DD日') : '' }}  バイヤ：{{ buyerList }}</p>
        <p v-if="isChangeContractStatus">既存化による最短退会日：{{ shortestWithdrawalDate ? $moment(shortestWithdrawalDate).format('yyyy年MM月DD日') : '' }}</p>
        <div class="form-item">
          <label>退会日</label>
          <div>
            <datepicker 
              v-model="withdrawDate" required typeable
              :input-class="{'withdraw__input_withdraw_date_form form-control': true, 'has-error': hasErrorDate}"
              @selected="hasErrorDate = false; $forceUpdate();"
              @input="hasErrorDate = false; $forceUpdate();"
              :openDate="shortestWithdrawalDate ? shortestWithdrawalDate : new Date()"
              :disabledDates="{ to: shortestWithdrawalDate }">
            </datepicker> 
            <div class="text-danger text-left small" v-show="hasErrorDate">必須項目です。</div>
            <div>最短退会日以前の日付は指定できません。</div>
          </div>
        </div>
      </div>
      <b-alert class="my-4" show variant="warning">
        <p>退会日は以下の条件を満たす必要があります。</p>
        <p>・退会申込の到着日から3ヵ月以降の月末日</p>
        <p>・openから既存に変わったことがある場合は、初回の変更日から12ヵ月以降の日付</p>
      </b-alert>
      
      <div class="form-item">
        <label>サプライヤへの連絡事項</label>
        <div>
          <p>退会日を通知するメールに入ります。</p>
          <b-form-textarea rows="5" v-model="infoToSupplier"></b-form-textarea>
        </div>
      </div>
      
      <div class="mt-4">
        <b-button variant="danger" size="md" v-on:click="onWithdraw">保存</b-button>
        <b-button class="ml-2" variant="light" size="md" :to="{ name : 'SupplierDetailWithdraw', params: { supplierCode: this.supplierCode} }">キャンセル</b-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/deep/ .has-error {
  border-color: #bf1601 !important;
}
</style>

<script>
import SupplierWithdrawal from "@/model/SupplierWithdrawal";
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  data() {
    return {
      isChangeContractStatus: true,
      changedContractDate: '',
      buyerList: '',
      shortestWithdrawalDate: '',
      withdrawDate: '',
      infoToSupplier: '',
      supplierId: '',
      supplierCode: this.$route.params.supplierCode,
      hasErrorDate: false
    }
  },
  props: {},
  components: { PageTitle, CompanySimpleHeader },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      SupplierWithdrawal.loadEditWithdrawal(this.supplierCode)
      .then(Response => {
        this.supplierId = Response.data.supplierId;
        this.shortestWithdrawalDate = Response.data.shortestWithdrawalDate;
        // convert string to date
        this.shortestWithdrawalDate = new Date(
          this.shortestWithdrawalDate.substring(0,4), 
          this.shortestWithdrawalDate.substring(4,6) - 1, 
          this.shortestWithdrawalDate.substring(6,8)
        );
        this.isChangeContractStatus = Response.data.isChangeContractStatus;
        this.changedContractDate = Response.data.changedContractDate;
        for (let buyer of Response.data.buyerList) {
          this.buyerList += '[' + buyer.displayCode + ']' + (buyer.name ? buyer.name : '');
          if (Response.data.buyerList.indexOf(buyer) != Response.data.buyerList.length - 1) {
            this.buyerList += ', ';
          } else {
            this.buyerList += '';
          }
        }
      })
      .catch(reason => {
        this.$errorMessage(reason);
      });
    },
    onWithdraw() {
      if (!this.withdrawDate) {
        return this.hasErrorDate = true;
      }
      this.$swal.confirm("退会手続きを実行してもよろしいでしょうか？", undefined, () => {
        SupplierWithdrawal.executeWithdraw({
          supplierId: this.supplierId,
          supplierCode: this.supplierCode,
          withdrawDate: this.$moment(this.withdrawDate).format('yyyyMMDD'),
          infoToSupplier: this.infoToSupplier
        })
        .then(() => {
          this.$swal.success("退会手続きが完了しました", undefined, () => {
            this.$router.push({ name: "SupplierDetailWithdraw", params: { supplierCode: this.supplierCode} });  
          });
        })
        .catch(reason => {
          this.$errorMessage(reason);
        });
      });
    }
  }
}
</script>