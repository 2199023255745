<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="署名者情報"/>
    <PageTitle title="署名者情報"></PageTitle>
    <div class="m-4 p-3 bg-light-blue">
      <div v-if="signer.updatedAt">
        {{signer.updatedAt}} に登録されました。
      </div>
      <div v-if="!signer.updatedAt">
        <p>登録情報がありません</p>
        <b-button variant="primary" size="md" v-on:click="request">催促メールを送る</b-button>
      </div>
      <div v-if="signer.updatedAt">
        <div class="form-item">
          <label>役職 ※</label>
          <div>{{signer.main.jobTitle}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(漢字) ※</label>
          <div>{{signer.main.firstName}} {{signer.main.lastName}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(カナ) ※</label>
          <div>{{signer.main.firstNameKana}} {{signer.main.lastNameKana}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(英字) ※</label>
          <div>{{signer.main.firstNameEn}} {{signer.main.lastNameEn}}</div>
        </div>
        <div class="form-item">
          <label>署名者email ※</label>
          <div>{{signer.main.email}}</div>
        </div>
        <div class="form-item">
          <label>電話番号</label>
          <div>{{signer.main.mobilePhone}}</div>
        </div>
        <hr/>
        <h5 class="mb-2">署名代行者</h5>
        
        <div class="form-item">
          <label>役職 ※</label>
          <div>{{signer.sub.jobTitle}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(漢字) ※</label>
          <div>{{signer.sub.firstName}} {{signer.sub.lastName}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(カナ) ※</label>
          <div>{{signer.sub.firstNameKana}} {{signer.sub.lastNameKana}}</div>
        </div>
        <div class="form-item">
          <label>署名者名(英字) ※</label>
          <div>{{signer.sub.firstNameEn}} {{signer.sub.lastNameEn}}</div>
        </div>
        <div class="form-item">
          <label>電話番号</label>
          <div>{{signer.sub.mobilePhone}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>
import SupplierSigner from '@/model/SupplierSigner'
import CompanySimpleHeader from "@/components/organism/CompanySimpleHeader.vue"
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle, CompanySimpleHeader },
  data: () => {
    return {
      signer: {},
    }
  },
  mounted() {
    this.getSigner()
  },
  methods: {
    getSigner() {
      Promise.all([
        SupplierSigner.get(this.$route.params.supplierCode),
      ]).then((results) => {
        if (!results[0].data.length) this.signer = {}
        this.signer = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    request() {
      this.$swal.confirm("催促メールを送信します。よろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierSigner.request(this.$route.params.supplierCode)
        ]).then(() => {
          this.$swal.success("送信しました", undefined, () => {});
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    }
  }
}
</script>