import Model from './_base';

export default class Login extends Model {
    static entity = 'login';
    
    constructor() {
        super(Login.entity);
        this.companyCode = "";
        this.email = "";
        this.password = "";
    }

    static login(data){
      return Model.axios.post(this.urlServer(""), data);
    }
}