import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state:{
    logged: false,
    accessToken: "",
    user: {},
    supplier: {},
    pages: {},
    isMainMenuOpen: false
  },
  mutations: {
    loginAsRegistering(state, { accessToken }){
      state.accessToken = accessToken;
    },
    login(state, { accessToken, user }) {
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.user = user;
      state.logged = true;
      state.status = user.status;
    },
    logout(state) {
      state.logged = false
      state.user = {}
      state.supplier = {}
      state.pages = {}
    },
    supplier(state, supplier) { 
      state.supplier = {
        supplierCode: supplier.supplierCode,
        supplierDisplayCode: supplier.supplierDisplayCode,
        supplierName: supplier.supplierName,
        isMembership: supplier.isMembership,
      }
    },
    pages(state, data) {
      state.pages = Object.assign({}, state.pages, data)
    },
  },
  actions: {

  }

});