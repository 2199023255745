<template>
  <div>
    <PageTitle title="サプライヤー一覧"></PageTitle>
    <div class="supplier-list">
      <nav class="nav nav-tabs border-bottom-0">
        <router-link class="supplier-list__menu" active-class="supplier-list__menu--active" :to="{ name: 'SupplierList_All' }">すべて</router-link>
        <router-link class="supplier-list__menu" active-class="supplier-list__menu--active" :to="{ name: 'SupplierList_RegisterStatus' }">登録進捗</router-link>
        <!-- 
        <router-link class="supplier-list__menu" active-class="supplier-list__menu--active" :to="{ name: 'SupplierList_AutoVerificationNg' }">自動検出NG</router-link>
        <router-link class="supplier-list__menu" active-class="supplier-list__menu--active" :to="{ name: 'SupplierList_TsrExistence' }">TSR情報有無</router-link>
        <router-link class="supplier-list__menu" active-class="supplier-list__menu--active" :to="{ name: 'SupplierList_AntiCompanyCheckResult' }">反社チェック結果</router-link> 
        -->
      </nav>
      <router-view :print="this.print" :saveSupplier="this.saveSupplier"></router-view>
    </div>
    <SupplierAddressPrint :supplierCodes="printTargetSupplierCodes" />
  </div>
</template>

<style scoped lang="scss">

.supplier-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.supplier-list__menu {
  display: block;
  border: 1px solid $very-light-gray;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  color: $black;
  text-decoration: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.supplier-list__menu:not(.supplier-list__menu--active) {
  margin: 10px 0 -1px 0; 
}
.supplier-list__menu--active {
  background-color: $blue; 
}

</style>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
import SupplierAddressPrint from "@/components/organism/SupplierAddressPrint.vue"

export default {
  components: { PageTitle, SupplierAddressPrint },
  data: () => {
    return {
      printTargetSupplierCodes: []
    }
  },
  methods: {
    print(supplierCodes) {
      this.printTargetSupplierCodes = supplierCodes
    },
    saveSupplier(supplier) {
      this.$store.commit('supplier', supplier)
    }
  }
}
</script>