<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="代金振込口座情報"/>
    <div class="row">
      <div class="col-auto">
        <PageTitle title="代金振込口座情報"></PageTitle>
      </div>
      <div class="col pt-3">
        
        <template v-if="registerdBank.checkStatus == 'NONE'">
           <b-badge>未登録</b-badge>
        </template>
        <template v-if="registerdBank.checkStatus == 'REGISTERED' || registerdBank.checkStatus == 'INITIALIZE'">
          <div></div>
        </template>
        <template v-if="registerdBank.checkStatus == 'CONFIRMED' || registerdBank.checkStatus == 'CHANGED'">
          <b-badge variant="warning">未承認</b-badge>
        </template>
        <template v-if="registerdBank.checkStatus == 'APPROVED'">
          <b-badge variant="success">承認済み</b-badge>
        </template>
      </div>
    </div>
    <div class="m-2 mx-4">
      サプライヤが入力した、代金振込先口座情報です。
    </div>
    <div class="m-4 p-3 bg-light-blue">
      <h5>口座情報</h5>
      <div class="p-2" v-if="registerdBank.checkStatus == 'NONE'">
        登録情報がありません
      </div>
      <div class="px-3 mt-2" v-if="registerdBank.checkStatus != 'NONE'">
        <b-form-radio-group disabled="disabled" v-model="registerdBank.bankType" :options="bankType" />
        <div class="form-item">
          <label>銀行番号</label><div>{{registerdBank.bankCode}}</div> 
        </div>
        <div class="form-item">
          <label>銀行名</label><div>{{registerdBank.bankName}}</div>
        </div>
        <div class="form-item">
          <label>支店番号</label><div>{{registerdBank.branchCode}}</div>
        </div>
        <div class="form-item">
          <label>支店名</label><div>{{registerdBank.branchName}}</div>
        </div>
        <div class="form-item">
          <b-form-radio-group disabled="disabled" v-model="registerdBank.accountType" :options="accountType" />
        </div>
        <div class="form-item">
          <label>口座番号</label><div>{{registerdBank.accountNumber}}</div>
        </div>
        <div class="form-item">
          <label>口座名義</label><div>{{registerdBank.accountName}}</div>
        </div>
        <div class="form-item">
          <label>口座名義カナ</label><div>{{registerdBank.accountNameKana}}</div>
        </div>
        <div class="text-center mt-5">
          <template v-if="registerdBank.checkStatus == 'CHANGED'">
            <b-button variant="primary" size="lg" v-on:click="onConfirm">
              <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
              確認済みにする
            </b-button>
          </template>
          <template>
            <b-button v-if="registerdBank.checkStatus == 'CONFIRMED'" variant="primary" size="lg" v-on:click="onApprove">
              <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
              承認済みにする
            </b-button>
          </template>
        </div>
      </div>
    </div>
    <PageTitle title="会費振替口座情報"></PageTitle>
    <div class="m-2 mx-4">
      サプライヤから回収した口座振替情報を記入ください。
    </div>
    <div class="m-4 bg-light-blue p-3">
      <h5>振替情報</h5>
      <div v-if="registerdBank.checkStatus == 'NONE'">
        口座情報がないため会費振替を登録することができません
      </div>
      <div v-if="registerdBank.checkStatus != 'NONE'">
        <b-form-radio-group v-model="transferInputType"
                            :options="transferInputTypeList"
                            v-on:change="changeTransferInputType" />
        <b-form-radio-group v-bind:disabled="transferInputType == 'COPY'"
                            v-model="transferBank.bankType"
                            :options="bankType" />
        <div class="form-item" v-if="transferBank.bankType !== 'JP_BANK'">
          <label>銀行番号</label>
          <div>
            <b-col sm="2">
              <validation-provider name="銀行番号" rules="required|numeric" v-slot="{ errors }">
                <b-form-input :formatter="formatBank"
                              v-bind:disabled="transferInputType == 'COPY'" 
                              v-model="transferBank.bankCode"></b-form-input>
                <span class="transfer_bank__error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </div>
        </div>
        <div class="form-item" v-if="transferBank.bankType !== 'JP_BANK'">
          <label>銀行名</label>
          <b-col sm="3"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.bankName"></b-form-input></b-col>
        </div>
        <div class="form-item">
          <label>支店番号</label>
          <b-col sm="2"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.branchCode"></b-form-input></b-col>
        </div>
        <div class="form-item">
          <label>支店名</label>
          <b-col sm="3"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.branchName"></b-form-input></b-col>
        </div>
        <div class="form-item">
          <b-form-radio-group v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.accountType" :options="accountType" />
        </div>
        <div class="form-item">
          <label>口座番号</label>
          <b-col sm="2"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.accountNumber"></b-form-input></b-col>
        </div>
        <div class="form-item">
          <label>口座名義</label>
          <b-col sm="4"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.accountName"></b-form-input></b-col>
        </div>
        <div class="form-item">
          <label>口座名義カナ</label>
          <b-col sm="4"><b-form-input v-bind:disabled="transferInputType == 'COPY'" v-model="transferBank.accountNameKana"></b-form-input></b-col>
        </div>
        <div class="form-item" v-if="registerdBank.checkStatus != 'INITIALIZE'">
          <label>変更理由</label>
          <b-col sm="7"><b-form-textarea v-model="transferBank.reason" rows="3"></b-form-textarea></b-col>
        </div>
        <div class="form-item" v-if="registerdBank.checkStatus != 'INITIALIZE'">
          <label>新口座有効開始日</label>
          <b-col sm="3"><b-form-input v-model="transferBank.availableStartDate" placeholder=YYYY-MM-DD></b-form-input></b-col>
        </div>
        <div class="text-center mt-5">
          <b-button variant="primary" size="lg" v-on:click="onSave">
            <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
            保存
          </b-button>
          <br />
          <b-button variant="light" class="mt-2" :to="{ name : 'SupplierDetail' }">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            キャンセル
          </b-button>
        </div>  
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/css/validation.scss'; 
.transfer_bank__error {
  @extend .validation__error;
}
</style>

<script>
import SupplierBank from "@/model/bank/SupplierBank";
import CompanySimpleHeader from "@/components/organism/CompanySimpleHeader.vue"
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle, CompanySimpleHeader },
  data: () => {
    return {
      registerdBank: {
      },
      transferBank: {
      },
      transferInputType: '',
      transferInputTypeList: [
        { text: '振込口座からの転記', value: 'COPY' },
        { text: '手入力', value: 'INPUT' }
      ],
      bankType: [
        { text: 'ゆうちょ銀行', value: 'JP_BANK' },
        { text: 'ゆうちょ銀行以外', value: 'OTHER_BANK' }
      ],
      accountType: [
        { text: '普通預金', value: 'DEPOSIT' },
        { text: '当座預金', value: 'IMMEDIATE' }
      ],
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    formatBank(e) {
      return String(e).substring(0, 7)
    },
    show() {
      this.getSupplierBank()
      this.getSupplierTransferBank()
    },
    getSupplierBank() {
      Promise.all([
        SupplierBank.getSupplierBank(this.$route.params.supplierCode),
      ]).then((results) => {
        if (!results[0].data.length) this.registerdBank = {}
        this.registerdBank = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    getSupplierTransferBank() {
      Promise.all([
        SupplierBank.getSupplierTransferBank(this.$route.params.supplierCode),
      ]).then((results) => {
        if (!results[0].data.length) this.transferBank = {}
        this.transferBank = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    changeTransferInputType(value) {
      if (value == 'COPY') {
        this.transferBank = Object.assign({}, this.transferBank, this.registerdBank) 
      }
    },
    onConfirm() {
      this.$swal.confirm("確認済みに変更してもよろしいでしょうか？", undefined, () => {
        Promise.all([
        ]).then(() => {
          Promise.all([
            SupplierBank.updateStatus(this.$route.params.supplierCode, 
              JSON.parse(JSON.stringify({ status: 'CONFIRMED' }))),
          ]).then((results) => {
            if (!results[0].data.length) this.transferBank = {}
            this.$swal.success("確認済みに変更しました", undefined, () => { this.show() })
          }).catch(reason => {
            this.$errorMessage(reason)
          })
        }).catch(reason => {
          this.$errorMessage(reason);
        });
      })
    },
    onApprove() {
      this.$swal.confirm("承認済みに変更してもよろしいでしょうか？", undefined, () => {
        Promise.all([
        ]).then(() => {
          Promise.all([
            SupplierBank.updateStatus(this.$route.params.supplierCode, 
              JSON.parse(JSON.stringify({ status: 'APPROVED' }))),
          ]).then((results) => {
            if (!results[0].data.length) this.transferBank = {}
            this.$swal.success("承認済みに変更しました", undefined, () => { this.show() })
          }).catch(reason => {
            this.$errorMessage(reason)
          })
        }).catch(reason => {
          this.$errorMessage(reason);
        });
      })
    },
    onSave() {
      if (this.registerdBank.checkStatus == 'CONFIRMED' 
          || this.registerdBank.checkStatus == 'CHANGED') {
            this.$swal.fire('口座情報が承認されていません。承認後に会費口座情報を登録できます')
            return
      }
      this.$swal.confirm("会費振替口座情報を登録してもよろしいでしょうか？", undefined, () => {
        Promise.all([
        ]).then(() => {
          Promise.all([
            SupplierBank.saveTransferBank(this.$route.params.supplierCode, this.transferBank),
          ]).then(() => {
            this.$swal.success("登録しました", undefined, () => { this.show() })
          }).catch(reason => {
            this.$errorMessage(reason)
          })
        }).catch(reason => {
          this.$errorMessage(reason);
        });
      })
    }
  }
}
</script>