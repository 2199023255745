import Model from './_base';

export default class SupplierRegistrationStatus extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierRegistrationStatus.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplireCode){
        return Model.axios.get(this.url('/registration/' + supplireCode));
    }

    static update(data){
        return Model.axios.post(this.url('/registration/status'), data);
    }

    static remind(data){
        return Model.axios.post(this.url('/registration/remind'), data);
    }
}