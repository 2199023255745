import Model from '@/model/_base';

export default class SupplierTsr extends Model {
    static entity = 'tsr';

    constructor(data) {
        super(SupplierTsr.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static search(companyCode){
        return Model.axios.get(this.url('/' + companyCode));
    }

    static download(query){
        return Model.axios.post(this.url('/download'), query);
    }

    static change(companyCode, data){
        return Model.axios.post(this.url('/change/supplier/' + companyCode), data);
    }
    static reRequest(companyCode, data){
        return Model.axios.post(this.url('/re-request/supplier/' + companyCode), data);
    }
}