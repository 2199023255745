<template>
  <div class="transaction__fee">
    <PageTitle title="請求回収代行管理"></PageTitle>
    <div class="row">
      <div class="col-sm-2 col-md-2 col-lg-2">
        <select class="form-control" v-model="targetMonthly" >
          <option v-if="!targetMonthly" :value="null">-</option>
          <option v-for="month in targetMonthlySelect" :key="month" :value="month">{{ $moment(month).format('yyyy年MM月支払分') }}</option>
        </select>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="row">
          <div class="col-sm-5 col-md-5">合計入金予定額</div>
          <div class="col-sm-7 col-md-7">
            {{ totalAmountWithTax ? '¥' + totalAmountWithTax.toLocaleString("en-US") : ''}}
          </div>
        </div>
        <div class="row" v-for="division in divisionRateList" :key="division.divisionCode">
          <div class="col-sm-5 col-md-5">{{ division.divisionName }}</div>
          <div class="col-sm-7 col-md-7">
            {{ division.divisionAmount ? '¥' + division.divisionAmount.toLocaleString("en-US") : '' }}
          </div>
        </div>
      </div>
      <div class="transaction_fee-download col-sm-6 col-md-6 col-lg-6">
        <b-button variant="primary" size="md" class="transaction__fee-download-btn w-10" :disabled="paymentClosingList.length === 0" @click="downloadDataList">一括ダウンロード</b-button>
      </div>
    </div>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:20%">バイヤー会社情報</th>
          <!-- <th class="text-center" style="width:15%">Biz入金口座Code</th> -->
          <th class="text-center" style="width:10%">取引合計金額（税込）</th>
          <th class="text-center" :style="'width:' + (40 / divisionRateList.length) + '%'" v-for="division in divisionRateList" :key="division.divisionCode">{{ division.divisionName }}</th>
          <!-- <th class="text-center" style="width:10%">サプライヤへの振込予定日</th> -->
          <th class="text-center" style="width:5%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in paymentClosingList" :key="index" >
          <td class="text-left">[{{ payment.buyerCompanyDisplayCode }}] {{ payment.buyerCompanyName }}</td>
          <!-- <td class="text-center">{{ payment.bizDepositAccountCode }}</td> -->
          <td class="text-right" >
            {{ payment.totalAmountWithTax ? '¥' + payment.totalAmountWithTax.toLocaleString("en-US") : '' }}
          </td>
          <td class="text-right" v-for="amount in payment.paymentDivisionList" :key="amount.code">
            {{ amount.divisionAmount ? '¥' + amount.divisionAmount.toLocaleString("en-US") : '' }}
          </td>
          <!-- <td class="text-center">{{ payment.bizDepositAccountCode ? $moment(payment.bizDepositAccountCode).format('yyyy/MM/DD') : '' }}</td> -->
          <td class="text-center"><a href="javascript:;" @click="showDetail(payment)">詳細</a></td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <LoadingList :loaded="loadedList" :list="paymentClosingList" emptyData="現在データがございません。"></LoadingList>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transaction__fee {
  border: 1px solid $very-light-gray;
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
}
.transaction__fee-select {
  margin-left: 2rem;
  margin-right: 100px;
}
.transaction_fee-download {
    display: flex;
    flex-direction: column;
}
.transaction__fee-download-btn {
  margin: 5px;
  min-width: 220px;
  margin-left: auto;
}
</style>

<script>
import PaymentClosing from "@/model/PaymentClosing";

import PageTitle from "@/components/atoms/PageTitle.vue";
import LoadingList from "@/components/atoms/LoadingList.vue"

export default {
  components: { PageTitle, LoadingList },
  data() {
    return {
      loadedList: true,
      targetMonthlySelect: [],
      targetMonthly: null,
      totalAmountWithTax: 0,
      divisionRateList: [],
      paymentClosingList: []
    }
  },
  mounted() {
    this.getTargetMonthly();
  },
  watch: {
    targetMonthly() {
      this.loadListPayment();
    }
  },
  methods: {
    getTargetMonthly() {
      PaymentClosing.getTargetMonthly()
      .then(Response => {
        this.targetMonthlySelect = Response.data;
        if (this.$route.params.targetMonthly){
          this.targetMonthly = this.$route.params.targetMonthly;
        } else if (this.targetMonthlySelect && this.targetMonthlySelect.length > 0) {
          this.targetMonthly = this.targetMonthlySelect[0];
        }
      })
      .catch(() => {
        // do nothing
      });
    },
    loadListPayment() {
      if (!this.targetMonthly) {
        return this.$swal.error(undefined, "targetMonthly is null!");
      }
      this.loadedList = false;
      PaymentClosing.loadTransactionFeeManagement(this.targetMonthly)
      .then(Response => {
        // this.paymentClosingList = Response.data
        // for (let payment of this.paymentClosingList) {
        //   this.totalAmountWithTax += payment.totalAmountWithTax;
        // }
        // for (let division of this.paymentClosingList[0].paymentDivisionList) {
        //   let data = {
        //     divisionCode: division.divisionCode,
        //     divisionName: division.divisionName,
        //     totalAmount: 0
        //   }
        //   this.divisionRateList.push(data);
        // }
        // for (let payment of this.paymentClosingList) {
        //   for (let divisionAmount of this.divisionRateList) {
        //     for (let amount of payment.paymentDivisionList) {
        //       if (divisionAmount.divisionCode == amount.divisionCode 
        //         && divisionAmount.divisionName == amount.divisionName) {
        //           divisionAmount.totalAmount += amount.divisionAmount;
        //           break;
        //       }
        //     }
        //   }
        // }
        this.totalAmountWithTax = Response.data.totalAmountWithTax;
        this.paymentClosingList = Response.data.transactionFeeManagementList;
        this.divisionRateList = Response.data.paymentDivisionList;
      })
      .catch(() => {
        // do nothing
      });
      this.loadedList = true
    },
    showDetail(payment){
      this.$router.push({
        name: "TransactionFeeManagement_Detail",
        params: {
          targetMonthly: this.targetMonthly,
          companyDisplayCode: payment.buyerCompanyDisplayCode
        }
      });
    },
    downloadDataList(){
      PaymentClosing.exportZipTransactionPaymentClosing(this.targetMonthly)
      .then(response => {
        var blob = new Blob([response.data])
        if (window.navigator.msSaveOrOpenBlob) {
          var decodedFileName = decodeURI(response.headers["x-file-name"])
          window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
        } else {
          const link = document.createElement('a')
          var url = (window.URL || window.webkitURL).createObjectURL(blob)
          link.href = url
          link.download = decodeURI(response.headers["x-file-name"])
          link.click()
          link.remove()
        }
      })
      .catch(() => {
        this.$swal.error(undefined, "Error!");
      });
    }
  }
}
</script>