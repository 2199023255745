<template>
  <div name="modal">
    <div class="modal modal-overlay" @click.self="$emit('close')">
      <div class="modal-window">
        <div class="modal-content p-0">
          
          <form>
          <div class="modal-header">
            <slot name="title"/>
            <button type="button" class="close" @click.self="$emit('close')">×</button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-0">
              <label for="newCategoryCode">カテゴリコード</label>
              <input type="text" class="form-control" id="newCategoryCode" name="newCategoryCode" v-model='form.newCategoryCode'/>
            </div>
            <span v-if="alert.code" class="search-alert text-danger">コードは1字以上50字以内の間で入力してください。</span>
            <div class="form-group mt-2 mb-0">
              <label for="newCategoryName">カテゴリ名</label>
              <input type="text" class="form-control" id="newCategoryName" name="newCategoryName" v-model='form.newCategoryName'/>
            </div>
            <span v-if="alert.name" class="search-alert text-danger">名称は1字以上50字以内の間で入力してください。</span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger"  @click.self="$emit('close')">キャンセル</button>
            <button type="button" class="btn btn-primary" @click="create">登録</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &-window {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
  }

  &-content {
    padding: 10px 20px;
  }

  &-footer {
    padding: 10px;
    text-align: right;
  }
}

// オーバーレイのトランジション
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.4s;

  // オーバーレイに包含されているモーダルウィンドウのトランジション
  .modal-window {
    transition: opacity 0.4s, transform 0.4s;
  }
}

// ディレイを付けるとモーダルウィンドウが消えた後にオーバーレイが消える
.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;

  .modal-window {
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>

<script>
import Category from "@/model/Category";

export default {
  data: () => {
    return {
      form:{
      },      
      alert:{
        code:false,
        name:false,
      },
    }
  },
  props: [
    "val"    
  ],
  watch: {},
  methods: {
  create(){
    if(!this.form.newCategoryCode || (this.form.newCategoryCode.length < 1 || this.form.newCategoryCode.length > 50)){
      this.alert.code = true;
    }else{
      this.alert.code = false;
    }

    if(!this.form.newCategoryName || (this.form.newCategoryName.length < 1 || this.form.newCategoryName.length > 50)){
      this.alert.name = true;
    }else{
      this.alert.name = false;
    }

    if(!this.alert.code && !this.alert.name){
      this.$swal.confirm("カテゴリを追加します。よろしいですか？", undefined, () => {
        var parentPath = ""
        if(this.val.type == "mid"){
          parentPath = this.val.id
        }else if(this.val.type == "low"){
          parentPath = this.val.parentId + "/" + this.val.id
        }

        this.form.parentPath = parentPath
        this.form.type = this.val.type
        Promise.resolve(
          Category.createCategory(this.form),
        ).then((result) => {
          this.$swal.success("カテゴリの追加が完了しました", undefined, () => {});   
          
          this.$emit('after',result.data)

        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    }
  },
  
  }
  }
</script>