
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optionalimport Vue from "vue";

import Vue from "vue";
import { BootstrapVue } from 'bootstrap-vue';
import VTooltip from 'v-tooltip'
import VModal from 'vue-js-modal'

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';
import * as customFilters from '@/plugins/filters';

import App from "@/App.vue";
import router from "@/routes";
import store from "@/store";

import "@/plugins/fontawesome";
import Message from "@/plugins/message.js";
import "@/plugins/array";
import "@/plugins/date";
import "@/plugins/number";
import "@/plugins/sweetalert2";
import Datepicker from "@/plugins/vuejs-datepicker";
import InputCommaDot from "@/components/atoms/InputCommaDot";
import InputComma from "@/components/atoms/InputComma";

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VTooltip)
Vue.use(VModal)

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Object.keys(customFilters).forEach(filter => {
  Vue.filter(filter, customFilters[filter]);
});


localize('ja', ja);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component("input-comma-dot", InputCommaDot);
Vue.component("input-comma", InputComma);


Vue.use(Message);
Vue.use(Datepicker);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
