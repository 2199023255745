<template>
    <b-checkbox-group v-model="model.isOpenGuideExclusion" :options="list"/>
</template>

<script>
import StopMembership from "@/model/membership/StopMembership"

export default {
    props: [ 'model' ],
    data: () => {
        return {
            list: StopMembership.create().list
        }
    }
}
</script>

<style scoped lang="scss">
</style>