export default class {
  list = [
    { text: 'Biz入会案内禁止あり', value: true },
    { text: 'Biz入会案内禁止なし', value: false }
  ]

  
  findTextBy(value) {
    return this.list.filter(status => status.value == value)[0].text
  }

  static create() {
    return new this()
  }
}