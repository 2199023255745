<template>
  <div class="p-3 company-simple-detail-header">
    <b-breadcrumb>
      <b-breadcrumb-item to="/supplier/all">サプライヤ一覧</b-breadcrumb-item>
      <b-breadcrumb-item id="menu-tooltip" active>{{title || 'メニュー'}}▼</b-breadcrumb-item>
      <b-tooltip target="menu-tooltip" placement="bottom" variant="light">
        <b-nav vertical>
          <b-nav-item :to="{ name: 'SupplierDetail', params: { supplierCode: $route.params.supplierCode } }">サプライヤ情報</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierExamDetail', params: { supplierCode: $route.params.supplierCode } }">サプライヤ審査</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailBank', params: { supplierCode: $route.params.supplierCode } }">銀行口座情報</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailSigner', params: { supplierCode: $route.params.supplierCode } }">署名者情報</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailBuyer', params: { supplierCode: $route.params.supplierCode } }">取引先バイヤ企業</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailBusinessAvailable', params: { supplierCode: $route.params.supplierCode } }">リスク管理</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailWithdraw', params: { supplierCode: $route.params.supplierCode } }">退会管理</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierDetailHistory', params: { supplierCode: $route.params.supplierCode } }">変更履歴</b-nav-item>
        </b-nav>
      </b-tooltip>
      
    </b-breadcrumb>
    <div class="p-2">
      <p>
        <b>サプライヤーコード:</b>
        <router-link :to="{name: 'SupplierDetail', params: { supplierCode: company.code }}">{{company.displayCode || '«未登録»'}}</router-link>
        <a class="simple-button" @click="$refs['memo-modal'].show();">
          <font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
        </a>
        <br/><br/>
        <a class="simple-button" @click="$refs['history-modal'].show(); $refs['history'].load();">
          <font-awesome-icon :icon="['fas', 'history']"></font-awesome-icon>
        </a>
        
      </p>
      <p><b>法人番号:</b>{{company.corporationNumber || '«未登録»'}}</p>
      <p><b>会社名:</b>{{company.fullName || '«未登録»'}}</p>
      <p>
        <b class="text-muted">主担当者</b><br/>
        <b>お名前:</b>{{company.mainUserName || '«未登録»'}}<br/>
        <b>メールアドレス:</b>{{company.mainUserEmail || '«未登録»'}}<br/>
        <b>電話番号:</b>{{ company.mainUserPhone || '«未登録»'}}
      </p>
    </div>
    <b-modal ref="history-modal" size="xl" title="履歴" hide-footer>
      <History ref="history" :supplierCode="$route.params.supplierCode" :compact="true"/>
    </b-modal>
    <b-modal ref="memo-modal" size="lg" hide-footer>
       <Memo :supplierCode="$route.params.supplierCode" @hidden="$refs['memo-modal'].hide();" />
    </b-modal>
  </div>
</template>
<script>
import SupplierDetail from '@/model/SupplierDetail.js';
import Memo from "@/components/organism/Memo.vue";
import History from '@/components/organism/examination/History.vue';
export default {
  components: { Memo, History },
  props:["companyCode", 'title'],
  data: function(){
    return {
      company: {}
    }
  },
  created(){
    SupplierDetail.getHeader(this.companyCode).then(result => {
      this.company = result.data;
    }).catch(reason => this.$errorMessage(reason));
  }
}
</script>
<style lang="scss">
  .company-simple-detail-header{
    background: none;
    border-bottom: 1px solid #a5a8ac;
  }
  .simple-button{
    float: right;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.5s ease-in;
    padding: .5rem;
    &:hover{
      background: #a5a8ac;
      color: #fff;
    }
    svg{
      width: 1.5rem !important;
      height: 1rem !important;
    }
  }
</style>