<template>
  <div class="auto_verification">
    <div class="p-3">
      <SearchFree placeholder="サプライヤコード、サプライヤ名、主担当者名、主担当者メールアドレス"
                      maxlength="100"
                      v-model="form.conditions.fullText"
                      @search="getSearchItems" />
      <div class="mt-2 row">
        <div class="col-auto"><MembershipCheckBox :model="form.conditions" /></div>
        <div class="col-auto">
          <b-checkbox class="d-inline-block" v-model="form.conditions.byVerification.hasRejectedVerificationCount">不合格件数あり</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byVerification.hasUnsureVerificationCount">要判断件数あり</b-checkbox>
        </div>
      </div>
    </div>
    <div class="auto_verification__collect">
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center">サプライヤ</th>
            <th class="text-center">処理状態</th>
            <th class="text-center">最終更新日時</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="form.result.length == 0">
            <td class="text-left" colspan="6">
              <LoadingList :loaded="loaded" :list="form.result" emptyData="データがございません。"></LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in form.result" :key="index">
            <td style="width:25%">
              <router-link :to="{ name: 'SupplierRPADetail', params: { supplierCode: item.companyCode } }">
                <span v-on:click="saveSupplier(item)">
                  {{item.companyDisplayCode}}<br />
                  {{item.corporationNumber || '«未登録»'}}<br />
                  {{item.companyName || '«未登録»'}}<br />
                  {{item.mainUserName || '«未登録»'}}<br />
                  {{item.mainUserEmail || '«未登録»'}}</span>
              </router-link>
            </td>
            <td  style="width:25%" class="text-left">
              <p>未処理件数: {{item.verificationInfo.uncheckedCount - item.verificationInfo.totalVerificationCount}}</p>
              <p>総数: {{item.verificationInfo.totalVerificationCount}}</p>
              <p>合格件数: {{item.verificationInfo.resolvedVerificationCount}}</p>
              <p>不合格件数: {{item.verificationInfo.rejectedVerificationCount}}</p>
              <p>要判断件数: {{item.verificationInfo.unsureVerificationCount}}</p>
            </td>
            <td class="text-left">
              <p>RPA最終更新日時: {{item.verificationInfo.lastUpdateDate || '«未処理»'}}</p>
              <p>ユーザ最終更新日時: {{item.lastUpdateDate || '«未処理»'}}</p>
            </td>
            <td style="width:10%" class="text-center"><font-awesome-icon class="supplier_all__memo" :icon="['far', 'edit']" v-on:click="showMemo(item.companyCode)"></font-awesome-icon></td>
          </tr>
        </tbody>
      </table>
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
    </div>
    <modal name="memo-modal"
       :draggable="true" 
       :resizable="true"
       :scrollable="true"
       height="auto">
       <Memo :supplierCode="form.memoSupplierCode" />
    </modal>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";


</style>

<script>
import SupplierSearch from '@/model/SupplierSearch'
import SupplierVerification from '@/model/check/SupplierVerification'

import Pagination from "@/components/molecule/Pagination.vue"
import SearchFree from "@/components/molecule/SearchFree.vue"
import MembershipCheckBox from "@/components/atoms/membership/MembershipCheckBox.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import Memo from "@/components/organism/Memo.vue"

export default {
  components: { Pagination, SearchFree, MembershipCheckBox,LoadingList, Memo },
  props: [ 'saveSupplier' ],
  data: () => {
    return {
      form: {
        conditions: {
          byScore: {},
          byCompany: {},
          byOther: {},
          byVerification: {},
          isOpenGuideExclusion: [],
          isOpenSupplier: []
        },
        paging: {
          sorting: []
        },
        result: []
      },
      loaded: true,
    }
  },
  created() {
    if(this.$store.state.pages.autoVerification){
      this.form.conditions = this.$store.state.pages.autoVerification.conditions || this.form.conditions;
      this.form.paging = this.$store.state.pages.autoVerification.paging || this.form.paging;
    }
    this.getSearchItems();
  },
  methods: {
    getSearchItems() {
      console.log("whuy");
      this.loaded = false;
      SupplierSearch.getVerified(this.form).then(result => {
        this.form = result.data;
        this.loaded = true;
        this.$store.commit('pages', { 
          autoVerification: {
            paging: this.form.paging,
            conditions: this.form.conditions  
          }
        });
      }).catch(reason => this.$errorMessage(reason));
    },
    onReentryRequest(supplierCode) {
      this.$swal.confirm("再入力依頼をします。よろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierVerification.reentryRequest(supplierCode)
        ]).then(() => {
          this.$swal.success("依頼しました", undefined, () => {})
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    },
    onOK(supplierCode) {
      this.$swal.confirm("OKにしますします。よろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierVerification.change(supplierCode, { isCorrect: true })
        ]).then(() => {
          this.$swal.success("完了しました", undefined, () => {
            this.getSearchItems(false)
          })
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    },
    onNG(supplierCode) {
      this.$swal.confirm("NGにします。よろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierVerification.change(supplierCode, { isCorrect: false })
        ]).then(() => {
          this.$swal.success("完了しました", undefined, () => {
            this.getSearchItems(false)
          })
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    },
    showMemo : function(supplierCode) {
      this.form.memoSupplierCode = supplierCode
      this.$modal.show('memo-modal')
    },
    hideMemo : function () {
      this.$modal.hide('memo-modal')
    },
  }
}
</script>