<template>
  <div>
    <PageTitle title="アンケート">
      <router-link to="/system/safety/questionnaire/0" style="float:right;" class="mb-2 btn btn-primary"><font-awesome-icon icon="plus"/>新規登録</router-link>
    </PageTitle>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <table class="table table-bordered">
        <colgroup>
          <col width="10%"/>
          <col width="30%"/>
          <col width="20%"/>
          <col width="20%"/>
          <col width="20%"/>
        </colgroup>
        <thead>
          <tr> 
            <th>No.</th>
            <th>タイトル</th>
            <th>状態</th>
            <th>項目数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.count }}</td>
            <td>
              <router-link :to="'/system/safety/questionnaire/' + item.id" class="btn btn-primary btn-sm no-min"><font-awesome-icon icon="edit"/></router-link>
              <button type="button" @click="deleteQuestionnaire(item.id)" class="btn btn-danger btn-sm no-min ml-2"><font-awesome-icon icon="trash"/></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
export default {
  components: {
    PageTitle
  },
  data(){
    return {
      list: [
        {
          id: 1,
          title: "被災確認アンケート",
          status: "自動送信（スケージュール設定済み）",
          count: 3
        },
        {
          id: 2,
          title: "通常安否確認アンケート",
          status: "未公開（手動送信可能）",
          count: 2
        },
        {
          id: 3,
          title: "地震確認アンケート",
          status: "自動送信（エベント）",
          count: 5
        }
      ]
    }
  },
  methods: {
    deleteQuestionnaire(id){
      console.log(id);
      this.$bvModal.msgBoxConfirm('本当に削除しますか？').then(() => {
        // Confirmed
        this.$successMessage("削除しました");
      }, () => {
        // Cancelled
        this.$warningMessage("キャンセルしました");         
      });
    }
  }
}
</script>


<style>

</style>