import Model from '@/model/_base';

export default class SupplierBusinessAvailable extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierBusinessAvailable.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplierCode) {
        return Model.axios.get(this.url("/" + supplierCode + '/business-available'));
    }

    static save(supplierCode, form) {
        return Model.axios.put(this.url("/" + supplierCode + '/business-available'), form);
    }
}