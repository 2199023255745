<template>
  <div>
    <div class="page">
      <div class="pl-1 pr-1 pb-3">
        <div class="row">
          <div class="col">
            <Todo/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Buyer/>
            <Supplier/>
            <Performance/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  
.page {
  padding: 1em;
}

</style>
<script>
  import Todo from '@/components/organism/dashboard/Todo.vue'
  import Buyer from '@/components/organism/dashboard/Buyer.vue'
  import Supplier from '@/components/organism/dashboard/Supplier.vue'
  import Performance from '@/components/organism/dashboard/Performance.vue'

  export default {
    components: { Todo, Buyer, Supplier, Performance },
    data: () => ({
      registrationStatus: 0,
      status: {},
      registration: false
    }),
    methods: {
      checkRegistrationStatus(finish) {
        this.$data.registrationStatus = finish ? 12 : 9;
      }
    }
  }
</script>