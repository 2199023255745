import Model from './_base';

export default class SupplierWithdrawal extends Model {
    static entity = 'withdrawal';

    constructor(data) {
        super(SupplierWithdrawal.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static loadSupplierWithdrawal(supplierCode){
        return Model.axios.get(this.url('/load-withdrawal/' + supplierCode));
    }

    static loadEditWithdrawal(supplierCode){
        return Model.axios.get(this.url('/load-withdrawal-edit/' + supplierCode));
    }

    static executeWithdraw(form){
        return Model.axios.post(this.url('/execute-withdraw'), form);
    }
}