<template>
    <div class="search">
        <b-form-input :placeholder="placeholder" :value="value" @input="updateValue($event)" :maxlength="maxlength" />
        <div class="ml-1">
            <button type="button" class="btn btn-warning ml-3" @click="$emit('search')">
                <font-awesome-icon icon="search" />検索
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
}
</style>

<script>
export default {
    components: {  },
    props: { 
        placeholder: { type: String }, 
        maxlength: { type: String }, 
        value: { type: String}
    },
    methods: {
        updateValue(value){
            this.$emit('input', value);
        }
    }
}
</script>