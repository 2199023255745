<template>
  <div>
    <PageTitle title="サプライヤー検証結果"></PageTitle>
    <div class="supplier_detail">
      <div class="row">
        <div class="col">
          <div class="supplier_detail__company">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                会社情報
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">法人番号</label>
                  <div class="supplier_detail__contents_item_value">4010005011434</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">会社名</label>
                  <div class="supplier_detail__contents_item_value">鈴木家電株式会社</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">住所</label>
                  <div class="supplier_detail__contents_item_value">
                    106-0032
                    <br />
                    東京都港区六本木一丁目仙石山森タワー25F
                  </div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">代表者</label>
                  <div class="supplier_detail__contents_item_value">
                    お名前（漢字）：代表太郎 <br/>
                    お名前（カナ）：ダイヒョウタロウ <br/>
                    生年月日：1968-03-32
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="supplier_detail__detail">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                許認可情報
              </div>
              <div class="supplier_detail__contents_body">  
                <table class="table border-table">
                  <tbody>
                    <RPAItem v-for="license in licenses" :key="license.id" :result="license"/>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="supplier_detail__detail">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                認証情報
              </div>
              <div class="supplier_detail__contents_body">  
                <table class="table border-table">
                  <tbody>
                    <RPAItem v-for="certificate in certificates" :key="certificate.id" :result="certificate"/>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="supplier_detail__detail">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                特許情報
              </div>
              <div class="supplier_detail__contents_body">  
                <table class="table border-table">
                  <tbody>
                    <RPAItem v-for="patent in patents" :key="patent.id" :result="patent"/>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="supplier_detail__authorization">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                システム情報
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">最終更新日時</label>
                  <div class="supplier_detail__contents_item_value">2021-01-20 09:08:22</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">最終更新者</label>
                  <div class="supplier_detail__contents_item_value">鈴木太郎（12345_12345678）</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">再入力依頼日時</label>
                  <div class="supplier_detail__contents_item_value">2021-01-19 20:00:05</div>
                </div>
                <b-button variant="light">まとめて再入力依頼</b-button>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<style scoped lang="scss">

.supplier_detail {
  margin-left: 20px;
}
.supplier_detail__contents {
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background: $light-cyan-blue;
}
.supplier_detail__contents_title {
  margin: 10px;
  font-weight: bold;
}
.supplier_detail__current_status {
  margin: 10px 20px 50px 0;
  padding: 30px;
  border: 1px solid $very-light-gray;
}
.supplier_detail__contents_body {
    margin: 10px;
  }
.supplier_detail__contents_item {
  display: flex;
  align-items: flex-start;
  &--first {
    border-bottom: 1px solid $very-light-gray;
  }
}
.supplier_detail__contents_item_label {
  flex: 2;
  font-size: 16px;
  padding-right: 1rem;
  margin-top: 6px;
}
.supplier_detail__contents_item_value {
  flex: 10;
  margin-top: 6px;
}

.border-table td,
.border-table th {
  border: 1px solid $very-light-gray;
  border-collapse: collapse;
  background-clip: padding-box;
}

</style>

<script>

import PageTitle from "@/components/atoms/PageTitle.vue";
import RPAItem from "@/components/molecule/rpa/RPAItem.vue";

export default {
  components: { PageTitle, RPAItem },
  props: {},
  data: () => {
    return {
      detail: {},
      licenses: [
        {license:{}}
      ],
      certificates: [
        {license:{}}
      ],
      patents: [
        {license:{}}
      ]
    }
  },
  mounted() {
    
  },
  computed: {
    address() {
      return this.detail.address1 + (this.detail.address2 ? this.detail.address2 : '')
    }
  },
  methods: {
    getDetail() {
      
    },
    getRegistrationStatus() {
      
    }
  }
}
</script>
