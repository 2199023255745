import Model from './_base';

export default class MembershipFeeManagement extends Model {
    static entity = 'membership-fee-setting';

    constructor(data) {
        super(MembershipFeeManagement.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static loadInfo(){
        return Model.axios.get(this.url('/load-membership-fee-info'));
    }

    static loadEdit(id){
        return Model.axios.post(this.url('/load-membership-fee-edit/'+id));
    }

    static saveSetting(data){
        return Model.axios.post(this.url('/save-membership-fee-setting'), data);
    }
}