<template>
  <div class="register_status">
    <div class="p-3">
      <div>
        <SearchFree placeholder="サプライヤコード、サプライヤ名、主担当者名、主担当者メールアドレス"
                      maxlength="100"
                      v-model="form.conditions.fullText"
                      @search="getSearchItems" />
      </div>
      <div class="row mt-2">
        <div class="col-auto"><StopMembershipCheckBox :model="form.conditions"/></div>
        <div class="col-auto"><MembershipCheckBox :model="form.conditions" /></div>
      </div>
      <hr/>
      <div class="row mt-2">
        <div class="col">
          <b-checkbox class="d-inline-block"      v-model="form.conditions.byStatus.hasAccountCreated">入会申込あり(Ａ情報登録済み)</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasRegistrationCompleted">入会申込完了(Ｂ情報登録済み)</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasExaminationCompleted">審査完了</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasAddressVerified">口座振替依頼書送付済</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasBankTransferAccountRegistered">振替口座登録済</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasContractSigned">契約署名済</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasCompleted">口座振替手続き完了</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byStatus.hasNgCompleted">※手続きNG</b-checkbox>
        </div>
      </div>
      <div class="row mt-4">
        <b-button type="button" class="btn btn-primary ml-3" v-bind:disabled="form.isNoCheckItem" @click="onPrint">送付ラベル印刷</b-button>
        <b-button type="button" class="btn btn-primary ml-3" v-bind:disabled="form.isNoCheckItem" @click="onRemind">督促メール</b-button>
        <b-button type="button" class="btn btn-primary ml-3" v-bind:disabled="form.isNoCheckItem" @click="onSend">口座振替依頼書送付済みにする</b-button>
      </div>
    </div>
    <div class="register_status__collect">
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
      <!-- TODO fix pagination -->
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center"><input class="reminder__checkbox reminder__checkbox--all checkbox-large" type="checkbox" v-on:click="onCheckAll" /></th>
            <th class="text-center">サプライヤ</th>
            <th class="text-center">ステータス</th>
            <th class="text-center">入会案内制限</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="form.result.length == 0">
            <td class="text-left" colspan="5">
              <LoadingList :loaded="loaded" :list="form.result" emptyData="データがございません。"></LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in form.result" :key="`searchItems${index}`">
            <td class="text-center"><input class="reminder__checkbox reminder__checkbox--item checkbox-large" type="checkbox" v-model="item.selected" v-on:click="onCheck" /></td>
            <td>
              <router-link :to="{ name: 'SupplierExamDetail', params: { supplierCode: item.companyCode } }">
                <span v-on:click="saveSupplier(item)">
                  {{item.companyDisplayCode}}<br />
                  {{item.corporationNumber || '«未登録»'}}<br />
                  {{item.companyName || '«未登録»'}}<br />
                  {{item.mainUserName || '«未登録»'}}<br />
                  {{item.mainUserEmail || '«未登録»'}}</span>
              </router-link>
            </td>
            <td>
              <div><DetailedRegistrationFlow :status="item.registerStatus" :isOpenSupplier="item.isOpenSupplier" /></div>
              <div>最終更新：{{item.lastUpdateDate}}</div>
            </td>
            <td class="text-center">
              {{membership.findTextBy(item.isOpenSupplier)}}
              <br />
              <template v-if="!item.isStopMembership">{{stopMembership.findTextBy(item.isOpenGuideExclusion)}}</template>
              <template v-if="item.isStopMembership">
                <a :id="'buyerInfo'+item.companyCode" class="register_status__stop_membership_buyerinfo" href="#" >{{findStopMembership(item.isStopMembership)}}</a>
                <b-tooltip :target="'buyerInfo'+item.companyCode" placement="bottom">
                  <ul class="register_status__stop_membership_buyerinfo_names">
                    <li v-for="(buyerName, index) in item.stopMembershipBuyerNames" :key="`${buyerName}${index}`">
                      {{buyerName}}
                    </li>
                  </ul>
                </b-tooltip>
              </template>
            </td>
            <td>
              <div v-if="!item.registerStatus.isCompleted">
                <b-button type="button" class="btn btn-dark ml-3" v-on:click="onStopMembership(item.companyCode)">入会お断り</b-button>
              </div>
              <div v-if="item.registerStatus.isContractSigned && !item.registerStatus.isCompleted">
                <b-button type="button" class="btn btn-success ml-3" v-on:click="onComplete(item.companyCode)">口座振替結果OK</b-button>
              </div>
              <div v-if="item.registerStatus.isContractSigned && !item.registerStatus.isCompleted && !item.registerStatus.isNgCompleted">
                <b-button type="button" class="btn btn-danger ml-3" v-on:click="onNgComplete(item.companyCode)">口座振替結果NG</b-button>
              </div>
              <div>
                <font-awesome-icon class="register_status__memo" 
                                   :icon="['far', 'edit']"
                                   v-on:click="showMemo(item.companyCode)"></font-awesome-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
    </div>
    <modal name="memo-modal"
       :draggable="true" 
       :resizable="true"
       :scrollable="true"
       height="auto">
       <Memo :supplierCode="modalSupplierCode" />
    </modal>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

</style>

<script>
import SupplierSearch from '@/model/SupplierSearch'    
import SupplierRegistrationStatus from '@/model/SupplierRegistrationStatus'
import StopMembership from '@/model/membership/StopMembership'
import Membership from '@/model/membership/Membership'

import Pagination from "@/components/molecule/Pagination.vue"
import SearchFree from "@/components/molecule/SearchFree.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import StopMembershipCheckBox from "@/components/atoms/membership/StopMembershipCheckBox.vue"
import MembershipCheckBox from "@/components/atoms/membership/MembershipCheckBox.vue"
import DetailedRegistrationFlow from '@/components/organism/DetailedRegistrationFlow.vue'
import Memo from "@/components/organism/Memo.vue"

export default {
  components: { Pagination, SearchFree, LoadingList, DetailedRegistrationFlow, Memo, StopMembershipCheckBox, MembershipCheckBox },
  props: [ 'print', 'saveSupplier' ],
  data: () => {
    return {
      form: {
        conditions: {
          byScore: {},
          byCompany: {},
          byStatus: {},
          byOther: {},
          isOpenGuideExclusion: [],
          isOpenSupplier: []
        },
        paging: {
          sorting: []
        },
        result: []
      },
      loaded: true,
      stopMembership: StopMembership.create(),
      membership: Membership.create(),
      modalSupplierCode: null
    };
  },
  created() {
   if(this.$store.state.pages.progress){
      this.form.conditions = this.$store.state.pages.progress.conditions || this.form.conditions;
      this.form.paging = this.$store.state.pages.progress.paging || this.form.paging;
    }
    this.getSearchItems();
  },
  methods: {
    findMemberShip(value) {
      return this.membership.filter(status => status.value == value)[0].text
    },
    findStopMembership(value) {
      return this.stopMembership.filter(status => status.value == value)[0].text
    },
    getSearchItems() {
      SupplierSearch.getSearchStatus(this.form).then(result => {
        this.form = result.data;
        this.$store.commit('pages', { progress: this.form })
      }).catch(reason => this.$errorMessage(reason));
    },
    clearSearch() {
      this.form.conditions = {
        byScore: {},
        byCompany: {},
        byOther: {},
        isOpenGuideExclusion: [],
        isOpenSupplier: []
      };
    },
    onStopMembership(supplierCode) {
      this.$swal.confirm("入会お断りメールを送信します。よろしいですか？", undefined, () => {
        Promise.all([
          SupplierRegistrationStatus.update({ values: [
            {
              supplierCode: supplierCode,
              status: 'noMembership'
            }
          ]}),
        ]).then(() => {
          this.$swal.success("入会お断りメールを送信しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    onCheck() {
      const item = document.getElementsByClassName("reminder__checkbox--item")
      this.form.isNoCheckItem = true
      item.forEach(element => {
        if (element.checked) this.form.isNoCheckItem = false 
      })
    },
    onCheckAll() {
      const all = document.querySelector(".reminder__checkbox--all")
      if (all.checked) {
        this.form.result.forEach(content => content.selected = true)
        this.form.isNoCheckItem = false
      }
      if (!all.checked) {
        this.form.result.forEach(content => content.selected = false)
        this.form.isNoCheckItem = true
      }
    },
    onPrint() {
      this.$props.print(this.form.result
          .filter(content => content.selected)
          .map(content => content.companyCode))
    },
    onRemind() {
      this.$swal.confirm("状況に応じた督促メールを送信してもよろしいでしょうか？", undefined, () => {
        const remind = this.form.result.filter(content => content.selected)
          .map(content => { 
            let stats;
            if(!content.registerStatus.accountCreated){
              stats = 'accountCreateReminder';
            }else if(content.registerStatus.addressVerified){
              stats = 'addressVerifiedReminder';
            }else{
              stats = 'completeRegistrationReminder';
            }
            return {
              supplierCode: content.companyCode,
              status: stats
            }
          })
        Promise.all([
          SupplierRegistrationStatus.remind({ values: remind }),
        ]).then(() => {
          this.$swal.success("送信しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    onSend() {
      this.$swal.confirm("口座振替依頼書送付済みに変更してよろしいでしょうか？", undefined, () => {
        const request = this.form.result.filter(content => content.selected)
          .map(content => { 
            return {
              supplierCode: content.companyCode,
              status: 'addressVerified',
              value: true
            }
          })
        Promise.all([
          SupplierRegistrationStatus.update({ values: request }),
        ]).then(() => {
          this.$swal.success("変更しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    onComplete(supplierCode) {
      this.$swal.confirm("口座振替結果をOKにします。よろしいですか？", undefined, () => {
        Promise.all([
          SupplierRegistrationStatus.update(
            {
              values: [
                {
                  supplierCode: supplierCode,
                  status: 'completed',
                  value: true
                }
              ]
            }
          ),
        ]).then(() => {
          this.$swal.success("変更しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    onNgComplete(supplierCode) {
      this.$swal.confirm("口座振替結果をNGにします。よろしいですか？", undefined, () => {
        Promise.all([
          SupplierRegistrationStatus.update(
            {
              values: [
                {
                  supplierCode: supplierCode,
                  status: 'ngCompleted',
                  value: true
                }
              ]
            }
          ),
        ]).then(() => {
          this.$swal.success("変更しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    showMemo : function(supplierCode) {
      this.modalSupplierCode = supplierCode
      this.$modal.show('memo-modal');
    },
    hideMemo : function () {
      this.$modal.hide('memo-modal');
    },
  }
}
</script>
