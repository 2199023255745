import Model from './_base';

export default class SupplierDetail extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierDetail.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode));
    }

    static getHeader(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/header'));
    }
}