<template>
  <div class="editable-field">
    <label class="m-0 text-dark" v-if="label">{{label}}</label>
    <template v-if="!isEdit && !controller">
      <pre :class="{'m-0': true, changed:!isPristine}" v-if="type === 'list'">{{view}}</pre>
      <span v-else :class="{changed:!isPristine}">
        {{view}}
      </span>
    </template>
    <template v-if="isEdit && !controller">
      <b-form-input         v-if="type === 'text'" v-model="edited" type="text" class="mt-1"/>
      <b-form-textarea v-else-if="type === 'textarea'" v-model="edited" class="mt-1"/>
      <b-form-input    v-else-if="type === 'currency'" v-model="edited" class="mt-1"/>
      <template v-else-if="type === 'list'">
        <b-form-textarea v-model="edited" class="mt-1"/>
      </template>
      
    </template>
    <div :class="{'controls': true, 'hidden':shared, 'none':noControls }">
      <a 
        class="ml-1" 
        href="javascript:void(0);" 
        v-b-tooltip.hover :title="isEdit?'更新':'編集'"
        @click.prevent="saveOrEdit()"><font-awesome-icon :icon="isEdit?'check':'pen'"/></a>
      <a 
        v-if="!isPristine || isEdit"
        class="ml-1" 
        href="javascript:void(0);" 
        v-b-tooltip.hover :title="isEdit?'キャンセル':'リセット'" 
        @click.prevent="clearOrReset()"><font-awesome-icon :icon="isEdit?'times-circle':'redo'"/></a>
      <a 
        class="ml-1" 
        href="javascript:void(0);" 
        @click.prevent="copyFromTsr()"
        v-b-tooltip.hover title="TSRからコピー"><font-awesome-icon icon="copy"/></a>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    label: String,
    value: [String, Number, Array], 
    tsrValue: [String, Number, Array],
    type: {
      type: String,
      default: 'text'
    },
    viewFilter: [String, Function],
    getValue: {
      type: Function,
      default: function(){
        return this.edited;
      }
    },
    setValue: {
      type: Function,
      default: function(value){
        this.edited = value;
      }
    },
    shared: Boolean,
    noControls: Boolean,
    controller: Boolean
  },

  data: function() {
    let objs = {
      isEdit: this.edit,
      isPristine: true,
      edited: this.value,
      original: this.value
    };

    return objs;
  },
  computed: {
    view: {
      get(){
        if(typeof this.viewFilter === 'string') return this.$options.filters[this.viewFilter](this.value);
        else if(typeof this.viewFilter === 'function') return this.viewFilter(this.value);
        else return this.value;
      }
    }
  },
  watch: {
    value:function(value) {
      this.setValue(value);
      if(this.isPristine) this.original = this.edited;
    }
  },
  methods:{
    saveOrEdit(){
      this.$emit('action', 'saveOrEdit');
      if(this.isEdit) {
        this.isPristine = this.edited === this.original;
        this.$emit('input', this.getValue());
      }
      this.isEdit=!this.isEdit;
    },
    clearOrReset(){
      this.$emit('action', 'clearOrReset');
      if(this.isEdit){
        this.isEdit = !this.isEdit;
        return;
      }
      this.edited = this.original;
      this.isPristine = true;
      this.$emit('input', this.getValue());
    },
    copyFromTsr(){
      this.$emit('action', 'copyFromTsr');
      this.edited = this.tsrValue;
      this.isPristine = this.edited === this.original;
      this.$emit('input', this.getValue());
    },
    runAction(event){
      if(event === 'saveOrEdit') this.saveOrEdit();
      else if(event === 'clearOrReset') this.clearOrReset();
      else if(event === 'copyFromTsr') this.copyFromTsr();
    }
  }
}
</script>

<style lang="scss">
  .editable-field{
    display:flex;
    align-items:center;
    width: 100%;
    pre, span, input, textarea {
      display:block;
      flex: 1;
      font-family: メイリオ, ヒラギノ角ゴ Pro, Arial, Helvetica Neue, sans-serif;
      color: inherit;
    }

    .changed{
      color: $green;
    }
    .controls {
      margin: 0 auto;
      &.hidden{
        visibility: hidden;
      }
      &.none{
        display:none;
      }
      a{
        color: $gray;
      }
    }
  }
</style>