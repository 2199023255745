import Model from './_base';

export default class BillManagement extends Model {
    static entity = 'bill-management';
    
    constructor(data) {
        super(BillManagement.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getBillManagementData(query) {
        return Model.axios.post(this.url("/get-bill-management-data"), query);
    }

    static getDownloadBillData(query) {
        return Model.axios.post(this.url("/download-bill-data"), query, { responseType: "blob"});
    }
}