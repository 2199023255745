<template>
  <table class="bg-white w-100 rpa-item-table">
    <tr>
      <th>会社名</th>
      <td :class="company.companyName === target.companyName || 'text-danger'">
        {{company.companyName}}</td>
      <td>{{target.companyName || '-'}}
        <span v-if="result.failedReason" class="text-danger"><br/>{{result.failedReason}}</span>
      </td>
    </tr>
    <tr>
      <th>検証番号</th>
      <td :class="input.registerNo === target.registerNo || 'text-danger'">
        {{input.registerNo}}</td>
      <td>{{target.registerNo || '-'}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["result", "company"],
  components: { },
  computed: {
    input: function(){
      if(this.result.processedData){
        return {
          registerNo: this.result.processedData.split(',')[0]
        }
      }
      return {}
    },
    target: function(){
      if(this.result.verifiedResult){
        const verifiedResult = JSON.parse(this.result.verifiedResult);
        return {
          companyName: verifiedResult.companyName,
          registerNo: verifiedResult.permissionNo
        }
      }
      return {}
    }
  }
}
</script>

<style>

</style>