<template>
  <div class="sheets">
    <template v-if="searchItems.length > 0">
    <div class="sheet" v-for="(item, index) in searchItems" :key="`address${index}`">
      <div>{{item.postalCode}}</div>
      <div>{{item.address}}</div>
      <br />
      <div>{{item.supplierName}} {{item.departmentName}}</div>
      <div>{{item.personName}} 様</div>
    </div>
    </template>
  </div>
</template>
<script>
import SupplierList from '@/model/SupplierList'

export default {
  components: {},
  props: [ 'supplierCodes' ],
  data: () => {
    return {
      searchItems: []
    }
  },
  watch: {
    supplierCodes () {
      this.show()
    }
  },
  methods: {
    show: function() {
      Promise.all([
        SupplierList.print( { supplierCodes : this.supplierCodes })
      ]).then((results) => {
        if (!results[0].data.length) this.searchItems = []
        this.searchItems = results[0].data
      }).then(() => {
        window.print()
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    }
  }
}
</script>
<style lang="scss">
@media print {
  #main-sidebar {
    display: none;  
  }
  .page-title {
    display: none;  
  }
  .supplier-list {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.sheets {
  display: none;
}
.sheet {
  page-break-after: always;
}
@media print {
  @page {
    size: A4 landscape;
  }
  .sheets {
    display: block;
  }
  .sheet {
    width: 1024px;
    height: 400px;
    margin: 20px;
    background: white;
    border: 1px solid $very-light-gray;
    padding: 50px;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
  }
}
</style>