<template>
  <div>
    <div class="p-3">
      <div class="row">
        <div class="col-6">
        <SearchFree placeholder="サプライヤコード、サプライヤ名、主担当者名、主担当者メールアドレス"
                      maxlength="100"
                      v-model="form.conditions.fullText"
                      @search="getSearchItems" />
        </div>
        <div class="col"><a href="#" @click="showDetailedSearchBar=!showDetailedSearchBar">{{showDetailedSearchBar?'詳細条件を非表示':'詳細条件を表示'}}</a></div>
      </div>
      <SupplierSearchDetailCondition :display="showDetailedSearchBar" :query="form.conditions" />
      <div class="mt-2 row">
        <div class="col-auto"><StopMembershipCheckBox :model="form.conditions"/></div>
        <div class="col"><MembershipCheckBox :model="form.conditions" /></div>
      </div>
      <div class="mt-2">
        <b-button type="button" class="btn btn-primary ml-3" @click="clearSearch()">クリアする</b-button>
        <b-button id="downloadModal" type="button" class="btn btn-primary ml-3" v-bind:disabled="form.result.length == 0">ダウンロード</b-button>
        <b-tooltip target="downloadModal" placement="bottom" variant="light">
          <b-form-checkbox-group v-model="form.downloadType" :options="downloadTypes" class="supplier_all__download_list" />
          <b-button type="button" class="btn btn-primary ml-3" @click="downloads">実行</b-button>
        </b-tooltip>
        <b-button id="promoteOpen" type="button" class="btn btn-primary ml-3" v-if="isShowPromote">Biz入会案内を送信</b-button>
      </div>
    </div>
    <div class="collect">
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center"><input class="reminder__checkbox reminder__checkbox--all checkbox-large" type="checkbox" v-on:click="onCheckRemindAll" /></th>
            <th class="text-center" style="width: 30%">サプライヤ</th>
            <th class="text-center">ステータス</th>
            <th class="text-center">入会状況</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="form.result.length == 0">
            <td class="text-left" colspan="5">
              <LoadingList :loaded="loaded" :list="form.result" emptyData="データがございません。"/>
            </td>
          </tr>
          <tr v-for="(item, index) in form.result" :key="`searchItems${index}`">
            <td class="text-center"><input class="reminder__checkbox reminder__checkbox--item checkbox-large" type="checkbox" v-model="item.selected" v-on:click="onCheck" /></td>
            <td>
              <router-link :to="{ name: 'SupplierDetail', params: { supplierCode: item.companyCode } }">
                <span v-on:click="saveSupplier(item)">
                  {{item.companyDisplayCode}}<br />
                  {{item.corporationNumber || '«未登録»'}}<br />
                  {{item.companyName || '«未登録»'}}<br />
                  {{item.mainUserName || '«未登録»'}}<br />
                  {{item.mainUserEmail || '«未登録»'}}</span>
              </router-link>
            </td>
            <td>
              <CompanyRegisterStatusView :is-compact="true" :status="item.registerStatus"/>
              <div>最終更新：{{item.lastUpdateDate}}</div>
            </td>
            <td class="text-center">
              {{membership.findTextBy(item.isOpenSupplier)}}
              <br />
              <template v-if="!item.isOpenGuideExclusion">{{stopMembership.findTextBy(item.isOpenGuideExclusion)}}</template>
              <template v-if="item.isOpenGuideExclusion">
                <a :id="'buyerInfo'+item.companyCode" class="supplier_all__stop_membership_buyerinfo" href="#" >{{stopMembership.findTextBy(item.isOpenGuideExclusion)}}</a>
                <b-tooltip :target="'buyerInfo'+item.companyCode" placement="bottom">
                  <ul class="supplier_all__stop_membership_buyerinfo_names">
                    <li v-for="(buyerName, index) in item.stopMembershipBuyerNames" :key="`${buyerName}${index}`">
                      {{buyerName}}
                    </li>
                  </ul>
                </b-tooltip>
              </template>
            </td>
            <td>
              <font-awesome-icon class="supplier_all__memo" :icon="['far', 'edit']" v-on:click="showMemo(item.companyCode)"></font-awesome-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-left">
        <b-button variant="primary" size="md" v-bind:disabled="form.isNoCheckItem" v-on:click="onRemind">安否確認メール送信</b-button>
      </div>
      <Pagination class="mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"></pagination>
    </div>
    <modal name="memo-modal" :draggable="true" :resizable="true" :scrollable="true" height="auto">
       <Memo :supplierCode="memoSupplierCode" />
    </modal>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";
</style>

<script>
import SupplierSearch from '@/model/SupplierSearch'  
import SupplierSafety from '@/model/SupplierSafety'  
import SupplierList from '@/model/SupplierList'
import StopMembership from '@/model/membership/StopMembership'
import Membership from '@/model/membership/Membership'

import Pagination from "@/components/molecule/Pagination.vue"
import SearchFree from "@/components/molecule/SearchFree.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import StopMembershipCheckBox from "@/components/atoms/membership/StopMembershipCheckBox.vue"
import MembershipCheckBox from "@/components/atoms/membership/MembershipCheckBox.vue"
import Memo from "@/components/organism/Memo.vue"
import CompanyRegisterStatusView from "@/components/atoms/CompanyRegisterStatusView.vue";
import SupplierSearchDetailCondition from "@/components/organism/SupplierSearchDetailCondition.vue"

export default {
  props: [ 'saveSupplier' ],
  components: {
    Pagination, SearchFree, LoadingList, StopMembershipCheckBox, MembershipCheckBox, 
    Memo, SupplierSearchDetailCondition, CompanyRegisterStatusView
  },
  data: () => {
    return {
      showDetailedSearchBar: false,
      loaded: true,
      form: {
        conditions: {
          byScore: {},
          byCompany: {},
          byOther: {},
          isOpenGuideExclusion: [],
          isOpenSupplier: []
        },
        paging: {
          sorting: []
        },
        result: []
      },
      stopMembership: StopMembership.create(),
      membership: Membership.create(),
      displayPromote: false,
      downloadTypes: [
        { text: '被災管理用', value: 'Safety' },
        { text: '基本情報', value: 'Basic' },
        { text: '会社情報（基本）', value: 'CompanyBasic' },
        { text: '会社情報（詳細）', value: 'CompanyDetail' },
        { text: '許認可情報', value: 'License' },
        { text: 'ユーザ情報', value: 'User' },
        { text: 'PR情報', value: 'Pr' },
        { text: 'その他', value: 'Others' },
      ],
      memoSupplierCode: null
    }
  },
  computed: {
    isShowPromote(){
      if(!this.$data.form.query) return false;
      if(!this.$data.form.query.condition) return false;
      return this.$data.form.query.condition.isStopMembership.length > 0
      &&
      this.$data.form.query.condition.isStopMembership[0] == false
      &&
      this.$data.form.query.condition.isMembership.length > 0
      &&
      this.$data.form.query.condition.isMembership[0] == false
      &&
      this.$data.displayPromote == true;
    }
  },
  created() {
    if(this.$store.state.pages.all){
      this.form.conditions = this.$store.state.pages.all.conditions || this.form.conditions;
      this.form.paging = this.$store.state.pages.all.paging || this.form.paging;
    }
    this.getSearchItems();
  },
  methods: {
    findDownloadType(value) {
      return this.downloadTypes.filter(type => type.value == value)[0].text
    },
    getSearchItems() {
      this.loaded = false;
      SupplierSearch.getAll(this.form).then(result => {
        this.form = result.data;
        this.loaded = true;
        this.$store.commit('pages', { 
          all: {
            paging: this.form.paging,
            conditions: this.form.conditions  
          }
        });
      }).catch(reason => this.$errorMessage(reason));
    },
    clearSearch() {
      this.form.conditions = {}
    },
    downloads() {
      if(Array.from(this.form.downloadType).find(type => type == 'Safety')) this.downloadSafety()
      this.download()
    },
    downloadSafety() {
      Promise.all([
          SupplierSafety.downloadSafety({
          condition: this.form.query.condition,
          pageable: this.form.searchItems.pageable
        })
      ]).then((results) => {
        const fileName = this.findDownloadType('Safety') + '.csv'
        var blob = new Blob([results[0].data])
        if (window.navigator.msSaveOrOpenBlob) {
          var decodedFileName = decodeURI(fileName)
          window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
        } else {
          const link = document.createElement('a')
          var url = (window.URL || window.webkitURL).createObjectURL(blob)
          link.href = url
          link.download = decodeURI(fileName)
          link.click()
          link.remove()
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    download() {
      Promise.all(
        Array.from(this.form.downloadType).filter(type => type != 'Safety')
            .map(type => {
              const promise = SupplierList.download(Object.assign({}, this.form.query, { downloadType: type }))
              return { promise: promise, downloadType: type}
            })
      ).then((results) => {
        results.forEach(result => {
          const fileName = this.findDownloadType(result.downloadType) + '.csv'
          result.promise.then((content) => {
            var blob = new Blob([content.data])
            if (window.navigator.msSaveOrOpenBlob) {
              var decodedFileName = decodeURI(fileName)
              window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
            } else {
              const link = document.createElement('a')
              var url = (window.URL || window.webkitURL).createObjectURL(blob)
              link.href = url
              link.download = decodeURI(fileName)
              link.click()
              link.remove()
            }
          })
        })
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    onCheck() {
      const item = document.getElementsByClassName("reminder__checkbox--item")
      this.form.isNoCheckItem = true
      item.forEach(element => {
        if (element.checked) this.form.isNoCheckItem = false 
      })
    },
    onCheckRemindAll() {
      const all = document.querySelector(".reminder__checkbox--all")
      const item = document.getElementsByClassName("reminder__checkbox--item")
      if (all.checked) { 
        item.forEach(element => element.checked = true)
        this.form.isNoCheckItem = false
      }
      if (!all.checked) {
        item.forEach(element => element.checked = false)
        this.form.isNoCheckItem = true
      }
    },
    onRemind() {
      this.$swal.confirm("安否確認メールを送信してもよろしいでしょうか？", undefined, () => {
        const request = this.form.searchItems.contents.filter(content => content.selected)
          .map(content => content.supplierCode)
        Promise.all([
          SupplierSafety.post({ supplierCodes: request }),
        ]).then(() => {
          this.$swal.success("送信しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    showMemo(supplierCode) {
      this.memoSupplierCode = supplierCode
      this.$modal.show('memo-modal');
    },
    hideMemo(){
      this.$modal.hide('memo-modal');
    }
  }
}
</script>