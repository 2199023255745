import Model from './_base';

export default class SupplierList extends Model {
    static entity = 'suppliers';

    constructor(data) {
        super(SupplierList.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static print(supplierCodes){
        return Model.axios.post(this.url('/print'), supplierCodes);
    }

    static download(condition){
        return Model.axios.post(this.url('/download'), condition);
    }
}