import Model from './_base';

export default class Category extends Model {
    static entity = 'category';

    constructor(data) {
        super(Category.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getList(){
        return Model.axios.get(this.url('/list'));
    }
    static getListWithDeleted(){
        return Model.axios.get(this.url('/list/with_deleted'));
    }
    static getSearchedList(keyword){
        return Model.axios.get(this.url('/search?keyword='+keyword));
    }
    static getSearchedListWithDeleted(keyword){
        return Model.axios.get(this.url('/search/with_deleted?keyword='+ keyword ));
    }

    static getFlatList(currentPage,itemsPerPage,keyword,deleteFlg){
        return Model.axios.get(this.url('/list/flat' + '?page=' + currentPage + '&size=' + itemsPerPage + '&keyword='+ keyword + '&with_deleted='+ deleteFlg));
    }

    static getCategory(id){
        return Model.axios.get(this.url('/' + id));
    }

    static createCategory(form){
        return Model.axios.post(this.url('/create'),form);
    }

    static updateCategoryInfo(id,form){
        return Model.axios.post(this.url('/info/' + id ),form);
    }

    static updateUnspsc(id,form){
        return Model.axios.post(this.url('/unspsc/' + id ),form);
    }

    static updateTerms(id,term){
        var params = new URLSearchParams();
        params.append('term', term);
        return Model.axios.post(this.url('/term/' + id ),params);
    }

    static updateLicenses(id,license){
        var params = new URLSearchParams();
        params.append('license', license);
        return Model.axios.post(this.url('/license/' + id ),params);
    }

    static updateIsNeedEvaluation(id,isNeedEvaluationFlag){
        var params = new URLSearchParams();
        params.append('isNeedEvaluation', isNeedEvaluationFlag);
        return Model.axios.post(this.url('/evaluation/' + id ),params);
    }

    static deleteCategory(id){
        return Model.axios.delete(this.url('/delete/' + id));
    }

    static deleteCategoryHard(id){
        return Model.axios.delete(this.url('/delete/' + id + '/hard'));
    }

    static getLicenses(){
        return Model.axios.get(this.url('/licenses'));
    }

    static getTerms(){
        return Model.axios.get(this.url('/terms'));
    }

    static downloadCsv(){
        return Model.axios.post(this.url('/download' ), { responseType: 'blob' });
}    
}