import Model from './_base';

export default class SupplierSearch extends Model {
    static entity = 'suppliers';

    constructor(data) {
        super(SupplierSearch.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getAll(query){
        return Model.axios.post(this.url('/search'), query);
    }

    static getVerified(query){
        return Model.axios.post(this.url('/search-verification'), query);
    }

    static getTSR(query){
        return Model.axios.post(this.url('/search-tsr'), query);
    }

    static getAntiSocial(query){
        return Model.axios.post(this.url('/search-antisocial'), query);
    }

    static getSearchStatus(query){
        return Model.axios.post(this.url('/search-status'), query);
    }

    static getProgress(query){
        return Model.axios.post(this.url('/progress'), query);
    }


}