<template>
  <div class="todo">
    <div class="todo__sub-title">
      <h1>Todo</h1>
    </div>
    <div>
      <ul class="todo__list">
        <li>振替口座登録待ち・・・・・・・・・・10件</li>
        <li>自動検証トラブル・・・・・・・・・・1件</li>
        <li>TSR情報更新待ち・・・・・・・・・・2件</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .todo__sub-title {
    font-weight: bold;
  }
  .todo__list {
    list-style: none;
  }
</style>