<template>
  <div>
    <PageTitle title="被災詳細">
      <router-link to="/system/safety/events" class="btn btn-link border ml-3">被災一覧へ戻る</router-link>
    </PageTitle>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <h4>安否状況集計</h4>
      <table class="table table-bordered">
        <tr>
          <th>災害名</th>
          <td>{{ event.name }}</td>
        </tr>
        <tr>
          <th>利用者数</th>
          <td>
            <label>対象者数:</label>
            <b-badge variant="light">24</b-badge>
            <label class="ml-2">応答数:</label>
            <b-badge variant="light">12</b-badge>
            <label class="ml-2">回答パーセント:</label>
            <b-badge variant="danger">50%</b-badge>
          </td>
        </tr>
        <tr>
          <th>通知アンケート</th>
          <td>
            アンケート名: <b-badge variant="light">安否状況通知アンケート</b-badge><br/>
            通知方法: <b-badge variant="light">メール</b-badge><br/>
            応答方法: 
            <b-badge variant="light">80% フォーム</b-badge>
            <b-badge variant="light" class="ml-2">13% SMS</b-badge>
            <b-badge variant="light" class="ml-2">7% 電話</b-badge>
            <br/>
          </td>
        </tr>
        <tr>
          <th>通知日時</th>
          <td>2021-01-01 12:00:00</td>
        </tr>
        <tr>
          <th>通知終了日時</th>
          <td>2021-01-02 12:00:00</td>
        </tr>
        <tr>
          <th>取引不可予定日時</th>
          <td>2021-01-03 12:00:00</td>
        </tr>
      </table>
      <b-button variant="light" size="sm">再送信</b-button>
      <b-button variant="light" size="sm" class="ml-4">今すぐ被災終了にする</b-button>
    </div>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <h4>安否状況一覧</h4>
          
      <a href="#">
        <font-awesome-icon icon="file-download"/>
        集計データ(CSVダウンロード)
      </a>
      
      <b-pagination 
        v-model="response.page" 
        :total-rows="response.total" 
        :per-page="response.per_page" 
        :aria-controls="'pagination'"
        align="center"/>
      <b-table striped hover responsive :items="response.items" :fields="fields" :current-page="response.page" :per-page="response.per_page" :aria-controls="'pagination'">
        <template slot-scope="props">
          <td>
            <b-button variant="light" size="sm" @click="$router.push('/system/safety/events/' + props.item.id)">詳細</b-button>
          </td>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
export default {
  components: {
    PageTitle
  },
  data() {
    return {
      event: {
        name: "災害発生",
        type: "メール",
        description: "",
        start_date: "",
        end_date: "",
        status: "",
      },
      fields: [
       
      ],
      response: {
        page: 1,
        per_page: 10,
        total: 0,
        total_pages: 0,
        items: [
          {
            '会社コード': "SA000123",
            '会社名': "ジーニーラボ株式会社",
            '応答日時': "2020-01-01 12:00:00",
            '応答方法': "メール",
            '応答状況': "応答済み",
            '取引状態':'可能'
          },
          {
            '会社コード': "SA000124",
            '会社名': "鈴木印刷株式会社",
            '応答日時': "2020-01-01 15:00:00",
            '応答方法': "電話",
            '応答状況': "応答済み",
            '取引状態':'可能'
          },
        ],
      }
    }
  }

}
</script>

<style>

</style>