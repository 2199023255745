<template>
  <div class="supplier_memo">
    <div>
      <PageTitle title="操作メモ"></PageTitle>
    </div>
    <div>
      <b-form-textarea v-model="form.memo"></b-form-textarea>
    </div>
    <div>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center">メモ</th>
            <th class="text-center supplier_memo__table_date">日時</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="searchItems.history.length === 0">
            <td class="text-left" colspan="2">
              <LoadingList :loaded="loadedList" :list="searchItems.history" :textCenter=true></LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in searchItems.history" :key="`${item[dataType.detailKey]}${index}`">
            <td>{{item.memo}}</td>
            <td>{{item.date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="supplier_memo__action">
      <b-button class="supplier_memo__action_execute" variant="primary" size="md" v-on:click="onSave">
        保存
      </b-button>
      <b-button variant="light" size="md" v-on:click="onHide">
        キャンセル
      </b-button>
    </div>
  </div>
</template>
<script>
import SupplierMemo from '@/model/SupplierMemo'
import LoadingList from "@/components/atoms/LoadingList"
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { LoadingList, PageTitle },
  props: ['supplierCode'],
  data: () => {
    return {
      loadedList: false,
      dataType: {},
      form: {},
      searchItems: {
        history: []
      }
    }
  },
  created() {
    this.onShow(this.supplierCode)
  },
  methods: {
    onShow: async function(supplierCode) {
      const result = await this.get(supplierCode)
      if (result.data.length) this.searchItems = []
      this.searchItems = result.data
      this.form = {}
      this.loadedList = true
    },
    onHide: function() {
      this.$modal.hide('memo-modal');
      this.$emit('hidden');
    },
    onSave: async function() {
      await this.save(this.supplierCode, this.form)
      this.onShow(this.supplierCode)
    },
    get: function(supplierCode) {
      return SupplierMemo.get(supplierCode)
        .catch((error) => this.$errorMessage(error))
    },
    save: function(supplierCode, form) { 
      return SupplierMemo.save(supplierCode, form)
        .catch((error) => this.$errorMessage(error))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/table.scss";

.supplier_memo {
  margin: 20px;

  .supplier_memo__action {
    text-align: center !important;
  }
  .supplier_memo__action_execute {
    margin: 20px;
  }
  .supplier_memo__table_date {
    width: 150px;
  }
}

</style>