<template>
  <div :class="`supplier_search_detail ${display ? 'supplier_search_detail--block' : 'supplier_search_detail--none'}`">
    <div class="row">
      <div class="col">
        <p><b>品目カテゴリでの絞り込み</b></p>
        <hr/>
        <div style="display:flex; max-width: 600px;">
        <b-form-select :value="null">
          <b-select-option :value="null">大カテゴリを選択</b-select-option>
        </b-form-select>
        <b-form-select :value="null" class="ml-2">
          <b-select-option :value="null">中カテゴリを選択</b-select-option>
        </b-form-select>
        <b-form-select :value="null" class="ml-2">
          <b-select-option :value="null">小カテゴリを選択</b-select-option>
        </b-form-select>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <p><b>評価での絞り込み</b></p>
        <hr/>
        <div class="mx-2">
          <div class="inline-form">
            <label>TSR評点</label>
            <b-form-select></b-form-select>
            <span></span>
          </div>
          <div class="inline-form">
            <label>利用者評価<br/>（物品・役務）</label>
            <b-form-select></b-form-select>
            <span>以上</span>
          </div>
          <div class="inline-form">
            <label>価格競争力<br/>（物品・役務）</label>
            <b-form-select></b-form-select>
            <span>以上</span>
          </div>
          <div class="inline-form">
            <label>勝率%<br/>（物品・役務）</label>
            <b-form-input type="number"/>
            <span>％以上</span>
          </div>
          <div class="inline-form">
            <label>不良率%<br/>（物品のみ）</label>
            <b-form-input type="number"/>
            <span>％以上</span>
          </div>
          <div class="inline-form">
            <label>納期遵守率%<br/>（物品のみ）</label>
            <b-form-input type="number"/>
            <span>％以上</span>
          </div>
          <div class="inline-form">
            <label>見積回答率%<br/>	（物品・役務）</label>
            <b-form-input type="number"/>
            <span>％以上</span>
          </div>
        </div>
      </div>
      <div class="col-5">
        <p><b>会社規模での絞り込み</b></p>
        <hr/>
        <div class="mx-2">
          <div class="inline-form">
            <label>法人格</label>
            <b-form-checkbox-group class="inline-checkboxes">
              <b-form-checkbox>法人のみ</b-form-checkbox>
              <b-form-checkbox>上場会社のみ</b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <div class="inline-form">
            <label>設立年月</label>
            <b-form-input type="text"></b-form-input>
            <span>以降</span>
          </div>
          <div class="inline-form">
            <label>資本金</label>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
          <div class="inline-form">
            <label>従業員数</label>
            <b-form-input type="number"></b-form-input>
            <span>名以上</span>
          </div>
          <div class="inline-form">
            <label>取引実績</label>
            <b-form-input type="number"></b-form-input>
            <span>件以上</span>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
          <div class="inline-form">
            <label>前期売上高<br/>（TSR表記では当期）</label>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
          <div class="inline-form">
            <label></label>
            <span class="text-center">税引前利益</span>
            <span></span>
            <span class="text-center">税引後利益</span>
            <span></span>
          </div>
          <div class="inline-form">
            <label>前期<br/>（TSR表記では当期）</label>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
          <div class="inline-form">
            <label>前々期<br/>（TSR表記では前期）</label>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
          <div class="inline-form">
            <label>前々々期<br/>（TSR表記では前々期）</label>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
            <b-form-input type="number"></b-form-input>
            <span>万円以上</span>
          </div>
        </div>
      </div>
      <div class="col">
        <p><b>その他条件での絞り込み</b></p>
        <hr/>
        <div class="mx-2">
          <div class="inline-form">
            <b-checkbox>納品先地域対応可能</b-checkbox>
            <b-form-input type="text" placeholder="郵便番号" style="width:160px;"></b-form-input>
          </div>
          <div class="inline-form">
            <b-checkbox>ISO9001 取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>ISO27001(ISMS)又はプライバシーマーク取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>ISO14001 取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>建設業法許認可取得済</b-checkbox>
            <!-- TODO: Add construction selectors -->
          </div>
          <div class="inline-form">
            <b-checkbox>産業廃棄物処理業許可取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>宅地建物取引業免許取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>旅行業登録済</b-checkbox>
            <b-form-select style="width:200px;"></b-form-select>
          </div>
          <div class="inline-form">
            <b-checkbox>労働派遣事業者届出済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>特許取得済</b-checkbox>
          </div>
          <div class="inline-form">
            <b-checkbox>取り扱いメーカー</b-checkbox>
            <b-form-input type="text" style="width:200px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: [ 'display', 'query' ],
  data: () => {
    return {
      areas: {}
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.supplier_search_detail {
  margin: 20px;
  width: 100%;
  font-size: 12px;
  &--none {
    display: none;
  }
  &--block {
    animation: fadeIn 0.5s ease;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

</style>