<template>
    <div class="bill-management">
        <PageTitle title="会費請求書"></PageTitle>
        <label class="ml-3">ビズハイウェイの会費請求書一覧です。</label> 
        <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getBillManagement"></pagination>
        <table class="table table-setting table-striped table-bordered mt-4">
            <thead>
                <tr class="text-center">
                    <th style="width:13%">請求書No.</th>
                    <th style="width:26%">請求明細</th>
                    <th style="width:22%">サプライヤー企業</th>
                    <th style="width:13%">合計</th>
                    <th style="width:13%">振替日</th>
                    <th style="width:13%">支払状況</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="billData.length === 0">
                    <td colspan=6 class="text-center">
                        <LoadingList :loaded="loadedList" :list="billData" emptyData="現在データがございません。"></LoadingList>
                    </td>
                </tr>
                <tr v-for="(item, index) in billData" :key="index" class="text-center">
                    <td>{{ item.billingNo }}</td>
                    <td>
                        <a href="javascript:;" @click.prevent="onRequestDownload(item)">
                            {{ item.targetPeriodFrom | toMonth }} ~ {{ item.targetPeriodTo | toMonth }} Biz会費
                        </a>
                    </td>
                    <td>{{ item.supplierCompanyName }} <br/> {{ item.supplierOrgName }}</td>
                    <td>￥{{ item.totalAmount | numberFormat }}</td>
                    <td>{{ item.transferDate | toDay }}</td>
                    <td>支払済み</td>
                </tr>
            </tbody>
        </table>
        <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getBillManagement"></pagination>
    </div>
</template>

<script>
import BillManagement from "@/model/BillManagement.js";

import PageTitle from "@/components/atoms/PageTitle.vue";
import Pagination from "@/components/molecule/Pagination.vue";
import LoadingList from "@/components/atoms/LoadingList.vue";

export default {
  components: { 
    PageTitle, 
    Pagination, 
    LoadingList 
  },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 50,
        sort: null,
        sortOrder: true
      },
      keyword: '',
      loadedList: false,
      billData: [],
      supplierCompanyList: [],
      supplierIdSelected: null,
      
    }
  },
  mounted() {
    this.getBillManagement(true);
  },
  methods: {
    getBillManagement(loadAll = false) {
        this.billData = [];
        // mock
        if (loadAll) {
            this.pagination.totalItemCount = 0;
            this.pagination.currentPage = 1;
        }

        Promise.all([
            BillManagement.getBillManagementData({
                pagination: this.pagination,
                queries: {keyword: this.keyword}
            })
        ]).then(response => {
            for (let item of response[0].data.items) {
                item.targetPeriodFrom = item.targetPeriodYearFrom + item.targetPeriodMonthFrom;
                item.targetPeriodTo = item.targetPeriodYearTo + item.targetPeriodMonthTo;
                this.billData.push(item);
            }
            this.pagination = response[0].data.pagination;
        }).catch(reason => {
            this.$errorMessage(reason)
        });

        this.loadedList = true;
    },
    onRequestDownload(item) {
        Promise.all([
          BillManagement.getDownloadBillData({
              supplierCompanyId: item.supplierCompanyId, billingNo: item.billingNo 
          })
        ])
        .then(response => {
            var blob = new Blob([response[0].data]);
            var fileName = decodeURI(response[0].headers["x-file-name"]);
            if (window.navigator.msSaveOrOpenBlob) {
                // For IE11 not support URL.createObjectURL()
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
            }
        })
        .catch(reason => { this.$errorMessage(reason) })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.bill-management {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
}

.bill-management-select {
    width: 300px;
    margin: 0.5rem;
    margin-left: 1rem;
}

</style>