import Model from './_base';

export default class BuyerMasterInfo extends Model {
    static entity = 'buyer-master-info';

    constructor() {
        super(BuyerMasterInfo.entity);
    }

    static getList(){
        return Model.axios.get(this.url());
    }
    
    static get(id){
        return Model.axios.get(this.url('/' + id));
    }
    
    static save(data){
        return Model.axios.post(this.url('/'), data);
    }

    static delete(id){
        return Model.axios.delete(this.url('/' + id));
    }

    
    static download(data){
        return Model.axios.post(this.url('/download'), data);
    }
    
    static upload(file,fileType){
        var formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", fileType);
        return Model.axios.post(this.url('/upload'), formData , { headers: { "Content-Type": "multipart/form-data" }, notLoading: true });
    }
}