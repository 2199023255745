import Model from '@/model/_base';

export default class SupplierBank extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierBank.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getSupplierBank(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/bank'));
    }
    static updateStatus(supplierCode, status){
        return Model.axios.put(this.url('/' + supplierCode + '/bank/change/'), status);
    }


    static getSupplierTransferBank(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/transfer-bank'));
    }
    static saveTransferBank(supplierCode, data){
        return Model.axios.post(this.url('/' + supplierCode + '/transfer-bank'), data);
    }
}