<template>
  <div id="app">
    <Header></Header>

    <div class="main-window">
      <Sidebar v-if="$store.state.logged"></Sidebar>
      <div :class="{'main-content':true, 'has-sidebar': $store.state.logged}">
        <router-view/>
        <div id="main-overlay" class="overlay">
          <b-spinner variant="primary" class="mx-auto my-auto" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios'
import Base from '@/model/_base'

import Header from '@/components/organism/Header'
import Footer from '@/components/organism/Footer'
import Sidebar from '@/components/molecule/Sidebar'
 
export default {
  name: "app",
  components: {
    Header, Footer, Sidebar
  },
  data: (()=> {
    return {
      showSidebar: true
    }
  }),
  created: function() {
    axios.defaults.headers.common['Authorization'] = this.$store.state.accessToken;
    axios.interceptors.request.use(function(config) {
      // Do something before request is sent
      document.getElementById("main-overlay").className = "overlay show";
      return config;
    }, function(error) {
      document.getElementById("main-overlay").className = "overlay";
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function(response) {
      document.getElementById("main-overlay").className = "overlay";
      return response;
    }, error => {
      document.getElementById("main-overlay").className = "overlay";
      if( error.response.status == 403){

        this.$store.commit('logout');
        this.$router.push('/login');
      }
      return Promise.reject(error);
    });

    axios.get(Base.baseURL + 'status/token').then(()=>{});
    
    if(this.$store.state.user && this.$store.state.user.role){
        this.showSidebar = false
    }
  }
};
</script>

<style lang="scss">
  @import './assets/css/main.scss';

  .main-window {
    padding-top: 70px;
    .main-content {
      margin-left: 0;
      &.has-sidebar{
        margin-left: 84px;
      }
    }
  }

  .main-content{
    position:relative;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
    z-index: 400;
    display:none;
    &.show{
      display:flex;
    }
  }
</style>