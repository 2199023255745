import Model from './_base';

export default class SupplierStatus extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierStatus.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplierCode) {
        return Model.axios.get("https://stg-api.bizhiway.cloud/j2-api/demo/status/" + supplierCode);
    }

    static set(supplierCode, statusName, status) {
        return Model.axios.post("https://stg-api.bizhiway.cloud/j2-api/demo/status/" + supplierCode + '?statusName='+ statusName + '&status=' + status);
    }
    
    static setContracts(supplierCode){
        return Model.axios.post("https://stg-api.bizhiway.cloud/j2-api/demo/contract/" + supplierCode);
    }
    
    static setBuyerContracts(supplierCode){
        return Model.axios.post("https://stg-api.bizhiway.cloud/j2-api/demo/contract-buyer/" + supplierCode);
    }
}