<template>
  <div>
    <PageTitle title="共通取引基本約款 登録"></PageTitle>
    <div class="term mx-3 mx-lg-5">
      <div class="term__file_group mb-3">
        <label class="term__file_label">約款名称</label>
        <div class="term__file_body">
          <p class="term__file_offset mb-0"><b-form-input v-model="form.name" placeholder="契約名称を入力" /></p>
          <span v-if="alert.name" class="search-alert text-danger">約款ファイル名は1字以上100字以内の間で入力してください。</span>
        </div>
      </div>
      <div class="term__file_group mb-3">
        <label class="term__file_label">約款ファイル</label>
        <div class="term__file_body">
          <b-form-file id="file" v-model="pdfFile" placeholder="-- 約款ファイルを選択 --"></b-form-file>
          <span v-if="alert.file" class="search-alert text-danger mb-0">約款ファイルは必須項目です。</span>
        </div>
      </div>
      <div class="text-center mt-5">
        <b-button variant="primary" size="lg" v-on:click="onSave">
          <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
          契約約款を登録
        </b-button>
        <br />
        <b-button variant="light" class="mt-2" :to="{ name : 'Terms' }">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          キャンセル
        </b-button>
      </div>  
    </div>
  </div>
</template>

<style scoped lang="scss">
  
.term {
  margin: 10px;
}

.term__file {
  padding: 10px;
  background: $light-cyan-blue;
}
.term__file_group {
  display: flex;
  align-items: flex-start;
  &--first {
    border-bottom: 1px solid $very-light-gray;
  }
}
.term__file_label {
  flex: 2;
  font-size: 16px;
  padding-right: 1rem;
  margin-top: 6px;
}
.term__file_body {
  flex: 10;
}
.term__file_offset {
  margin-top: 6px;
}

</style>

<script>
import Term from "@/model/Term";
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
  data: () => {
    return {
      form:{
          name:""
      },
      alert:{
        name:false,
        file:false,
      },
      pdfFile:null,
    }
  },
  props: [],
  methods: {
    onSave() {      
      this.alert.name = false
      this.alert.file = false
      if(!this.form.name || (this.form.name.length < 1 || this.form.name.length > 100)){
        this.alert.name = true
      }
      if(!this.pdfFile){
        this.alert.file = true
      }

      if(!this.alert.name && !this.alert.file){
        this.$swal.confirm("約款を登録します。よろしいですか？", undefined, () => {
          this.form.isFile = 0;
          this.form.isNewFile = 0;
          if(this.pdfFile!=null){
            this.form.isFile = 1;
            this.form.isNewFile = 1;
          }
          Promise.resolve(
          ).then(() => Term.create(this.form,this.pdfFile)
          ).then(() => {
            this.$router.push("/system/terms");
            this.$swal.success("約款の登録が完了しました", undefined, () => {})
          }).catch(reason => {
            this.$errorMessage(reason);
          });
        })
      }
    }
  }
}
</script>