<template>
  <div>
    <PageTitle title="会費設定"></PageTitle>
    <div class="bg-light-blue p-3 mx-3 my-3">
      <table class="table border">
        <colgroup>
          <col name="no" width="5%"/>
          <col name="active" width="5%"/>
          <col name="amount" width="10%"/>
          <col name="start_date" width="10%"/>
          <col name="transfer_date" width="10%"/>
          <col name="target_period" width="10%"/>
          <col name="free_month" width="10%"/>
          <!-- <col name="trustee_code" width="5%"/>
          <col name="trustee_name" width="20%"/> -->
          <col name="operation" width="10%"/>
        </colgroup>
        <thead>
          <tr>
              <th>No</th>
              <th>有効</th>
              <th>会費月額</th>
              <th>適用開始日</th>
              <th>振替日</th>
              <th>振替月数</th>
              <th>無料月数</th>
              <th style="display:none;">委託者コード</th>
              <th style="display:none;">委託者名</th>
              <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fee, index) in fees" :key="index">
            <td>{{index + 1}}</td>
            <td v-if="fee.active"><font-awesome-icon icon="check"/></td>
            <td v-else></td>
            <td>
              {{fee.amountFee && (fee.amountFee.toLocaleString("en-US") + '円')}}
              <br/>
              <span class="text-muted small">消費税({{fee.amountFeeTax||0}}%)</span>
            </td>
            <td>{{fee.startDate}}</td>
            <td>{{fee.transferDate}}</td>
            <td>{{fee.targetPeriod}}</td>
            <td>{{fee.freeMonth}}</td>
            <td style="display:none;">{{fee.trusteeCode}}</td>
            <td style="display:none;">{{fee.trusteeName}}</td>
            <td>
              <router-link v-if="!fee.active" class="btn small btn-primary no-min" :to="{ name: 'MembershipFeeManagementEdit', params: {id: fee.id} }">変更</router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2 text-center">
        <router-link class="btn small btn-primary no-min" :to="{ name: 'MembershipFeeManagementEdit', params: {id: 0} }">追加</router-link>
      </div>
    </div>
  </div>
</template>
<style scoped>
  table tr td:not(:first-child){
    border-left: 1px solid #dee2e6;
  }
</style>
<script>
import MembershipFeeManagement from "@/model/MembershipFeeManagement";

import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
  data: () => {
    return {
      fees: []
    }
  },
  mounted() {
    this.loadMembershipFeeSetting()
  },
  methods: {
    loadMembershipFeeSetting() {
      let currentMonth = this.$moment().format('yyyy-MM');
      MembershipFeeManagement.loadInfo().then(result => {
        this.fees = result.data.map( f => {
          //TODO Move active check to backend.
          f.active = this.$moment(f.startDate).format('yyyy-MM') === currentMonth;
          f.startDate = this.$moment(f.startDate).format('yyyy-MM-DD');
          return f;
        });
      }).catch((reason) => this.$errorMessage(reason));
    }
  }
}
</script>