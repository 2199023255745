<template>
  <div>
    <PageTitle title="サプライヤー検証結果"></PageTitle>
    <div class="p-3">
      <CompanyDetailHeader :company="form.company"/>
      <div class="mt-4 p-3 bg-container">
        <h5>許認可情報</h5>
        <div class="mt-2">  
          <table class="table border-table">
            <tbody>
              <tr v-if="form.licenses.length <= 0">
                <td>データがありません。</td>
              </tr>
              <RPAItem v-for="license in form.licenses" :key="license.id" :result="license" :company="form.company" @refresh="reload"/>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-4 p-3 bg-container">
        <h5>認証情報</h5>
        <div class="mt-2">  
          <table class="table border-table">
            <tbody>
              <tr v-if="form.certificates.length <= 0">
                <td>データがありません。</td>
              </tr>
              <RPAItem v-else v-for="certificate in form.certificates" :key="certificate.id" :result="certificate" :company="form.company" @refresh="reload"/>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-4 p-3 bg-container">
        <h5>特許情報</h5>
        <div class="mt-2">  
          <table class="table border-table">
            <tbody>
              <tr v-if="form.patents.length <= 0">
                <td>データがありません。</td>
              </tr>
              <RPAItem v-else v-for="patent in form.patents" :key="patent.id" :result="patent" :company="form.company" @refresh="reload"/>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-4 p-3 bg-container">
        <h5>システム情報</h5>
        <div class="mt-2">
          <div>
            <label>最終更新日時</label>
            <div>2021-01-20 09:08:22</div>
          </div>
          <div>
            <label>最終更新者</label>
            <div>鈴木太郎（12345_12345678）</div>
          </div>
          <div>
            <label>再入力依頼日時</label>
            <div>2021-01-19 20:00:05</div>
          </div>
          <b-button variant="light" @click.prevent="reentryRequest">まとめて再入力依頼</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .bg-container{
    background: $light-cyan-blue;
  }
</style>

<script>
import SupplierVerification from '@/model/check/SupplierVerification.js';

import PageTitle from "@/components/atoms/PageTitle.vue";
import RPAItem from "@/components/molecule/rpa/RPAItem.vue";

import CompanyDetailHeader from '@/components/organism/CompanyDetailHeader.vue';

export default {
  components: { PageTitle, RPAItem, CompanyDetailHeader },
  props: {},
  data: function() {
    return {
      form: {
        company: {},
        licenses: [],
        certificates: [],
        patents: []
      }
    }
  },
  mounted() {
    this.reload();
  },
  computed: {
    address() {
      return this.detail.address1 + (this.detail.address2 ? this.detail.address2 : '')
    }
  },
  methods: {
    reload(){
      SupplierVerification.getVerification(this.$route.params.supplierCode).then(result => {
        this.form = result.data;
      }).catch(reason => this.$errorMessage(reason));
    },
    getDetail() {
      
    },
    getRegistrationStatus() {
      
    },
    reentryRequest(){
      SupplierVerification.reentryRequest(this.$route.params.supplierCode).then(result => {
        console.log(result);
        this.$successMessage("再入力依頼しました。");
      }).catch(reason => this.$errorMessage(reason));
    }
  }
}
</script>
