<template>
  <div class="loading-list" v-show="Object.keys(list).length === 0">
    <label class="h6 mb-0" v-if="loaded">{{emptyData}}</label>
    <div :class="{ 'text-center': textCenter }" v-else>
      <div class="flex-center d-flex">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="align-self-center font-weight-bold">&nbsp;{{loadingText }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex-center {
  justify-content: center;
}
</style>

<script>
export default {
  props: {
    loaded: {
      type: Boolean,
      required: true
    },
    emptyData: {
      type: String,
      default: "現在データがございません。"
    },
    list: {
      type: [Array, Object],
      required: true
    },
    textCenter: {
      type: Boolean,
      default: true
    },
    loadingText: {
      type: String,
      default: ""
    }
  }
};
</script>
