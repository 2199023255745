<template>
  <div class="request">
    <div class="request__search">
      <input type="text" class="form-control w-50" placeholder="サプライヤコード、サプライヤ名" v-model="keyWord" maxlength="100" />
      <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
        <font-awesome-icon icon="search" />
      </button>
    </div>
    <div class="request__summary">
      <div>請求合計金額：{{ totalAmountRequestTransferFee | numberFormat }}円</div>
      <div>回収済み金額：{{ totalAmountTransferredFee | numberFormat }}円</div>
      <div>未収金額：{{ totalAmountUnTransferFee | numberFormat }}円</div>
    </div>
    <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:30%">サプライヤ</th>
          <th class="text-center" style="width:25%">対象期間</th>
          <th class="text-center" style="width:15%">請求金額</th>
          <th class="text-center" style="width:15%">振替日</th>
          <th class="text-center" style="width:15%">振替結果</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in searchItems" :key="`${item[dataType.detailKey]}${index}`">
          <td>{{item.supplierDisplayCode}}<br />{{item.supplierName}}</td>
          <td class="text-center">{{item.targetPeriodYearFrom}}/{{item.targetPeriodMonthFrom}} ～ {{item.targetPeriodYearTo}}/{{item.targetPeriodMonthTo}}</td>
          <td class="text-right">{{item.requestFee | numberFormat}}円</td>
          <td>{{item.requestDate | toDay}}</td>
          <td>
            {{ item.transferStatusName || '未収' }}
            <br/>
            <a href="#" class="small text-muted" @click.prevent="onRequestDownload(item)">
              <font-awesome-icon icon="download"/> 請求書PDF
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
    <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
  </div>
</template>

<script>
import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import TransferDataManagement from '@/model/TransferDataManagement'  
import BillManagement from "@/model/BillManagement.js";

// const TRANSFER_RESULT = {
//   collected: "回収",
//   uncollected: "未収"
// }

export default {
  components: { Pagination, LoadingList },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: true,
      dataType: {},
      searchItems: [],
      totalAmountRequestTransferFee: 0,
      totalAmountTransferredFee: 0,
      totalAmountUnTransferFee: 0,
      keyWord: "",
      currentMonth: null,
      currentYear: null
    }
  },
  props: {
  },
  watch: {},
  mounted() {
    this.getSearchItems(true);
  },
  methods: {
    async getSearchItems(loadAll = false) {
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }
      if(!this.$route.params.currentMonth){
        const response = await TransferDataManagement.getMonth();
        if (response) {
           this.currentMonth = response.data.body.lastMonth;
           this.currentYear = response.data.body.lastYear;
         }
      }else{
           this.currentMonth = this.$route.params.currentMonth;
           this.currentYear = this.$route.params.currentYear;
      }
      TransferDataManagement.getLastMonthTransferList({pagination: this.pagination, queries: {
        keyWord: this.keyWord, 
        currentMonth: this.currentMonth, 
        currentYear:  this.currentYear 
       }})
      .then(response => {
        this.searchItems = response.data.items.dataList;
        this.totalAmountRequestTransferFee = response.data.items.totalAmountRequestTransferFee;
        this.totalAmountTransferredFee = response.data.items.totalAmountTransferredFee;
        this.totalAmountUnTransferFee = response.data.items.totalAmountUnTransferFee;
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
    },
    onRequestDownload(item) {
        Promise.all([
          BillManagement.getDownloadBillData({
              supplierCompanyId: item.supplierCompanyId, billingNo: item.requestNo 
          })
        ])
        .then(response => {
            var blob = new Blob([response[0].data]);
            var fileName = decodeURI(response[0].headers["x-file-name"]);
            if (window.navigator.msSaveOrOpenBlob) {
                // For IE11 not support URL.createObjectURL()
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
            }
        })
        .catch(reason => { this.$errorMessage(reason) })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.request {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.request__search {
  display: flex;
  margin: 10px;
}
.request__summary {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

</style>