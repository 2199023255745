import Model from './_base';

export default class ItemManagement extends Model {
    static entity = 'search-item-management';

    constructor(data) {
        super(ItemManagement.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static downloadItemManagementCsv(data){
        return Model.axios.post(this.url('/download'),data);
    }
    
    
    static uploadItemManagementCsv(file){
        var formData = new FormData();
        formData.append("file", file);
        return Model.axios.post(this.url("/upload"), formData, { headers: { "Content-Type": "multipart/form-data" }, notLoading: true,responseType: 'blob' });
    }
}    