<template>
  <div class="page">
    <div class="container">
      <div class="row">
        <div class="col">
          
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">管理者システムへログイン</div>
            <div class="card-body">
              <b-form @submit="onSubmit">
                <div class="biz-form-group">
                  <div class="biz-form-body">
                    <b-form-group class="biz-input-group" label="企業コード">
                      <b-form-input type="text" v-model="form.companyCode" placeholder="例) company" required></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="biz-form-group">
                  <div class="biz-form-body">
                    <b-form-group class="biz-input-group" label="ログイン名">
                      <b-form-input type="text" v-model="form.email" placeholder="例) name"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="biz-form-group">
                  <div class="biz-form-body">
                    <b-form-group class="biz-input-group" label="パスワード">
                      <b-form-input type="password" v-model="form.password" placeholder="例) password"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <b-button type="submit" variant="primary">ログイン</b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
        <div class="col">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/model/Login.js';
export default {
  components: {  },
  data: () => ({
    form: { }
  }),
  methods:{
    onSubmit(event){
        event.preventDefault();
        Login.login(this.form).then(result => {
            this.$store.commit("login", {
              accessToken: result.headers.authorization,
              user: Object.assign(result.data, {
                role: {},
                status: {}
              }),
            });
            this.$router.push({
              path: "/dashboard",
              params: {}
            });
        }).catch(reason => {
          console.log(reason);
          this.$bvToast.toast("企業コード、ユーザー名、パスワードの何れかが正しくありません。", {
            title: "ログインエラーが発生しました。",
            variant: "danger",
            solid: true
          })
        })
        
      },
  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 1em;
}

.biz-form-group {
  display: flex;
  align-items: flex-start;
  &.centered {
    align-items: center;
  }
  &.seperated {
    border-bottom: 1px solid $white;
  }
  .biz-form-label {
    flex: 2;
    font-size: 16px;
    padding-right: 1rem;
    margin-top: 6px;
    &.required {
      &:after {
        content: '必須';
        background: $red;
        font-size: 12px;
        color: $white;
        height: 24px;
        width: 40px;
        padding-top: 3px;
        border-radius: 2px;
        display:block;
        text-align: center;
        float: right;
       }
    }
  
    &.optional {
      &:after {
        content: '任意';
        background: $light-gray;  
        font-size: 12px;
        height: 24px;
        width: 40px;
        padding-top: 3px;
        border-radius: 2px;
        display:block;
        text-align: center;
        float: right;
       }
    }
  }
 
  .biz-form-body {
    flex: 10;
  }
  .biz-offset{
    margin-top: 6px;
  }
}

.biz-input-group {
  & > label {
    font-size: 12px;
  }
  .form-control, .custom-select {
    height:36px;
    font-size: 12px;
  }
  .form-text {
    font-size: 12px;
  }
  .custom-control-label {
    font-size: 14px;
  }
  legend{
    font-size: 12px;
  }
  .input-group-text{
    font-size: 12px;
  }
  .input-group-prepend, .input-group-append{
    height:36px;
  }
}

.card {
  &.card-header{
    background: $blue;
    box-shadow: 1px 0 1px rgba(204, 204, 204, 0.5);
    border-radius: 0;
    color: $white;
    font-size: 20px;
    p {
      display: inline;
      font-size: 14px;
      &:before{
        content: '|';
        font-size: 22px;
        color: $light-gray;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .control {
      float: right;
      a {
        color: $white;
        font-size: 14px;
      }
    }
  }
  .card-body {
    background: $light-cyan-blue;
  }
}

</style>