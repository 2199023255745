<template>
    <div class="payment-notice-source">
        <PageTitle title="支払通知元管理"></PageTitle>
        <div class="bg-light-blue m-2 mx-4 p-3">
            <div class="form-item">
                <label>会社名</label>
                <b-col sm="4"><b-form-input v-model="form.name"></b-form-input></b-col>
            </div>
            <div class="form-item mt-4">
                <label>郵便番号(〒)</label>
                <b-col sm="2"><b-form-input v-model="form.postalCode"></b-form-input></b-col>
            </div>
            <div class="form-item">
                <label>住所</label>
                <b-col sm="8"><b-form-textarea v-model="form.address"></b-form-textarea></b-col>
            </div>
            <div class="form-item mt-4">
                <label>担当窓口</label>
                <b-col sm="3"><b-form-input v-model="form.windowStaffName"></b-form-input></b-col>
            </div>
            <div class="form-item">
                <label>TEL</label>
                <b-col sm="4"><b-form-input v-model="form.phoneNumber"></b-form-input></b-col>
            </div>
            <div class="form-item">
                <label>FAX</label>
                <b-col sm="4"><b-form-input v-model="form.fax"></b-form-input></b-col>
            </div>
            <div class="text-center mt-3">
                <b-button type="button" variant="success" @click="save">保存</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentClosing from "@/model/PaymentClosing";
import PageTitle from "@/components/atoms/PageTitle.vue";
export default {
    components: {PageTitle},
    data: function(){
        return {
            form: {}
        }
    },
    mounted: function(){
        this.load();
    },
    methods:{
        load(){
            PaymentClosing.loadPaymentNoticeSource().then(result => {
                this.form = result.data;
            }).catch(reason => this.$errorMessage(reason));
        },
        save(){
            PaymentClosing.savePaymentNoticeSource(this.form).then(() => {
                this.$successMessage("支払通知元情報を保存しました。");
                this.load();
            }).catch(reason => this.$errorMessage(reason));
        }
    }
}
</script>

<style>

</style>