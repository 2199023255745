<template>
  <div class="container-fluid">
    <PageTitle title="標準品目管理"></PageTitle> 
    <div class="row border p-3">
      <div class="col-12 mb-3 p-0 border p-3">
        <div>
          <span class="float-left">
            <!--button type="button" class="btn btn-warning mr-1">アップロード</button-->
            <button type="button" class="btn btn-primary" @click="downloadCsv">ダウンロード</button>
          </span>  
        </div>
      </div>
      <div class="col-12 p-0 d-flex">
        <input type="text" class="form-control w-50"  v-model="keyword" placeholder="品目カテゴリコード,品目カテゴリ名,検索用品目,UNSPSC" maxlength="100"/>
        <button type="button" class="btn btn-warning ml-3" @click="search" >
          <font-awesome-icon icon="search" />
        </button>
        <button type="button" class="btn btn-light ml-1"  @click="clearSearch" >
          <font-awesome-icon icon="times"/>
        </button>
      </div>
      <span v-if="alert.search" class="search-alert text-danger">検索は1字以上50字以内の間で行ってください。</span>
      <template>
        <div class="mt-3 w-100">
          <hr class="mt-0" style="border-top-width: 2px;" />
          <div class="d-flex mb-3">
            <div class="align-self-center h5 mb-0 mr-2">品目カテゴリ</div>
            <div class="switch">
              <input id="switch" type="checkbox" v-model="listMode" @change="changeList()">
              <label class="m-0" for="switch"></label>
            </div>
            <div class="with-delete row float-right">
              <span class="ml-3 align-self-center">削除済を表示</span>
              <div class="toggle-switch ml-2 align-self-center">
                <input id="toggle" class="toggle-input" type='checkbox' v-model="withDeleted" @change="changeList()" />
                <label for="toggle" class="toggle-label"/>
              </div>
            </div>
          </div>
          <hr class="mt-0" style="border-top-width: 2px;" />
          
          <TreeList :categoryListUI="categoryListUI" @parentCategoryListUI="updateCategoryListUI" :listMode="listMode" :searched="searched"></TreeList>
          
          <div class="row" v-if="listMode==true">
            <div class="col-12">
              <div class="d-flex justify-content-between">
              <span class="col-2 d-flex align-items-center">
              {{pagination.start}} - {{pagination.end}} / 全{{pagination.totalItemCount}}件
              </span>
              <div class="col-6 mx-auto text-center">
                <Pagination class="my-2 mx-0 w-100" :pagination="pagination" @loadList="getCategoryFlatList(withDeleted)"></pagination>
              </div>
              <div class="form-group col-2 d-flex align-items-center justify-content-end m-0">
                <select id="select1a" class="form-control">
                  <option>10件表示</option>
                  <option>20件表示</option>
                  <option>50件表示</option>
                  <option>100件表示</option>
                </select>
              </div>
              </div>
              <table class="table table-setting table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th class="list-cat-name text-center">大カテゴリ</th>
                    <th class="list-cat-name text-center">中カテゴリ</th>
                    <th class="list-cat-name text-center">小カテゴリ</th>
                    <th class="text-center">検索用品名</th>
                    <th class="text-center">UNSPSC</th>
                    <th class="text-center">共通約款</th>
                    <th class="text-center">該当許認可</th>
                    <th class="text-center">利用者評価利用</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="categoryListFlat.length === 0">
                    <td class="text-left">
                      <LoadingList :loaded="loadedList" :list="categoryListFlat" emptyData="現在データがございません。"></LoadingList>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in categoryListFlat.content" :key="`${index}`">
                    <td>
                      <span class="deleted-category mr-1" v-if="item['topIsDeleted']==true">
                        (削除済)
                      </span>
                      {{item['topCategoryName']}}</td>
                    <td>
                      <span class="deleted-category mr-1" v-if="item['midIsDeleted']==true">
                        (削除済)
                      </span>
                      {{item['midCategoryName']}}</td>
                    <td>
                      <span class="deleted-category mr-1" v-if="item['lowIsDeleted']==true">
                        (削除済)
                      </span>
                      {{item['lowCategoryName']}}</td>
                    <td></td>
                    <td>{{item['lowUnspsName']}}</td>
                    <td class="text-center align-middle"><span v-if="item['terms']">{{item['terms'].split('/').length}}</span></td>
                    <td class="text-center align-middle"><span v-if="item['license']">{{item['license'].split('/').length}}</span></td>
                    <td class="text-center align-middle">
                      <span v-if="item['isNeedEvaluation']">有</span>
                      <span v-else>無</span>                      
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getCategoryFlatList"></pagination>
            </div>
          </div>      
        </div>
      </template>
    </div>
    
  </div>
</template>

<style scoped lang="scss" src="./BasicItemCategory.scss">  
</style>

<script>
import Category from "@/model/Category";
import Pagination from "@/components/molecule/Pagination.vue";
import LoadingList from "@/components/atoms/LoadingList.vue";
import PageTitle from "@/components/atoms/PageTitle.vue";
import TreeList from './BasicItemCategoryTreeList.vue'

export default {
  //components: { Pagination, LoadingList,PageTitle },
  components: {PageTitle, Pagination,LoadingList,TreeList},
  data: () => {
    return {
      keyword:"",
      searchedKeyword:"",
      searched:false,      
      withDeleted:false,
      listMode:false,
      modal: false,
      pagination: {
        currentPage: 0,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true,
        start: null,
        end: null
      },
      ps: null,
      loadedList: true,
      categoryListUI: [],
      categoryListFlat: [],
      catInfo:false,
      minCatInfo:false,
      alert:{
        search:false,
      },
      
      
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.show()    
  },
  methods: {    
    show() {
    },
    getCategoryFlatList(deleteFlg){
      Promise.all([
        Category.getFlatList(this.pagination.currentPage -1,this.pagination.itemsPerPage,this.searchedKeyword,deleteFlg),
      ]).then((results) => {
        if (!results[0].data.length) this.categoryListUI = {}
        this.categoryListFlat = results[0].data
        var start = (this.categoryListFlat.number * this.categoryListFlat.size) + 1
        if(this.categoryListFlat.totalElements == 0){
          start = 0
        }
        var end = this.categoryListFlat.size * (this.categoryListFlat.number + 1)
        if(end > this.categoryListFlat.totalElements){
          end = this.categoryListFlat.totalElements
        }
        this.pagination = {
          currentPage: this.categoryListFlat.number + 1,
          totalItemCount: this.categoryListFlat.totalElements,
          itemsPerPage: this.categoryListFlat.size,
          start:start,
          end:end

        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    changeList(){
     if(!this.keyword && this.searchedKeyword){
        this.keyword = this.searchedKeyword
      }else{     
        this.searchedKeyword = this.keyword
      }

      if(!this.withDeleted && !this.listMode){
        this.searchTree()
      }
      if(this.withDeleted && !this.listMode){        
          this.searchTreeWithDeleted()
      }      
      if(!this.withDeleted && this.listMode){        
        this.getCategoryFlatList(this.withDeleted)
      }
      if(this.withDeleted && this.listMode){
        this.getCategoryFlatList(this.withDeleted)
      }
    },
    search(){
      this.searchedKeyword = this.keyword
      if(this.keyword.length > 0 && this.keyword.length <= 50){
        this.changeList()
        this.alert.search = false
        this.catInfo=false;
        this.minCatInfo=false;
      }else{
        this.alert.search = true
      }
    },
    searchTree(){
       Promise.resolve(
        Category.getSearchedList(this.searchedKeyword),
      ).then((result) => {
        this.searched = true
        this.categoryListUI = result.data
        this.searchedKeyword = this.keyword
      }).catch(reason => {
        this.$errorMessage(reason)
      })  
    },
    searchTreeWithDeleted(){
      Promise.resolve(
        Category.getSearchedListWithDeleted(this.searchedKeyword),
      ).then((result) => {
        this.searched = true
        this.categoryListUI = result.data
        this.searchedKeyword = this.keyword
      }).catch(reason => {
        this.$errorMessage(reason)
      })  
    },
    clearSearch(){
      this.keyword=""
      this.searchedKeyword=""
      this.alert.search = false
      this.changeList()
      
    },
    downloadCsv(){
      Promise.all([
        Category.downloadCsv(),
      ]).then((results) => {
        const fileName = '共通品目カテゴリ.csv'
        var blob = new Blob([results[0].data])
        if (window.navigator.msSaveOrOpenBlob) {
          var decodedFileName = decodeURI(fileName)
          window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
        } else {
          const link = document.createElement('a')
          var url = (window.URL || window.webkitURL).createObjectURL(blob)
          link.href = url
          link.download = decodeURI(fileName)
          link.click()
          link.remove()
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    updateCategoryListUI(list) {
      this.categoryListUI = list;
    }
  }
}
</script>