<template>
  <div class="container-fluid">
    <PageTitle title="取扱品目カテゴリ品名辞書管理"></PageTitle> 
    <div class="row border p-3">
      <div class="col-12 mb-3 p-0 border p-3">
        <div>
          <span class="float-left">
            <input type="file" id="upload" ref="uploadref" style="display: none" accept=".csv" @change="selectedFile()">
            <button type="button" class="btn btn-primary mr-1" @click="downloadCsv">ダウンロード</button>
            <button type="button" class="btn btn-warning mr-1" @click="uploadCsv" >アップロード</button>
          </span>  
          <span v-if="alert.upload1" class="search-alert text-danger">ファイルサイズは10MB以下にしてください。</span>
          <span v-if="alert.upload2" class="search-alert text-danger">CSVファイルを選択してください。</span>
        </div>
      </div>
      <div class="col-12 p-0 d-flex">
        <input type="text" class="form-control w-50"  v-model="keyword" placeholder="品目カテゴリコード,品目カテゴリ名,検索用品目,UNSPSC" maxlength="100"/>
        <button type="button" class="btn btn-warning ml-3" @click="search" >
          <font-awesome-icon icon="search" />
        </button>
        <button type="button" class="btn btn-light ml-1"  @click="clearSearch" >
          <font-awesome-icon icon="times"/>
        </button>
      </div>
      <span v-if="alert.search" class="search-alert text-danger">検索は1字以上50字以内の間で行ってください。</span>
      <template>
        <div class="mt-3 w-100">
          <hr class="mt-0" style="border-top-width: 2px;" />
          <div class="d-flex mb-3">
            <div class="align-self-center h5 mb-0 mr-2">品目カテゴリ</div>
          </div>
          <hr class="mt-0" style="border-top-width: 2px;" />
          
          <TreeList :categoryListUI="categoryListUI" @parentCategoryListUI="updateCategoryListUI" :searched="searched"></TreeList>
           
        </div>
      </template>
    </div>
    
  </div>
</template>

<style scoped lang="scss" src="../category/BasicItemCategory.scss">  
</style>

<script>
import Category from "@/model/Category";
import ItemManagement from "@/model/ItemManagement";
import PageTitle from "@/components/atoms/PageTitle.vue";
import TreeList from './SearchItemManagementTreeList.vue';

export default {
  //components: {PageTitle, Pagination,TreeList},
  components: {PageTitle,TreeList},
  data: () => {
    return {
      keyword:"",
      searchedKeyword:"",
      searched:false,      
      categoryListUI: [],
      categoryListFlat: [],
      catInfo:false,
      minCatInfo:false,
      alert:{
        search:false,
        upload1:false,
        upload2:false,       
      },
      isUploading:false,
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.show()    
  },
  methods: {    
    show() {
    },
    changeList(){
     if(!this.keyword && this.searchedKeyword){
        this.keyword = this.searchedKeyword
        this.searchTree()
      }else{     
        this.searchedKeyword = this.keyword
        this.searchTree()
      }
     
    },
    search(){
      this.searchedKeyword = this.keyword
      if(this.keyword.length > 0 && this.keyword.length <= 50){
        this.changeList()
        this.alert.search = false
        this.catInfo=false;
        this.minCatInfo=false;
      }else{
        this.alert.search = true
      }
    },
    searchTree(){
       Promise.resolve(
        Category.getSearchedList(this.searchedKeyword),
      ).then((result) => {
        this.searched = true
        this.categoryListUI = result.data
        this.searchedKeyword = this.keyword
      }).catch(reason => {
        this.$errorMessage(reason)
      })  
    },
    clearSearch(){
      this.keyword=""
      this.searchedKeyword=""
      this.alert.search = false
      this.changeList()
      
    },
    updateCategoryListUI(list) {
      this.categoryListUI = list;
    },
    downloadCsv(){
      Promise.all([
        ItemManagement.downloadItemManagementCsv({keyword : String(this.searchedKeyword),responseType: 'blob'}),
      ]).then((results) => {
        const fileName = '品名辞書.csv'
        var blob = new Blob([results[0].data])
        if (window.navigator.msSaveOrOpenBlob) {
          var decodedFileName = decodeURI(fileName)
          window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
        } else {
          const link = document.createElement('a')
          var url = (window.URL || window.webkitURL).createObjectURL(blob)
          link.href = url
          link.download = decodeURI(fileName)
          link.click()
          link.remove()
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },


    uploadCsv() {
      const input = document.getElementById('upload');
      //input.click();
      input.value = '';
      input.click();
    },
    selectedFile(){
      const input = document.getElementById('upload');
      const file = input.files;
      this.alert.upload1 = false;
      this.alert.upload2 = false;
      const sizeLimit = 1024 * 1024 * 10;
      if (file[0].size > sizeLimit) {
        this.alert.upload1 = true
        return;
      }
      if (file[0].type !== 'text/csv') {
        this.alert.upload2 = true
        return; 
      }
      Promise.all([
        ItemManagement.uploadItemManagementCsv(file[0]),
      ]).then((results) => {
      
        if(results[0].data.size == 0){
          alert('アップロード完了');
        }else{
          alert('エラーチェックで除外されたデータがあります。');
          const fileName = 'エラーリスト.csv'
          var blob = new Blob([results[0].data])
          if (window.navigator.msSaveOrOpenBlob) {
            var decodedFileName = decodeURI(fileName)
            window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
          } else {
            const link = document.createElement('a')
            var url = (window.URL || window.webkitURL).createObjectURL(blob)
            link.href = url
            link.download = decodeURI(fileName)
            link.click()
            link.remove()
          }
        }
      }).catch(reason => {
        this.$errorMessage(reason);
      })
    }
    
  }
}
</script>