import Model from './_base';

export default class TransferDataManagement extends Model {
    static entity = 'transfer-data-management';

    constructor(data) {
        super(TransferDataManagement.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getMonth(){
        return Model.axios.get(this.url('/month'), { notLoading: true });
    }

    static getLastMonthTransferList(query){
        return Model.axios.post(this.url('/last-month-transfer-list'), query);
    }

    static getLastMonthIsTransferredList(query){
        return Model.axios.post(this.url('/last-month-is-transferred-list'), query);
    }

    static getLastMonthIsNotTransferList(query){
        return Model.axios.post(this.url('/last-month-is-not-transfer-list'), query);
    }

    static getCurrentMonthRequestTransferList(query) {
        return Model.axios.post(this.url('/current-month-request-transfer-list'), query, { notLoading: true });
    }

    static getSuspensionMembershipList(query){
        return Model.axios.post(this.url('/suspension-membership-list'), query);
    }

    static getDownloadTransferData(query){
        return Model.axios.post(this.url('/download-transfer-data'), query);
    }
    
    static runBatch(){
        return Model.axios.post(this.url('/create-transfer-data-management'));
    }

    static runBatchSuspensionMembership(dueDate){
        return Model.axios.post(this.url('/batch-suspension-membership?dueDate=' + dueDate));
    }

    static getCollectMembershipList(query){
        return Model.axios.post(this.url('/collect-membership-list'), query);
    }

    static saveCollectDate(item){
        return Model.axios.post(this.url('/save-collect-date'), item);
    }

    static saveCollectSuspensionMembership(item){
        return Model.axios.post(this.url('/save-collect-date-suspension-membership'), item);
    }

    static executeImportTransferResult(file, requestNo) {
        var formData = new FormData();
        formData.append("file", file);
        return Model.axios.post(this.url("/import-transfer-result?requestNo=" + requestNo), formData, { headers: { "Content-Type": "multipart/form-data" }, notLoading: true });
    }

    static getHistoryDownloadAndUpload(query) {
        return Model.axios.post(this.url('/get-history-download-and-upload-list'), query);
    }

    static download(fileCode) {
        return Model.axios.get(this.url('/download/' + fileCode));
    }

    static deleteHistoryDownloadAndUpload(transferFileId) {
        return Model.axios.post(this.url('/delete-history-download-and-upload/' + transferFileId));
    }

    static deleteAllHistoryDownloadAndUpload() {
        return Model.axios.post(this.url('/delete-all-history-download-and-upload'));
    }

    static stopReminderMail(item) {
        return Model.axios.post(this.url('/stop-send-mail-reminder'), item);
    }

    static stopReminderMailBulk(item) {
        return Model.axios.post(this.url('/stop-send-mail-reminder-bulk'), item);
    }

    static sendMailReminder(item) {
        return Model.axios.post(this.url('/send-mail-reminder'), item);
    }

}