<template>
  <div class="row" v-if="listMode==false">
    <!-- ---COLUMN 1--- -->
    <div class="tree-list col-lg-4">
      <div id="wrapper-category" class="position-relative">
        <div class="mb-3">
          <button type="button" class="btn btn-light btn-sm mr-2 toggleCollapse" @click="toggleCollapse(false)">全展開</button>
          <button type="button" class="btn btn-light btn-sm mr-2 toggleCollapse" @click="toggleCollapse(true)">全縮小</button>
          <a href="javascript:;" @click="openModal([],null,'top')">
            <font-awesome-icon icon="plus-circle" class="icon-plus" />
            &nbsp;カテゴリを追加
          </a>
        </div>
        <ul class='category-tree pl-1 mt-2'>
          <li v-for="largeCategory in categoryListUI" :key="largeCategory.id" class="large-item py-1" :class='`cat-${largeCategory.id}`'>
            <template v-if="largeCategory.children.length === 0">
              <font-awesome-icon icon="caret-right" size="lg" class="icon-right disabled-color" />
            </template>
            <template v-else-if="largeCategory.isOpen">
              <font-awesome-icon icon="caret-down" size="lg" class="icon-down" @click="toogleChildren(largeCategory)" />
            </template>
            <template v-else>
              <font-awesome-icon icon="caret-right" size="lg" class="icon-right" @click="toogleChildren(largeCategory)" />
            </template>
            
            <span @click="getCategory(largeCategory,'top')">
              <span class="deleted-category mr-1" v-if="largeCategory.isDeleted==true">
                (削除済)
              </span>
              {{ largeCategory.categoryName }}
            </span>
            <a href="javascript:;" @click="openModal(largeCategory,null,'mid')">
              <font-awesome-icon icon="plus-circle" class="icon-plus" />
            </a>
            <ul class="list-unstyled pl-5" :hidden="!largeCategory.isOpen">
              <li v-for="midCategory in largeCategory.children" :key="midCategory.id" class="medium-item py-1" :class='`cat-${largeCategory.id}-${midCategory.id}`'>
                <template v-if="midCategory.children.length === 0">
                  <font-awesome-icon icon="caret-right" size="lg" class="icon-right disabled-color" />
                </template>
                <template v-else-if="midCategory.isOpen">
                  <font-awesome-icon icon="caret-down" size="lg" class="icon-down" @click="toogleChildren(midCategory)" />
                </template>
                <template v-else>
                  <font-awesome-icon icon="caret-right" size="lg" class="icon-right" @click="toogleChildren(midCategory)" />
                </template>
                <span @click="getCategory(midCategory,'mid')">
                  <span class="deleted-category mr-1" v-if="midCategory.isDeleted==true">
                    (削除済)
                  </span>
                  {{ midCategory.categoryName }}
                </span>
                <a href="javascript:;" @click="openModal(midCategory,largeCategory.id,'low')">
                  <font-awesome-icon icon="plus-circle" class="icon-plus" />
                </a>
                <ul class="list-unstyled pl-5" :hidden="!midCategory.isOpen">
                  <li v-for="lowCategory in midCategory.children" :key="lowCategory.id" class="low-item py-1" :class='`cat-${largeCategory.id}-${midCategory.id}-${lowCategory.id}`'>
                    <font-awesome-icon icon="circle" class="icon-circle" />                            
                    <span @click="getCategory(lowCategory,'min')">
                      <span class="deleted-category mr-1" v-if="lowCategory.isDeleted==true">
                        (削除済)
                      </span>
                      {{ lowCategory.categoryName }}
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <div v-if="categoryListUI.length == 0 && searched == true">
          該当カテゴリが存在しません。
          </div>
        </ul>
      </div>
    </div>
    <!-- ---COLUMN 2--- -->
    <div class="edit-list col-lg-4" style="border-left: 1px solid rgba(0,0,0,.1);border-right: 1px solid rgba(0,0,0,.1);">
      <!-- Detail -->
      <div v-show="catInfo">
        <div class="d-flex">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">カテゴリ情報</label>
          </div>
          <div v-if="categoryInfo['isDeleted']==false">
            <a href="javascript:;" @click='openEdit(categoryInfo["id"],"info")'>編集</a>                      
          </div>
          <div v-if="categoryInfo['isDeleted']==true">
            <a href="javascript:;" @click='deleteCategoryHard(categoryInfo["id"])'>
              <font-awesome-icon icon="times" />
              &nbsp;カテゴリを削除
            </a>                      
          </div>
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2 basicInfo">
          <p class="text-break m-0">カテゴリコード：{{categoryInfo["categoryCode"]}}</p>
          <p class="text-break m-0">カテゴリ名：{{categoryInfo["categoryName"]}}</p>
        </div>
      </div>
      <!-- /Detail -->

      <!-- Dictionary -->
      <div v-show="minCatInfo">
        <div class="d-flex mt-3">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">検索用品名・サービス</label>
          </div>
          <!--div>
            <a href="javascript:;">編集</a>
          </div-->
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2">
          <p class="text-break m-0">**管理テーブルを表示**</p>
        </div>
      </div>
      <!-- /Dictionary -->

      <!-- UNSPSC -->
      <div v-show="minCatInfo">
        <div class="d-flex mt-3">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">UNSPSC</label>
          </div>
          <div v-if="categoryInfo['isDeleted']==false">
            <a href="javascript:;" @click='openEdit(categoryInfo["id"],"unspsc")'>編集</a>                      
          </div>
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2 text-break">
          <p class="text-break m-0">[{{categoryInfo["unspsCode"]}}] {{categoryInfo["unspsName"]}}</p>
        </div>
      </div>
      <!-- /UNSPSC -->

      <!-- Biz mapping -->
      <div v-show="minCatInfo">
        <div class="d-flex mt-3">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">共通約款</label>
          </div>
          <div v-if="categoryInfo['isDeleted']==false">
            <a href="javascript:;" @click='openEdit(categoryInfo["id"],"terms")'>編集</a>                      
          </div>
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2" v-if="arrayTerm.length > 0">
          <div class="form-group m-0" v-for="term in terms" :key="term.id">
            <label class="m-0" :for="term.id" v-if="arrayTerm.indexOf(String(term.id)) >= 0">{{term.name}}</label>
          </div>
        </div>
        <div class="p-2" v-else>
          紐づけられた共通約款はありません。
        </div>
      </div>
      <!-- /Biz mapping -->

      <!-- Contracts -->
      <div v-show="minCatInfo">
        <div class="d-flex mt-3">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">該当許認可</label>
          </div>
          <div v-if="categoryInfo['isDeleted']==false">
            <a href="javascript:;" @click='openEdit(categoryInfo["id"],"licenses")'>編集</a>                      
          </div>
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2" v-if="arrayLicense.length > 0">
          <div class="form-group m-0" v-for="license in licenses" :key="license.id">
            <label class="m-0" :for="license.id" v-if="arrayLicense.indexOf(String(license.id)) >= 0">{{license.label}}</label>
          </div>
        </div>
        <div class="p-2" v-else>
          該当許認可はありません。
        </div>
      </div>
      <!-- /Contracts -->

      <!-- Subcontract -->
      <div v-show="minCatInfo">
        <div class="d-flex mt-3">
          <div class="flex-grow-1">
            <label class="h5 font-weight-bold">利用者評価利用有無</label>
          </div>
          <div v-if="categoryInfo['isDeleted']==false">
            <a href="javascript:;" @click='openEdit(categoryInfo["id"],"evaluate")'>編集</a>                  
          </div>
        </div>
        <hr class="mt-0 mb-1" />
        <div class="p-2">
          <p v-if='categoryInfo["isNeedEvaluation"] == true'>有り</p>
          <p v-else>無し</p>
        </div>
      </div>
      <!-- /Subcontract -->
    </div>

    <!-- ---COLUMN 3--- -->
    <div class="col-lg-4" id="colEditTab">
      <!-- Edit detail -->
      <div id="categoryEdit" v-show="showCategoryEdit">
        <label class="h5 font-weight-bold mb-4">カテゴリ情報編集</label>
        <div class="form-group">
          <label for="categoryCode">カテゴリコード</label>
          <b-form-input type="text" class="form-control" id="categoryCode" name="categoryCode"  v-model='form.categoryCode'/>
        </div>
        <div class="form-group">
          <label for="categoryName">カテゴリ名</label>
          <b-form-input type="text" class="form-control" id="categoryName" name="categoryName" v-model='form.categoryName'/>
        </div>
        <div class="bg-handle p-3 text-center">
          <b-button class="btn btn-primary btn-fixed-width" v-on:click='onSave("info")'>
            <font-awesome-icon icon="save" />
            &nbsp;保存
          </b-button>
          <div class="mt-2">
            <a href="javascript:;" @click="closeAllEdit">キャンセル</a>
          </div>
        </div>
        <div class="mt-3 text-right">
          <a href="javascript:;" @click='deleteCategory(categoryInfo["id"],categoryInfo["path"])'>
            <font-awesome-icon icon="times" />
            &nbsp;カテゴリを削除
          </a>
        </div>
      </div>

      <div id="unspscEdit" v-show="showUnspscEdit">
        <label class="h5 font-weight-bold mb-4">UNSPSC編集</label>
        <div class="form-group">
          <label for="unspsCode">UNSPSCコード</label>
          <b-form-input type="text" class="form-control" id="unspsCode" name="unspsCode"  v-model='form.unspsCode'/>
        </div>
        <div class="form-group">
          <label for="unspsName">UNSPSC名</label>
          <b-form-input type="text" class="form-control" id="unspsName" name="unspsName" v-model='form.unspsName'/>
        </div>
        <div class="bg-handle p-3 text-center">
          <button type="button" class="btn btn-primary btn-fixed-width"  v-on:click='onSave("unspsc")'>
            <font-awesome-icon icon="save" />
            &nbsp;保存
          </button>
          <div class="mt-2">
            <a href="javascript:;" @click="closeAllEdit">キャンセル</a>
          </div>
        </div>
      </div>

      <div id="termsEdit" v-show="showTermsEdit">
        <label class="h5 font-weight-bold mb-4">共通約款紐づけ編集</label>
        <div class="form-group mb-1" v-for="term in terms" :key="term.id">
            <input v-if="arrayTerm.indexOf(String(term.id)) >= 0" type="checkbox" :id="term.id" :value="term.id" v-model="checkedTerm">
            <input v-else type="checkbox" :id="term.id" :value="term.id" v-model="checkedTerm">
          <label class="pl-1 mb-0" :for="term.id">{{term.name}}</label>
        </div>

        <div class="bg-handle p-3 text-center">
          <button type="button" class="btn btn-primary btn-fixed-width" v-on:click='onSaveChecks("terms")'>
            <font-awesome-icon icon="save" />
            &nbsp;保存
          </button>
          <div class="mt-2">
            <a href="javascript:;" @click="closeAllEdit">キャンセル</a>
          </div>
        </div>
      </div>

      <div id="licensesEdit" v-show="showLicensesEdit">
        <label class="h5 font-weight-bold mb-4">該当許認可編集</label>
        <div class="form-group mb-1" v-for="license in licenses" :key="license.id">
            <input v-if="arrayLicense.indexOf(String(license.id)) >= 0" type="checkbox" :id="license.id" :value="license.id" v-model="checkedLicense">
            <input v-else type="checkbox" :id="license.id" :value="license.id" v-model="checkedLicense">
          <label class="pl-1 mb-0" :for="license.id">{{license.label}}</label>
        </div>

        <div class="bg-handle p-3 text-center">
          <b-button class="btn btn-primary btn-fixed-width" v-on:click='onSaveChecks("licenses")'>
            <font-awesome-icon icon="save" />
            &nbsp;保存
          </b-button>
          <div class="mt-2">
            <a href="javascript:;" @click="closeAllEdit">キャンセル</a>
          </div>
        </div>
      </div>

      <div id="evaluateEdit" v-show="showEvaluateEdit">
        <label class="h5 font-weight-bold mb-4">利用者評価利用有無編集</label>
        <div class="form-group mb-0">
          <label><input type="radio" name="isNeedEvaluation" v-model="form.isNeedEvaluation" value="1">利用有り</label>
        </div>
        <div class="form-group">
          <label><input type="radio" name="isNeedEvaluation" v-model="form.isNeedEvaluation" value="0">利用無し</label>
        </div>
        <div class="bg-handle p-3 text-center">
          <button type="button" class="btn btn-primary btn-fixed-width" v-on:click='onSaveIsNeedEvaluate'>
            <font-awesome-icon icon="save" />
            &nbsp;保存
          </button>
          <div class="mt-2">
            <a href="javascript:;" @click="closeAllEdit">キャンセル</a>
          </div>
        </div>
      </div>

      <!-- /Edit detail -->
    </div>
    <CreateCategoryModal :val="parentCategory" @close="closeModal" @after="afterCreate" v-if="modal">
      <!-- default スロットコンテンツ -->
      
      <template slot="title">
            <h4 class="modal-title">カテゴリ追加</h4>
      </template>
      <template slot="confirm">
            <button type="button" class="btn btn-primary" @click.self="test">登録</button>
      </template>
      <!-- /footer -->
    </CreateCategoryModal>
  </div>
</template>

<style scoped lang="scss" src="./BasicItemCategory.scss">  
</style>

<script>
import Category from "@/model/Category";
import CreateCategoryModal from './CreateCategoryModal.vue'

export default {
  //components: { Pagination, LoadingList,PageTitle },
  components: {CreateCategoryModal },
  data: () => {
    return {
      form: {},
      modal: false,
      ps: null,
      categoryInfo: {},
      categoryUnspsc: {},
      catInfo:false,
      minCatInfo:false,
      showCategoryEdit:false,
      showUnspscEdit:false,
      showTermsEdit:false,
      showLicensesEdit:false,
      showEvaluateEdit:false,      
      licenses: [],
      checkedLicense:[],
      arrayLicense:[],
      terms: [],
      checkedTerm:[],
      arrayTerm:[],
      parentCategory:{},      
      elms:{
        info:{keyword:"カテゴリ情報",code:"categoryCode",name:"categoryName"},
        unspsc:{keyword:"UNSPSC情報",code:"unspsCode",name:"unspsName"},
        terms:{keyword:"共通約款",checked:"checkedTerm",array:"arrayTerm"},
        licenses:{keyword:"該当許認可",checked:"checkedLicense",array:"arrayLicense"},
      },
    }
  },
  props: ["categoryListUI","listMode","searched"],
  watch: {},
  mounted() {
    this.show()    
  },
  methods: {    
    show() {
      this.getCategoryList();
      this.getLicenses();
      this.getTerms();
    },
    scrollCatTop: function(){
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      });
    },
    getCategoryList() {
      Promise.all([
        Category.getList(),
      ]).then((results) => {
        if (!results[0].data.length) this.$emit("parentCategoryListUI",{})
        this.$emit("parentCategoryListUI",results[0].data)
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },    
    getCategory(category,type){
      this.catInfo = false;
      this.minCatInfo = false;
      Promise.resolve(
        Category.getCategory(category.id),
      ).then((result) => {
        this.closeAllEdit()
        if (!result.data.length) this.categoryInfo = {}
        this.categoryInfo = result.data;
        this.catInfo = true;
        if(type=='min'){
          this.minCatInfo = true;          
          this.arrayLicense = [];
          if(this.categoryInfo["license"] != null && this.categoryInfo["license"].length > 0){
            this.arrayLicense = this.categoryInfo["license"].split('/')
          }
          this.arrayTerm = [];
          if(this.categoryInfo["terms"] != null && this.categoryInfo["terms"].length > 0){
            this.arrayTerm = this.categoryInfo["terms"].split('/')
          }
        }else{
          this.minCatInfo = false;
        }
        if(category.children != null && category.isOpen != true){
          this.toogleChildren(category)
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    openEdit(id,editType){
      this.closeAllEdit()
      if(editType == "info"){
        this.form.categoryCode= this.categoryInfo["categoryCode"];
        this.form.categoryName= this.categoryInfo["categoryName"];
        this.showCategoryEdit = true;
      } 
      if(editType == "unspsc"){
        this.form.unspsCode= this.categoryInfo["unspsCode"];
        this.form.unspsName= this.categoryInfo["unspsName"];
        this.showUnspscEdit = true;
      } 
      if(editType == "terms"){
        if(this.categoryInfo["terms"] != null && this.categoryInfo["terms"].length > 0){
          this.checkedTerm = this.arrayTerm
        }
        this.showTermsEdit = true;
      }
      if(editType == "licenses"){
        if(this.categoryInfo["license"] != null && this.categoryInfo["license"].length > 0){
          this.checkedLicense = this.arrayLicense
        }
        this.showLicensesEdit = true;
      }
      if(editType == "evaluate"){   
        this.form.isNeedEvaluation= 0;
        if(this.categoryInfo["isNeedEvaluation"]){
          this.form.isNeedEvaluation= 1;
        }
        this.showEvaluateEdit = true;
      }
      this.scrollCatTop()
    },
    closeAllEdit(){
      this.form = {};
      this.showCategoryEdit = false;
      this.showUnspscEdit = false;
      this.showTermsEdit = false;
      this.showLicensesEdit = false;
      this.showEvaluateEdit = false;
    },
    async updateCategoryInfo(){
      await Category.updateCategoryInfo(this.form.id,this.form);
    },
    async updateUnspsc(){
      await Category.updateUnspsc(this.form.id,this.form);
    },
    async updateTerms(term){
      await Category.updateTerms(this.form.id,term);
    },
    async updateLicenses(license){
      await Category.updateLicenses(this.form.id,license);
    },
    async updateIsNeedEvaluation(isNeedEvaluationFlag){
      await Category.updateIsNeedEvaluation(this.form.id,isNeedEvaluationFlag);
    },
    onSave(saveType) {
      this.form.id = this.categoryInfo["id"];
      let funcs = {info:this.updateCategoryInfo,unspsc:this.updateUnspsc}
      this.$swal.confirm(this.elms[saveType]["keyword"] + "を更新します。よろしいですか？", undefined, () => {        
        Promise.all([
          funcs[saveType](),
        ]).then(()=>{
          this.$swal.success(this.elms[saveType]["keyword"] + "の更新が完了しました", undefined, () => {});
          this.categoryInfo[this.elms[saveType]["code"]] = this.form[this.elms[saveType]["code"]];
          this.categoryInfo[this.elms[saveType]["name"]] = this.form[this.elms[saveType]["name"]];
          this.closeAllEdit();
        }).catch(reason => {
          this.$errorMessage(reason);
        })
      })
    },    
    onSaveChecks(saveType) {
      this.form.id = this.categoryInfo["id"];
      let funcs = {terms:this.updateTerms,licenses:this.updateLicenses}
      var items = "";
      if(this[this.elms[saveType]["checked"]].length > 0){        
        items = this[this.elms[saveType]["checked"]].sort().join('/');
      }
      
      this.$swal.confirm(this.elms[saveType]["keyword"] + "共通約款を更新します。よろしいですか？", undefined, () => {        
        Promise.all([
          funcs[saveType](items),
        ]).then(()=>{
          this.$swal.success(this.elms[saveType]["keyword"] + "共通約款の更新が完了しました", undefined, () => {});          
          if(this[this.elms[saveType]["array"]].length > 0){       
            this[this.elms[saveType]["array"]] = items.split('/');
          }
          this.closeAllEdit();
        }).catch(reason => {
          this.$errorMessage(reason);
        })
      })
    },
    onSaveIsNeedEvaluate() {
      this.form.id = this.categoryInfo["id"];  
      var isNeedEvaluationFlag = false;
      if(this.form.isNeedEvaluation > 0){
        isNeedEvaluationFlag = true;
      } 
      this.$swal.confirm("該当許認可を更新します。よろしいですか？", undefined, () => {        
        Promise.all([
          this.updateIsNeedEvaluation(isNeedEvaluationFlag),
        ]).then(()=>{
          this.$swal.success("該当許認可の更新が完了しました", undefined, () => {});
          this.categoryInfo["isNeedEvaluation"] = this.form.isNeedEvaluation;
          this.closeAllEdit();
        }).catch(reason => {
          this.$errorMessage(reason);
        })
      })
    },
    addCategoryUI(newCategory){
      var spliceTarget = null
      var spliceTargetIndex = null

      if(!newCategory.parentCategoryId){
        spliceTarget = this.categoryListUI
        spliceTargetIndex = 0
      }else{
        var path_array = newCategory.path.split("/")
        var topCategory = []
        
        for(var i = 0; i < this.categoryListUI.length; i++){
          if(this.categoryListUI[i]['id'] == [path_array[0]]){
            topCategory = this.categoryListUI[i]
            break
          }
        }        
        if(path_array.length > 1){
          var midCategory = this.getItemFromChildren(topCategory["children"],path_array[1])
        }
        if(path_array.length == 2){
          spliceTarget = this.categoryListUI[i]["children"]
          spliceTargetIndex = spliceTarget.length
        }        
        if(path_array.length == 3){
          spliceTarget = this.categoryListUI[i]["children"][midCategory['index']]["children"]
          spliceTargetIndex = spliceTarget.length
        }        
      }

      var defaultChildren = Array(0);
      spliceTarget.splice(spliceTargetIndex,0,{categoryName:newCategory.categoryName,children:defaultChildren,id:newCategory.id})
      this.closeAllEdit();
    },
    deleteCategory(id,path) {
      this.$swal.confirm("対象カテゴリ及び関係しているカテゴリを削除します。よろしいですか？", undefined, () => {
        var path_array = path.split("/")
        var topCategory = []
        var deleteCategory = null
        var spliceTarget = null
        var spliceTargetIndex = null
        for(var i = 0; i < this.categoryListUI.length; i++){
          if(this.categoryListUI[i]['id'] == [path_array[0]]){
            topCategory = this.categoryListUI[i]
            break
          }
        }
        if(path_array.length > 1){
          var midCategory = this.getItemFromChildren(topCategory["children"],path_array[1])

          if(path_array.length > 2){
            var lowCategory = this.getItemFromChildren(midCategory["data"]["children"],path_array[2])
            deleteCategory = lowCategory['data']
            spliceTarget = this.categoryListUI[i]["children"][midCategory['index']]["children"]
            spliceTargetIndex = lowCategory['index']            
          }else{
            deleteCategory = midCategory['data']
            spliceTarget = this.categoryListUI[i]["children"]
            spliceTargetIndex = midCategory['index']
          }
        }else{
          deleteCategory = topCategory
          spliceTarget = this.categoryListUI
          spliceTargetIndex = i
        }
        
        Promise.all([
          Category.deleteCategory(deleteCategory["id"]),
        ]).then(() => {
          this.$swal.success("カテゴリの削除が完了しました", undefined, () => {});          
          spliceTarget.splice(spliceTargetIndex,1)
          this.closeAllEdit();
          this.catInfo=false;
          this.minCatInfo=false;
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    },
    deleteCategoryHard(id) {      
      this.$swal.confirm("対象カテゴリ及び関係しているカテゴリを削除します。<br>完全に削除され、復元はできません。<br>よろしいですか？", undefined, () => {
        Promise.all([
          Category.deleteCategoryHard(id),
        ]).then(() => {
          this.$swal.success("カテゴリの削除が完了しました", undefined, () => {});
          this.closeAllEdit();
          this.catInfo=false;
          this.minCatInfo=false;
          this.changeList();
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      })
    },
    getItemFromChildren(children,id){
      for(var i = 0; i < children.length; i++){
        if(children[i]['id'] == id){
          var targetCategory = children[i]
          break
        }
      }
      return {data:targetCategory,index:i}
    },
    getTerms() {
      Promise.all([
        Category.getTerms(),
      ]).then((results) => {
        if (!results[0].data.length) this.terms = {}
        this.terms = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    getLicenses() {
      Promise.all([
        Category.getLicenses(),
      ]).then((results) => {
        if (!results[0].data.length) this.licenses = {}
        this.licenses = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    toogleChildren(category) {
      if (category.children && category.children.length > 0) {
        this.$set(category, "isOpen", !category.isOpen) 
      }
    },
    toggleCollapse(isOpen) {
      for (let large of this.categoryListUI) {
        if (large.children) {
          for (let medium of large.children) {            
            this.$set(medium, "isOpen", !isOpen) 
          }
        }
        this.$set(large, "isOpen", !isOpen) 
      }
    },
    openModal(category,parentCategoryId,type) {
      this.modal = true
      this.parentCategory = category
      this.parentCategory.type = type
      if(type == "low"){
        this.parentCategory.parentId = parentCategoryId    
      }
    },
    closeModal() {
      this.modal = false
    },
    afterCreate(newCategory){
      this.addCategoryUI(newCategory)
      this.closeModal()
    },   
  }
}
</script>