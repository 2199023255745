<template>
  <div class="next">
    <div class="next__header">
      <div class="next__header_search">
        <div class="next__header_search_item">
          <input type="text" class="form-control w-50" placeholder="サプライヤコード、サプライヤ名" v-model="keyWord" maxlength="100" />
          <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
            <font-awesome-icon icon="search" />
          </button>
        </div>
        <div v-if="!isUploadRequest">
          <div class="next__summary">請求合計金額：{{ totalAmountCurrRequestTransferFee | numberFormat }}円</div>
        </div>
      </div>
      <div class="next__header_actions">
        <b-button variant="primary" size="md" class="next__header_actions_item  w-50" 
          :disabled="searchItems.length === 0 || isUploadRequest" v-on:click="onRequestDownload">請求データダウンロード</b-button>
        <b-button variant="primary" size="md" class="next__header_actions_item  w-50" :disabled="searchItems.length === 0 || isUploadRequest" v-on:click="onSelectUploadFile">
          振替結果アップロード
        </b-button>
        <b-form-file id="target" v-model="fileUpload" style="display: none" @input="onTransferUpload(fileUpload)"></b-form-file>
      </div>
    </div>
    <template v-if="!isUploadRequest">
      <div class="next__action text-left">
        <b-button variant="primary" size="md" class="next__action-button" v-on:click="onRequestUpdate">請求データを更新</b-button>
      </div>
      <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center" style="width:30%">サプライヤ</th>
            <th class="text-center" style="width:25%">対象期間</th>
            <th class="text-center" style="width:25%">請求金額</th>
            <th class="text-center" style="width:20%">振替日</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in searchItems" :key="`${item[dataType.detailKey]}${index}`">
            <td>{{item.supplierDisplayCode}}<br />{{item.supplierName}}</td>
            <td class="text-center">
              {{item.targetPeriodYearFrom}}/{{item.targetPeriodMonthFrom}} ～ {{item.targetPeriodYearTo}}/{{item.targetPeriodMonthTo}}
              <template v-if="item.isRequestWithdrawal"><br /><span class="next__items-withdrawal">退会申請あり</span></template>
              <template v-else></template>
            </td>
            <td class="text-right">{{item.requestFee | numberFormat}}円</td>
            <td>{{item.requestDate | toDay}}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div v-if="searchItems.length === 0" class="text-left"> -->
          <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
      <!-- </div> -->
      <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    </template>
    <template v-else>
      <div>{{ currentMonth }}月の請求データは{{ currentMonth }}月初日に生成されます。</div>
    </template>
  </div>
</template>

<script>
import TransferDataManagement from '@/model/TransferDataManagement'  

import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"

export default {
  components: { Pagination, LoadingList },
  props: [ 'updateMonth' ],
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: true,
      dataType: {},
      searchItems: [],
      totalAmountCurrRequestTransferFee: 0,
      keyWord: "",
      isUploadRequest: false,
      currentMonth: null,
      currentYear: null,
      fileUpload: null
    }
  },
  mounted() {
    this.getSearchItems(true)
  },
  methods: {
   async getSearchItems(loadAll = false) {
      if(!this.currentMonth){
        await this.defaultMonth();
      }
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }
      TransferDataManagement.getCurrentMonthRequestTransferList({pagination: this.pagination, queries: {
        keyWord: this.keyWord, 
        currentMonth: this.currentMonth, 
        currentYear: this.currentYear }})
      .then(response => {
        this.searchItems = response.data.items.dataList;
        this.totalAmountCurrRequestTransferFee = response.data.items.totalAmountCurrRequestTransferFee;
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
    },
    onRequestUpdate(){
      TransferDataManagement.runBatch().then(()=>{
        this.$swal.success("Success!");
        this.getSearchItems();
      }).catch(()=>{});
    },
    onRequestDownload() {
      // this.$swal.confirm("請求データをダウンロードしてもよろしいでしょうか？", undefined, () => {
      //   TransferDataManagement.getDownloadTransferData(this.searchItems)
      //   .then(() => {
      //     this.$swal.success("請求データをダウンロードしました", undefined, () => {

      //     })
      //   })
      //   .catch(() => {
      //     // do nothing
      //   });
      // })
      Promise.all([
          TransferDataManagement.getDownloadTransferData({
          queries: {keyWord: this.keyWord, currentMonth: this.currentMonth, currentYear: this.currentYear}
        })
      ]).then((results) => {
        let month = this.$moment(new Date()).format("YYYYMM");
        const fileName = 'jienierequest' + month + '.dat'
        var blob = new Blob([results[0].data])
        if (window.navigator.msSaveOrOpenBlob) {
          var decodedFileName = decodeURI(fileName)
          window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
        } else {
          const link = document.createElement('a')
          var url = (window.URL || window.webkitURL).createObjectURL(blob)
          link.href = url
          link.download = decodeURI(fileName)
          link.click()
          link.remove()
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    onSelectUploadFile() {
      document.querySelector("#target").click();
    },
    onTransferUpload(fileUpload) {
      this.$swal.confirm("振替結果をアップロードしてもよろしいでしょうか？", undefined, () => {
        let requestNo = this.searchItems[0].requestNo;
        Promise.all([
          TransferDataManagement.executeImportTransferResult(fileUpload, requestNo)
        ]).then(() => {
          this.$swal.success("振替結果をアップロードしました", undefined, () => {
            this.fileUpload = null;
            this.defaultMonth();
            this.getSearchItems(true);
          })
        }).catch(reason => {
          this.fileUpload = null;
          this.$errorMessage(reason);
        })
      })
      this.fileUpload = null;
    },
   async defaultMonth(){
     const response = await TransferDataManagement.getMonth();
     if (response) {
        this.currentMonth = response.data.body.currentMonth;
        this.currentYear = response.data.body.currentYear;
        this.isUploadRequest = response.data.body.isUploadRequest;
        this.$emit('updateMonth', response.data.body);
      }
    },
    runBatchSuspensionMembership(){
      TransferDataManagement.runBatchSuspensionMembership(0).then(()=>{
        this.$swal.success("Success!");
        this.getSearchItems();
      }).catch(()=>{});
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.next {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.next__header {
  display: flex;
  margin: 10px;
  :last-child {
    margin-left: auto;
  }
}
.next__header_search {
  display: inline-flexbox;
  width: 100%;
  margin-right: auto;
}
.next__header_search_item {
  display: flex;
}

.next__header_actions {
  display: flex;
  flex-direction: column;
}
.next__header_actions_item {
  margin: 5px;
  min-width: 220px;
  margin-left: auto;
}
.next__summary {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.next__action {
  display: inline-flex;
}
.next__action-button {
  margin: 5px;
}
.next__items-withdrawal {
  color: $red;
  font-size: 12px;
}

.reminder__checkbox {
  margin: 5px;
}

</style>