<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="退会管理"/>
    <PageTitle title="退会管理"></PageTitle>
    <div class="bg-light-blue mx-4 mt-2 p-3">
      <router-link :to="{ name : 'SupplierDetailWithdrawEdit', params: { supplierCode: supplierCode} }">
        <b-button class="withdraw_start" variant="danger" size="md">
          退会手続きを開始
        </b-button>
      </router-link>
      <b-alert show class="my-4">
        <p>このサプライヤの退会できる最短の日付は<b>{{ shortestWithdrawalDate ? $moment(shortestWithdrawalDate).format('yyyy年MM月DD日') : '' }}</b>です。</p>
        <p>退会日は以下の条件を満たす必要があります。</p>
        <p>・退会申込の到着日から3ヵ月以降の月末日</p>
        <p>・openから既存に変わったことがある場合は、初回の変更日から12ヵ月以降の日付</p>
      </b-alert>
    </div>
  </div>
</template>

<script>
import SupplierWithdrawal from "@/model/SupplierWithdrawal";
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  data() {
    return {
      shortestWithdrawalDate: '',
      supplierCode: this.$route.params.supplierCode
    }
  },
  props: {},
  components: { PageTitle, CompanySimpleHeader },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      SupplierWithdrawal.loadSupplierWithdrawal(this.supplierCode)
      .then(Response => {
        this.shortestWithdrawalDate = Response.data.shortestWithdrawalDate;
      })
      .catch(reason => {
        this.$errorMessage(reason);
      });
    }
  }
}
</script>