<template>
  <div>
    <div class="rpa-row">
      <div class="rpa-header" style="display:flex; align-items:center;">
        <p class="font-weight-bold m-0" v-if="result.isPatent">特許</p>
        <p class="font-weight-bold m-0" v-else>{{result.license.name}}</p>
      </div>
      <div style="flex:1;">
        <template v-if="result.filePaths && result.filePaths.length > 0">
          <table class="bg-white w-100 rpa-item-table">
            <tr>
              <th>エビデンスファイル</th>
              <td>
                <a   
                  class="text-primary"
                  @click="onViewEvidence(result.filePaths)">
                  <font-awesome-icon icon="file-download"></font-awesome-icon> ダウンロード
                </a>
              </td>
              <td>-</td>
            </tr>
          </table>
          
        </template>
        <PatentItem v-else-if="result.isPatent" :result="result" :company="company"/>
        <ConstructionItem v-else-if="result.license.code === 'CONSTRUCTION'" :result="result" :company="company"/>
        <ResidentialItem v-else-if="result.license.code === 'RESIDENTIAL'" :result="result" :company="company"/>
        <TravelItem v-else-if="result.license.code === 'TRAVEL_AGENCY'" :result="result" :company="company"/>
        <IndustrialWasteItem v-else-if="result.license.code === 'INDUSTRIAL_WASTE'" :result="result" :company="company"/>
        <WorkerItem v-else-if="result.license.code === 'WORKER'" :result="result" :company="company"/>
        <ISOItem v-else-if="result.license.code === 'ISO9001'" :result="result" :company="company"/>
        <ISOItem v-else-if="result.license.code === 'ISO14001'" :result="result" :company="company"/>
        <ISMSItem v-else-if="result.license.code === 'ISMS'" :result="result" :company="company"/>
        <PrivacyMarkItem v-else-if="result.license.code === 'PRIVACY_MARK'" :result="result" :company="company"/>
      </div>
      <div style="background: #FFF; font-weight:bold; width: 64px; border-right: 2px solid #000;border-bottom: 1px solid #000; display: flex; align-items:center; justify-content:center; ">
        <b-checkbox/>
      </div>
    </div>
    <b-modal ref="rpa-change-status-modal" title="判断を変更" @ok="onChangeStatusOk">
      <b-alert show>この許認可・認証の判断を「OK」に変更しますか？</b-alert>
      <b-form-group label="審査ステータス">
        <b-select v-model="changeStatus.isCorrect">
          <b-select-option :value="true">OK</b-select-option>
          <b-select-option :value="false">NG</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group label="判断を変更する理由">
        <b-textarea v-model="changeStatus.message"></b-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import SupplierVerification from '@/model/check/SupplierVerification.js';
import ConstructionItem from './ConstructionItem.vue';
import TravelItem from './TravelItem.vue';
import IndustrialWasteItem from './IndustrialWasteItem.vue';
import ResidentialItem from './ResidentialItem.vue';
import WorkerItem from './WorkerItem.vue';
import ISOItem from './ISOItem.vue';
import ISMSItem from './ISMSItem.vue';
import PrivacyMarkItem from './PrivacyMarkItem.vue';
import PatentItem from './PatentItem.vue';
export default {
  props: ["result", "company"],
  components: { ConstructionItem, ResidentialItem, TravelItem, IndustrialWasteItem, WorkerItem, ISOItem, ISMSItem, PrivacyMarkItem, PatentItem },
  computed: {
    verifiedResult(){
      return JSON.parse(this.result.verifiedResult);
    },
    apiUrl(){
      return process.env.VUE_APP_API_WORKER_URL;
    },
  },
  data: function(){
    return {
      changeStatus: {
        isCorrect: true,
        message: ""
      }
    }
  },
  created(){

  },
  methods: {
    onViewEvidence(filePaths){
      SupplierVerification.downloadEvidence(filePaths.join(',')).then(result => {
        const blob = new Blob([result.data], {type: result.headers['content-type']});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = result.headers['content-disposition'].split("filename=")[1];
        link.click();
        URL.revokeObjectURL(link.href);
        this.getFileStatus="success";
      }).catch(reason => this.$errorMessage(reason));
    },
    onChangeStatus(){
      this.$refs["rpa-change-status-modal"].show();
    },
    onChangeStatusOk(){
      SupplierVerification.change(this.company.companyCode, {
        id: this.result.id,
        verifiedResult: JSON.stringify({
          reliability: 1,
          message: this.changeStatus.message
        }),
        isCorrect: this.changeStatus.isCorrect
      }).then(() => {
        this.$successMessage("判断を更新しました。");
        this.$emit("refresh");
      }).catch(reason => this.$errorMessage(reason));
    
    },
    onRequest(){

    },
    onRerun(){

    },
  }
}
</script>

<style>
  .rpa-row {
    display:flex;
    border-top: 2px solid #000;
  }
  .rpa-row .rpa-header{
    width: 20%;
    border: 1px solid #000;
    border-right: none;
    border-top:none;
    background: #fdf4ee;
    padding: .5rem 1rem;
  }
  .rpa-item-table{
    height: 100%;
  }
  .rpa-item-table tr th{
    border: 1px solid #000;
    border-top:none;
    background: #fdf4ee;
    padding: .5rem 1rem;

  }
  .rpa-item-table tr td{
    border: 1px solid #000;
    border-top:none;
    padding: .5rem 1rem;

  }
  .rpa-item-table tr th:nth-child(1){
    width: 20%;
  }
  .rpa-item-table tr td:nth-child(2){
    width: 40.0%;
  }
  .rpa-item-table tr td:nth-child(3){
    width: 40.0%;
  }
  

  .rpa-item-table tr th:nth-child(2){
    width: 40.0%;
  }
  .rpa-item-table tr th:nth-child(3){
    width: 40.0%;
  }
</style>