<template>
  <div class="stop">
    <div class="stop__search">
      <input type="text" class="form-control w-50" placeholder="サプライヤコード、サプライヤ名" v-model="keyWord" maxlength="100" />
      <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
        <font-awesome-icon icon="search" />
      </button>
      <b-button variant="primary" size="md" :disabled="searchItems.length === 0" v-on:click="onRequestDownload">請求データダウンロード</b-button>
    </div>
    <div class="stop__summary">
      <div>請求合計金額：{{ totalSuspensionMembershipFee | numberFormat }}円</div>
    </div>
    <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:5%"><input class="stop__checkbox reminder__checkbox--all checkbox-large" type="checkbox" v-model="checkedAll" /></th>
          <th class="text-center" style="width:20%">サプライヤ</th>
          <th class="text-center" style="width:10%">対象期間</th>
          <th class="text-center" style="width:10%">請求金額</th>
          <th class="text-center" style="width:10%">資格停止日</th>
          <th class="text-center" style="width:10%">未収状況</th>
          <th class="text-center" style="width:16%">回収日</th>
          <th class="text-center" style="width:12%"></th>
          <th class="text-center" style="width:7%">振替結果</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in searchItems" :key="`${item[dataType.detailKey]}${index}`">
          <td class="text-center">
            <input 
              class="stop__checkbox reminder__checkbox--item checkbox-large" 
              type="checkbox" 
              v-model="item.checkedStopRemind"
              @change="onCheckReminderStop" />
          </td>
          <td>{{item.supplierDisplayCode}}<br />{{item.supplierName}}</td>
          <td class="text-center">{{item.targetPeriodYearFrom}}/{{item.targetPeriodMonthFrom}} ～ {{item.targetPeriodYearTo}}/{{item.targetPeriodMonthTo}}</td>
          <td class="text-right">{{item.requestFee | numberFormat}}円</td>
          <td>{{item.suspensionMembershipDate | toDay}}</td>
          <td>{{item.uncollectedLevel}}</td>
          <td>
            <div class="stop__collect_date_input">
              <datepicker 
                v-model="item.collectDate" 
                required typeable 
                wrapper-class="list-inline-item"
                @selected="hasError = false; $forceUpdate();"
                @input="hasError = false; $forceUpdate();"
                :input-class="{'form-control stop__collect_date_input_text': true, 'has-error': hasError}">
              </datepicker>
              <b-button variant="primary" class="stop__collect_date_input_action" v-on:click="onSaveCollect(item)">確定</b-button>
            </div>
          </td>
          <td>
            <template v-if="item.isSuspensionReminder">
              <span class="stop__collected">督促停止</span>
            </template>
            <template v-else>
              <b-button variant="primary" class="stop__collect_date_input_action" v-on:click="onStopRemind(item)">督促</b-button>
            </template>
            <b-button variant="primary" class="stop__collect_date_input_action mt-2" v-on:click="sendMail(item)">督促メール送信</b-button>
          </td>
          <td>{{ item.transferStatusName || '未収' }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="searchItems.length === 0" class="text-left">
        <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
    </div>
    <div v-else>
      <b-button variant="primary" v-on:click="onStopRemindBundle">一括督促</b-button>
    </div>
    <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
  </div>
</template>

<script>
import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import TransferDataManagement from '@/model/TransferDataManagement'  

export default {
  components: { Pagination, LoadingList },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: false,
      dataType: {},
      searchItems: [],
      keyWord: "",
      totalSuspensionMembershipFee: 0,
      hasError: false,
      checkedAll: false
    }
  },
  props: {},
  watch: {
    checkedAll() {
      for (let item of this.searchItems) {
        item.checkedStopRemind = this.checkedAll;
      }
    }
  },
  mounted() {
    this.getSearchItems(true)
  },
  methods: {
    getSearchItems(loadAll = false) {
      this.searchItems = [];
      // mock
      this.loadedList = false;
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }
      TransferDataManagement.getSuspensionMembershipList({pagination: this.pagination, queries: {keyWord: this.keyWord}})
      .then(response => {
        for(let item of response.data.items.dataList){
          let star = "";
          if(item.suspensionReceivableStatus && item.suspensionReceivableStatus > 0){
            for(let i = 0; i < item.suspensionReceivableStatus; i++){
              star += "★";
            }
          }
          item.uncollectedLevel = star;
          item.checkedStopRemind = false;
          this.searchItems.push(item);
        }
        this.totalSuspensionMembershipFee = response.data.items.totalSuspensionMembershipFee;
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
      this.loadedList = true;
    },
    onCheckReminderStop() {
      let checked = 0;
      let uncheckAll = true;
      for (let item of this.searchItems) {
        if (item.checkedStopRemind) {
          uncheckAll = false;
          checked++;
        }
      }
      if (checked ==  this.searchItems.length) {
        this.checkedAll = true;
      } else if (uncheckAll) {
        this.checkedAll = false;
      }
    },
    onRequestDownload() {
      this.$swal.confirm("請求データをダウンロードしてもよろしいでしょうか？", undefined, () => {
        this.$swal.success("請求データをダウンロードしました", undefined, () => {
            
        })
      })
    },
    onSaveCollect(item) {
      if (!item.collectDate) {
        return this.hasError = true;
      }
      let form = { 
        supplierDisplayCode: item.supplierDisplayCode, 
        supplierCompanyId: item.supplierCompanyId, 
        collectDate: new Date(item.collectDate), 
        version: item.version
      };
      TransferDataManagement.saveCollectSuspensionMembership(form)
      .then(() => {
        this.$swal.success("Success!");
        this.getSearchItems();
      })
      .catch(error => {
        this.$swal.error(undefined, error.response.data.message);
      })
    },
    onStopRemind(item) {
      this.$swal.confirm("催促メールを停止してもよろしいでしょうか？", undefined, () => {
        let form = { 
          supplierDisplayCode: item.supplierDisplayCode, 
          supplierCompanyId: item.supplierCompanyId, 
          suspensionMembershipDate: new Date(item.suspensionMembershipDate),
          version: item.version
        };
        TransferDataManagement.stopReminderMail(form)
        .then(() => {
          this.$swal.success("催促メールを停止しました", undefined, () => {
            this.getSearchItems();
          });
        })
        .catch(reason => {
          this.$errorMessage(reason);
        })
      });
    },
    onStopRemindBundle() {
      this.$swal.confirm("一括で催促メールを停止してもよろしいでしょうか？", undefined, () => {
        let formList = [];
        debugger
        for (let item of this.searchItems) {
          if (item.checkedStopRemind) {
            let form = { 
              supplierDisplayCode: item.supplierDisplayCode, 
              supplierCompanyId: item.supplierCompanyId, 
              suspensionMembershipDate: new Date(item.suspensionMembershipDate),
              version: item.version
            };
            formList.push(form);
          }
        }
        if (formList.length < 1) {
          return this.$swal.error(undefined, "現在データがございません。");
        }
        TransferDataManagement.stopReminderMailBulk(formList)
        .then(() => {
          this.$swal.success("一括で催促メールを停止しました", undefined, () => {
            this.getSearchItems();
          });
        })
        .catch(reason => {
          this.$errorMessage(reason);
        })
      });
    },
    sendMail(item) {
      this.$swal.confirm("催促メールを送信してもよろしいでしょうか？", undefined, () => {
        let form = { 
          supplierDisplayCode: item.supplierDisplayCode, 
          supplierCompanyId: item.supplierCompanyId, 
          suspensionMembershipDate: new Date(item.suspensionMembershipDate),
          version: item.version,
          requestNo: item.requestNo
        };
        TransferDataManagement.sendMailReminder(form)
        .then(() => {
          this.$swal.success("催促メールを送信しました", undefined, () => {
            this.getSearchItems();
          });
        })
        .catch(reason => {
          this.$errorMessage(reason);
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.stop {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.stop__search {
  display: flex;
  margin: 10px;
  :last-child {
    margin-left: auto;
  }
}
.stop__summary {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.stop__action {
  display: inline-flex;
}
.stop__action-button {
  margin: 5px;
}
.stop__items-withdrawal {
  color: $red;
  font-size: 12px;
}

.reminder__checkbox {
  margin: 5px;
}

.stop__collect_date_input {
  display: inline-flex;
}
.stop__collect_date_input_text {
  margin-right: 5px;
  width: 150px;
}
.stop__collect_date_input_action {
  min-width: 80px;
}
.stop__collected {
  color: $red;
}

</style>