<template>
    <b-checkbox-group v-model="model.isOpenSupplier" :options="membership"></b-checkbox-group>
</template>

<script>
import Membership from "@/model/membership/Membership"

export default {
    props: [ 'model' ],
    data: () => {
        return {
            membership: Membership.create().list
        }
    }
}
</script>

<style scoped lang="scss">
</style>