<template>
  <div class="row" >
    <div class="tree-list col-lg-4">
      <div id="wrapper-category" class="position-relative">
        <div class="mb-3">
          <button type="button" class="btn btn-light btn-sm mr-2 toggleCollapse" @click="toggleCollapse(false)">全展開</button>
          <button type="button" class="btn btn-light btn-sm mr-2 toggleCollapse" @click="toggleCollapse(true)">全縮小</button>
        </div>
        <ul class='category-tree pl-1 mt-2'>
          <li v-for="largeCategory in categoryListUI" :key="largeCategory.id" class="large-item py-1" :class='`cat-${largeCategory.id}`'>
            <template v-if="largeCategory.children.length === 0">
              <font-awesome-icon icon="caret-right" size="lg" class="icon-right disabled-color" />
            </template>
            <template v-else-if="largeCategory.isOpen">
              <font-awesome-icon icon="caret-down" size="lg" class="icon-down" @click="toogleChildren(largeCategory)" />
            </template>
            <template v-else>
              <font-awesome-icon icon="caret-right" size="lg" class="icon-right" @click="toogleChildren(largeCategory)" />
            </template>
            
            <span @click="getCategory(largeCategory,'top')">
              {{ largeCategory.categoryName }}
            </span>
            <ul class="list-unstyled pl-5" :hidden="!largeCategory.isOpen">
              <li v-for="midCategory in largeCategory.children" :key="midCategory.id" class="medium-item py-1" :class='`cat-${largeCategory.id}-${midCategory.id}`'>
                <template v-if="midCategory.children.length === 0">
                  <font-awesome-icon icon="caret-right" size="lg" class="icon-right disabled-color" />
                </template>
                <template v-else-if="midCategory.isOpen">
                  <font-awesome-icon icon="caret-down" size="lg" class="icon-down" @click="toogleChildren(midCategory)" />
                </template>
                <template v-else>
                  <font-awesome-icon icon="caret-right" size="lg" class="icon-right" @click="toogleChildren(midCategory)" />
                </template>
                <span @click="getCategory(midCategory,'mid')">
                  {{ midCategory.categoryName }}
                </span>
                <ul class="list-unstyled pl-5" :hidden="!midCategory.isOpen">
                  <li v-for="lowCategory in midCategory.children" :key="lowCategory.id" class="low-item py-1" :class='`cat-${largeCategory.id}-${midCategory.id}-${lowCategory.id}`'>
                    <font-awesome-icon icon="circle" class="icon-circle" />                            
                    <span @click="getCategory(lowCategory,'min')">
                      {{ lowCategory.categoryName }}
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <div v-if="categoryListUI.length == 0 && searched == true">
          該当カテゴリが存在しません。
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="../category/BasicItemCategory.scss">  
</style>

<script>
import Category from "@/model/Category";

export default {
  data: () => {
    return {
      form: {},
      categoryInfo: {},
      categoryUnspsc: {},
      catInfo:false,
      minCatInfo:false,
      showCategoryEdit:false,
      showUnspscEdit:false,
      parentCategory:{},      
    }
  },
  props: ["categoryListUI","searched"],
  watch: {},
  mounted() {
    this.show()    
  },
  methods: {    
    show() {
      this.getCategoryList();
    },
    scrollCatTop: function(){
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      });
    },
    getCategoryList() {
      Promise.all([
        Category.getList(),
      ]).then((results) => {
        if (!results[0].data.length) this.$emit("parentCategoryListUI",{})
        this.$emit("parentCategoryListUI",results[0].data)
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },    
    getCategory(category,type){
      this.catInfo = false;
      this.minCatInfo = false;
      Promise.resolve(
        Category.getCategory(category.id),
      ).then((result) => {
        this.closeAllEdit()
        if (!result.data.length) this.categoryInfo = {}
        this.categoryInfo = result.data;
        this.catInfo = true;
        if(type=='min'){
          this.minCatInfo = true;          
        }else{
          this.minCatInfo = false;
        }
        if(category.children != null && category.isOpen != true){
          this.toogleChildren(category)
        }
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    closeAllEdit(){
      this.form = {};
      this.showCategoryEdit = false;
      this.showUnspscEdit = false;
    },
    /*
    async updateCategoryInfo(){
      await Category.updateCategoryInfo(this.form.id,this.form);
    },
    async updateUnspsc(){
      await Category.updateUnspsc(this.form.id,this.form);
    },  
    */
    getItemFromChildren(children,id){
      for(var i = 0; i < children.length; i++){
        if(children[i]['id'] == id){
          var targetCategory = children[i]
          break
        }
      }
      return {data:targetCategory,index:i}
    },
    toogleChildren(category) {
      if (category.children && category.children.length > 0) {
        this.$set(category, "isOpen", !category.isOpen) 
      }
    },
    toggleCollapse(isOpen) {
      for (let large of this.categoryListUI) {
        if (large.children) {
          for (let medium of large.children) {            
            this.$set(medium, "isOpen", !isOpen) 
          }
        }
        this.$set(large, "isOpen", !isOpen) 
      }
    }, 
  }
}
</script>