import Vue from "vue";

import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

Datepicker.props.language.default = () => ja;
Datepicker.props.format.default = "yyyy-MM-dd";
Datepicker.props.placeholder = {
  type: String,
  default: "YYYY-MM-DD"
};

Vue.component("Datepicker", Datepicker);

export default {
  components: {
    Datepicker
  }
}
