<template>
  <div class="p-3 company-detail-header">
    <h5 class="mb-0" v-b-toggle.company-detail-header-body>
      会社情報
      <font-awesome-icon class="float-right" icon="sort-down"/>
    </h5>
    <b-collapse id="company-detail-header-body" class="p-2 mt-2">  
        <p><b>法人番号:</b>{{company.corporationNumber || '«未登録»'}}</p>
        <p><b>会社名:</b>{{company.fullName || '«未登録»'}}</p>
        <p>
          <b>住所:</b>
          <br/>{{company.postalCode || '«未登録»'}}
          <br/>{{(company.address1 + company.address2) || '«未登録»'}}
        </p>
        <p>
          <b class="text-muted">代表者</b><br/>
          <b>お名前（漢字）:</b>{{company.ceoName || '«未登録»'}}<br/>
          <b>お名前（カナ）:</b>{{company.ceoNameKana || '«未登録»'}}<br/>
          <b>生年月日：</b>{{ company.ceoBirthDate || '«未登録»'}}
        </p>
    </b-collapse>
  </div>
</template>
<script>
export default {
  props:["company"]
}
</script>
<style lang="scss">
  .company-detail-header{
    background: $light-cyan-blue;
  }
</style>