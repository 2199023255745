<template>
  <div class="stop">
    <div class="stop__search">
      <input type="text" class="form-control w-50" placeholder="サプライヤコード、サプライヤ名" v-model="queries.keyword" maxlength="100" />
      <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
        <font-awesome-icon icon="search" />
      </button>
      <b-button variant="primary" size="md" :disabled="searchItems.length === 0" v-on:click="onRequestDownload">請求データダウンロード</b-button>
    </div>
    <div class="past__actions">
      <div class="past__actions_item">
        <select class="past__actions_item_request form-control ml-0" v-model="queries.dateTypeSelectCd" >
          <option :value="'requestDate'">請求日基準</option>
          <option :value="'collectDate'">回収日基準</option>
        </select>
        <datepicker 
          v-model="queries.dateFrom" typeable wrapper-class="past__actions_item_request mr-1" input-class="form-control" >
        </datepicker>
        <span style="align-self: center;"> ~ </span>
        <datepicker 
          v-model="queries.dateTo" typeable wrapper-class="past__actions_item_request ml-1 mr-1" input-class="form-control" >
        </datepicker>
        <button type="button" class="btn btn-light" @click="resetDatePicker">
          <font-awesome-icon icon="times" />
        </button>
      </div>
      <div class="past__actions_item">
        <input type="radio" id="status_0"  value="0" v-model="queries.transferStatus" />
        <span class="past__actions_item_request_status">請求</span>
        <input type="radio" id="status_1"  value="1" v-model="queries.transferStatus" />
        <span class="past__actions_item_request_status">回収</span>
        <input type="radio" id="status_2"  value="2" v-model="queries.transferStatus" />
        <span class="past__actions_item_request_status">未収</span>
        <input type="radio" id="status_3"  value="3" v-model="queries.transferStatus" />
        <span class="past__actions_item_request_status">返金</span>
      </div>
    </div>
    <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:10%">請求No.</th>
          <th class="text-center" style="width:20%">サプライヤ</th>
          <th class="text-center" style="width:20%">会費</th>
          <th class="text-center" style="width:10%">請求日</th>
          <th class="text-center" style="width:10%">振替回</th>
          <th class="text-center" style="width:20%">回収日</th>
          <th class="text-center" style="width:10%">振替結果</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in searchItems" :key="`${item[dataType.detailKey]}${index}`">
          <td>{{ item.requestNo }}</td>
          <td>{{ item.supplierDisplayCode }}<br />{{ item.supplierName }}</td>
          <td class="text-right">
            {{ item.requestFee | numberFormat }}円
            <br />
            {{item.targetPeriodYearFrom}}/{{item.targetPeriodMonthFrom}} ～ {{item.targetPeriodYearTo}}/{{item.targetPeriodMonthTo}}
          </td>
          <td>{{ item.requestDate | toDay }}</td>
          <td>{{ item.requestStatus }}</td>
          <td>
            <template v-if="item.collectDate && item.transferStatus === 0 ">
              {{item.collectDate | toDay}}
            </template>
            <template v-else-if="item.collectDate && item.transferStatus > 0 && !item.isSavedCollectDate ">
              <div class="past__items_collect-date">
                <span class="past__items_collect-date-text">{{item.collectDate | toDay}}</span>
                <b-button variant="primary" class="stop__collect_date_input_action" v-on:click="item.isSavedCollectDate = true; $forceUpdate();">訂正</b-button>
              </div>
            </template>
            <template v-else-if="item.isSavedCollectDate">
              <div class="past__items_collect-date">
                <datepicker 
                  v-model="item.collectDate" 
                  required typeable 
                  wrapper-class="list-inline-item"
                  @selected="hasError = false; $forceUpdate();"
                  @input="hasError = false; $forceUpdate();"
                  :input-class="{'form-control stop__collect_date_input_text': true, 'has-error': hasError}">
                </datepicker>
                <b-button variant="primary" class="stop__collect_date_input_action" v-on:click="onSaveCollectDate(item)">確定</b-button>
              </div>
              <span class="text-danger text-left" v-show="hasError">必須項目です。</span>
            </template>
            <template v-else-if="!item.collectDate">
              未収
            </template>
          </td>
          <td>{{ item.transferStatusName || '未収' }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="searchItems.length === 0" class="text-left">
      <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
    </div>
    <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
  </div>
</template>

<script>
import TransferDataManagement from '@/model/TransferDataManagement' 

import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"

export default {
  components: { Pagination, LoadingList },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: false,
      dataType: {},
      searchItems: [],
      queries: {
        keyword: '',
        dateTypeSelectCd: 'requestDate',
        dateFrom: '',
        dateTo: '',
        transferStatus: '0'
      },
      hasError: false
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.getSearchItems(true);
  },
  methods: {
    getSearchItems(loadAll = false) {
      // mock
      this.loadedList = false;
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }

      TransferDataManagement.getCollectMembershipList({ 
        pagination: this.pagination, 
        queries: {keyWord: this.queries.keyword, 
        transferStatus: this.queries.transferStatus, 
        dateTypeSelectCd: this.queries.dateTypeSelectCd,
        dateFrom: this.queries.dateFrom,
        dateTo: this.queries.dateTo,
        currentMonth: this.$route.params.currentMonth, 
        currentYear: this.$route.params.currentYear } })
      .then(response => {
        this.searchItems = response.data.items.dataList;
        for (let item of this.searchItems) {
          if (item.requestNumberTime) {
            item.requestStatus = item.requestNumberTime + '回目'
          } else {
            item.requestStatus = ''
          }
          item.isSavedCollectDate = false;
        }
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
      this.loadedList = true;
    },
    onCheckReminderStopAll() {
      const all = document.querySelector(".reminder__checkbox--all")
      const item = document.getElementsByClassName("reminder__checkbox--item")
      if (all.checked) item.forEach(element => element.checked = true)
      if (!all.checked) item.forEach(element => element.checked = false)
    },
    onRequestDownload() {
      this.$swal.confirm("請求データをダウンロードしてもよろしいでしょうか？", undefined, () => {
        this.$swal.success("請求データをダウンロードしました", undefined, () => {
            
        })
      })
    },
    onChangeCollectDate(targetIndex) {
      this.searchItems.forEach((item, index) => {
        if (targetIndex != index) return
        if (item.collectDate) item.isSavedCollectDate = false
      })
    },
    onSaveCollectDate(item) {
      if (!item.collectDate) {
        return this.hasError = true;
      }
      let form = { 
        supplierDisplayCode: item.supplierDisplayCode, 
        supplierCompanyId: item.supplierCompanyId, 
        collectDate: new Date(item.collectDate), 
        requestNo: item.requestNo,
        version: item.version
      };
      TransferDataManagement.saveCollectDate(form)
      .then(() => {
        this.getSearchItems();
      })
      .catch(reason => {
        this.$errorMessage(reason);
      })
    },
    resetDatePicker() {
      this.queries.dateFrom = null;
      this.queries.dateTo = null;
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/css/table.scss";

.stop {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.stop__search {
  display: flex;
  margin: 10px;
  :last-child {
    margin-left: auto;
  }
}
.stop__summary {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.stop__items-withdrawal {
  color: $red;
  font-size: 12px;
}

.past__actions {
  display: inline-flexbox;
  margin: 10px;
}
.past__actions_item {
  display: flex;
  margin: 10px 0 10px 0;
}
.past__actions_item_request {
  margin-right: 10px;
  margin-left: 10px;
  width: 175px;
}
.past__actions_item_request_status {
 margin-right: 15px;
}
.past__items_collect-date {
  display: flex;
}
.past__items_collect-date-text {
  margin-right: 10px;
}

.reminder__checkbox {
  margin: 5px;
}

.stop__collect_date_input {
  display: inline-flex;
  max-inline-size: 150px;
}
.stop__collect_date_input_text {
  margin-right: 5px;
  width: 150px;
}
.stop__collect_date_input_action {
  min-width: 80px;
}
.stop__collected {
  color: $red;
}
/deep/ .has-error {
  border-color: #bf1601 !important;
}
.btn-light {
  border-color: lightgray;
  min-width: 25px;
  box-shadow: none;
}

</style>