<template>
  <table class="bg-white w-100 rpa-item-table">
    <tr>
      <th>会社名</th>
      <td :class="company.companyName === target.companyName || 'text-danger'">
        <span>{{company.companyName}}</span>
      </td>
      <td>
        {{target.companyName || '-'}}
        <span v-if="result.failedReason" class="text-danger"><br/>{{result.failedReason}}</span>
      </td>
    </tr>
    <tr>
      <th>出願番号</th>
      <td :class="input.applicationNo === target.applicationNo || 'text-danger'">
        {{input.applicationNo}}</td>
      <td>
        {{target.applicationNo || '-'}}
      </td>
    </tr>
    <tr>
      <th>文献番号</th>
      <td :class="input.referenceNo === target.referenceNo || 'text-danger'">
        {{input.referenceNo}}</td>
      <td>{{target.referenceNo || '-'}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["result", "company"],
  components: { },
  computed: {
    input: function(){
      if(this.result.processedData){
        return {
          referenceNo: this.result.processedData.split(',')[1],
          applicationNo: this.result.processedData.split(',')[0]
        }
      }
      return {}
    },
    target: function(){
      
      return {}
    }
  }
}
</script>

<style>

</style>