<template>
  <div class="wrapper-pagination row  d-flex flex-column align-items-center" v-if="pagination.totalPages > 0">
    <div class="text-right p-0">
      <ul class="pagination m-0">
        <li class="page-item">
          <a class="page-link prev-button" href="javascript:void(0);" @click.prevent="changePage(pagination.page - 1)">
            <font-awesome-icon icon="chevron-left" /> 前へ
          </a>
        </li>
        <li :class="'page-item '+ (i === pagination.page ? 'active':'')" v-for="i in pagination.totalPages" :key="i">
          <a :class="'page-link'" href="javascript:;" @click.prevent="changePage(i)">{{ i }}</a>
        </li>
        <li class="page-item">
          <a class="page-link next-button" href="javascript:;" @click.prevent="changePage(pagination.page + 1)">
            次へ
            <font-awesome-icon icon="chevron-right" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      range: 3
    };
  },
  
  methods: {
    changePage(page) {
      if(page < 1) page = 1;
      if(page > this.pagination.totalPages) page = this.pagination.totalPages;
      this.pagination.page = page;
      this.$emit("loadList");
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style  lang="scss" scoped>

</style>
