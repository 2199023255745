<template>
  <div>
    <div class="page-title pt-1 mb-2 mt-3">
      <h3 style="display:inline-block;">{{title}}</h3>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
}
.page-title p {
  margin-bottom: 0;
}
</style>
