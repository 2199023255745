const numeral = require('numeral');
const moment = require('moment');

const formatCurrency = function (value) {
  return numeral(value).format("0,0");
}

const formatHumans = function (value) {
  return numeral(value).format("0,0") + '名';
}

const formatCurrencyView = function(value){
  if(value){
    return numeral(value).format("0,0") + '円';
  }else return "≪未登録≫";
}

const formatCapitalCurrency = function (value) {
  return numeral(value).format("0,0");
}

const formatDate = function(value){
  if(value){
    return moment(value).format('YYYY-MM-DD');
  }else return "";
}

const formatDateTime = function(value){
  if(value){
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }else return "";
}

const formatCapitalCurrencyView = function(value){
  
  if(value){
    return new Intl.NumberFormat('ja-JP', {  style: 'currency',  currency: 'JPY',currencyDisplay: 'name'}).format(value);
  }else return "-";
}

const formatLongText = function(value){
  if(value){
    if(value.length > 40) return value.substr(0,40) + "…";
    else return value;
  }else return "≪未登録≫";
}

const formatList = function(value){
  if(!value || value.length <= 0) return "≪未登録≫";
  return value.join('\n');
}

export {
  formatCurrency,
  formatHumans,
  formatCurrencyView,
  formatCapitalCurrency,
  formatCapitalCurrencyView,
  formatLongText,
  formatDateTime,
  formatDate,
  formatList
}