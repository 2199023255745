<template>
  <div>
      <PageTitle title="バイヤ管理情報">
          <router-link to="/system/buyer-info/0" style="float:right;" class="mb-2 btn btn-primary">
              <font-awesome-icon icon="plus"/>
              新規登録</router-link>
      </PageTitle>
      <div style="clear:both;" class="mt-4 mx-3 bg-light-blue p-4">
          <table class="buyer-table">
              <colgroup>
                <col width="5%"/>
                <col width="10%"/>
                <col width="20%"/>
                <col width="25%"/>
                <col width="40%"/>
                <col width="10%"/>
              </colgroup>
              <thead>
                <tr>
                    <th>No</th>
                    <th>会社コード</th>
                    <th>会社名</th>
                    <th>会社所在</th>
                    <th>会社業種</th>
                    <th>処理</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-if="!list || list.length <= 0">
										<td colspan="6">バイヤ情報レコードが存在しません。</td>
                  </tr>
                  <tr v-else v-for="(row, index) in list" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{row.buyerCompanyGroupCode}}</td>
                    <td>{{row.buyerCompanyGroupName}}</td>
                    <td><pre style="font-family: inherit;">{{row.buyerAddress}}</pre></td>
                    <td><pre style="font-family: inherit;">{{row.buyerIndustry}}</pre></td>
                    <td>
											<router-link :to="'/system/buyer-info/'+row.id"><font-awesome-icon icon="edit"/>編集</router-link><br/>
											<a href="javascript:void(0)" @click.prevent="onDelete(row.id)"><font-awesome-icon icon="times"/>削除</a>
                    </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
import BuyerMasterInfo from "@/model/BuyerMasterInfo.js";
export default {
	components: { PageTitle },
	data: function(){
		return {
			list: []
		}
	},
	mounted: function(){
		this.onLoad();
	},
	methods: {
		onLoad(){
			BuyerMasterInfo
				.getList()
				.then(result => this.list = result.data || [])
				.catch(reason => this.$errorMessage(reason));
		},
		onDelete(id){
			this.$bvModal.msgBoxConfirm("バイヤ情報を削除しますか？")
				.then(value => {
					if(value)
						BuyerMasterInfo.delete(id)
							.then(() => {
								this.$successMessage("バイヤ情報を削除しました。");
								this.onLoad();
							})
							.catch(reason => this.$errorMessage(reason));
				});
		}
	}
}
</script>

<style>
	.buyer-table{
		width: 100%;
		table-layout: fixed;
	}
	.buyer-table tr th {
		border: 1px solid #d3d3d3;
		padding: .5rem 1rem;
	}
	.buyer-table tr td {
		border: 1px solid #d3d3d3;
		padding: .5rem 1rem;
	}
</style>