<template>
  <div>
    <PageTitle title="サプライヤーTSR情報比較"></PageTitle>
    <div class="supplier_detail">
      <div class="row">

        <div class="col">
          <h2>サプライヤ登録情報情報</h2>
          <div class="supplier_detail__company">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                会社情報
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">法人番号</label>
                  <div class="supplier_detail__contents_item_value">{{form.current.corporationNumber}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">会社名</label>
                  <div class="supplier_detail__contents_item_value">{{form.current.fullName}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">住所</label>
                  <div class="supplier_detail__contents_item_value">
                    {{form.current.postalCode}}
                    <br />
                    {{form.current.address}}
                  </div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">代表者</label>
                  <div class="supplier_detail__contents_item_value">
                    お名前（漢字）：{{form.current.ceoName}}<br/>
                    お名前（カナ）：{{form.current.ceoNameKana}}<br/>
                    生年月日：{{form.current.ceoBirthDate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="supplier_detail__detail">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                会社詳細情報
              </div>
              <div class="supplier_detail__contents_body">  
                <table class="table border-table">
                  <tbody>
                    <tr>
                      <th class="background-body">設立年月</th>
                      <td>{{form.current.establishmentDate}}</td>
                    </tr>
                    <tr>
                      <th class="background-body">資本金</th>
                      <td>{{form.current.capital}}円</td>
                    </tr>
                    <tr>
                      <th class="background-body">従業員数</th>
                      <td>{{form.current.employeesNumber}}人</td>
                    </tr>
                    <tr>
                      <th class="background-body">業種</th>
                      <td style="height:104px;">
                        <p>未登録</p> <!--//TODO add 業種-->
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主要販売先</th>
                      <td>
                        <p>≪未登録≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主な仕入先</th>
                      <td>
                        <p>≪未登録≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主な株主</th>
                      <td><p>≪未登録≫</p></td>
                    </tr>
                    <tr>
                      <th class="background-body">取引銀行</th>
                      <td>
                        <p>≪未登録≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">決算情報</th>
                      <td>
                        <table class="table border-table">
                          <thead>
                            <tr>
                              <td style="text-align:center;">決算年月</td>
                              <td style="text-align:center;">売上</td>
                              <td style="text-align:center;">税引前利益</td>
                              <td style="text-align:center;">税引後利益</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row,i) in form.current.performances" :key="i">
                              <td>{{row.closingDate}}</td>
                              <td style="text-align:right;">{{row.sales}}円</td>
                              <td style="text-align:right;">{{row.profit}}円</td>
                              <td style="text-align:right;">{{row.profitAfterTax}}円</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="supplier_detail__authorization">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                システム情報
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">最終更新日時</label>
                  <div class="supplier_detail__contents_item_value">{{form.current.updatedOn}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">最終更新者</label>
                  <div class="supplier_detail__contents_item_value">{{form.current.updateUser}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">再入力依頼日時</label>
                  <div class="supplier_detail__contents_item_value"></div>
                </div>
                <b-button variant="light" @click="$refs['tsr-re-request-modal'].show();">再入力依頼</b-button>
              </div>
            </div>
          </div>
        </div>
        


        <div class="col">
          <h2>TSR情報</h2>
          <div class="supplier_detail__company">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                会社情報 (TSR評点: {{form.tsr.tsrScore}})
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">法人番号</label>
                  <div class="supplier_detail__contents_item_value">{{form.tsr.corporationNumber}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">会社名</label>
                  <div class="supplier_detail__contents_item_value">{{form.tsr.fullName}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">住所</label>
                  <div class="supplier_detail__contents_item_value">
                    {{form.tsr.postalCode}}
                    <br />
                    {{form.tsr.address}}
                  </div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">代表者</label>
                  <div class="supplier_detail__contents_item_value">
                    お名前（漢字）：{{form.tsr.ceoName}} <br/>
                    お名前（カナ）：{{form.tsr.ceoNameKana}}<br/>
                    生年月日：{{form.tsr.ceoBirthDate}}
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="supplier_detail__detail">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                会社詳細情報
              </div>
              <div class="supplier_detail__contents_body">  
                <table class="table border-table">
                  <tbody>
                    <tr>
                      <th class="background-body">設立年月</th>
                      <td>{{form.tsr.establishmentDate}}</td>
                    </tr>
                    <tr>
                      <th class="background-body">資本金</th>
                      <td>{{form.tsr.capital}}円</td>
                    </tr>
                    <tr>
                      <th class="background-body">従業員数</th>
                      <td>{{form.tsr.employeesNumber}}人</td>
                    </tr>
                    <tr>
                      <th class="background-body">業種</th>
                      <td>
                        <p>≪TSR情報なし≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主要販売先</th>
                      <td>
                        <p>≪TSR情報なし≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主な仕入先</th>
                      <td>
                        <p>≪TSR情報なし≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">主な株主</th>
                      <td><p>≪TSR情報なし≫</p></td>
                    </tr>
                    <tr>
                      <th class="background-body">取引銀行</th>
                      <td>
                        <p>≪TSR情報なし≫</p>
                      </td>
                    </tr>
                    <tr>
                      <th class="background-body">決算情報</th>
                      <td>
                        <table class="table border-table">
                          <thead>
                            <tr>
                              <td style="text-align:center;">決算年月</td>
                              <td style="text-align:center;">売上</td>
                              <td style="text-align:center;">税引前利益</td>
                              <td style="text-align:center;">税引後利益</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row,i) in form.tsr.performances" :key="i">
                              <td>{{row.closingDate}}</td>
                              <td style="text-align:right;">{{row.sales}}円</td>
                              <td style="text-align:right;">{{row.profit}}円</td>
                              <td style="text-align:right;">{{row.profitAfterTax}}円</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="supplier_detail__authorization">
            <div class="supplier_detail__contents">
              <div class="supplier_detail__contents_title">
                システム情報
              </div>
              <div class="supplier_detail__contents_body">
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">TSR取得日時</label>
                  <div class="supplier_detail__contents_item_value">{{form.tsr.receivedOn}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">最終審査者</label>
                  <div class="supplier_detail__contents_item_value">{{form.tsr.examinedBy}}</div>
                </div>
                <div class="supplier_detail__contents_item">
                  <label class="supplier_detail__contents_item_label">審査日時</label>
                  <div class="supplier_detail__contents_item_value">{{form.tsr.examinedOn}}</div>
                </div>
                <b-button variant="primary" @click="$refs['tsr-change-status-modal'].show();">TSR審査完了</b-button>        
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <b-modal ref="tsr-change-status-modal" title="判断を変更" @ok="onTsrAllow">
      <b-alert show>このTSR情報判断を「OK」に変更しますか？</b-alert>
      <b-form-group label="審査ステータス">
        <b-select v-model="changeStatus.isExamined">
          <b-select-option :value="true">OK</b-select-option>
          <b-select-option :value="false">NG</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group label="判断を変更する理由">
        <b-textarea v-model="changeStatus.examinedRemarks"></b-textarea>
      </b-form-group>
    </b-modal>

    <b-modal ref="tsr-re-request-modal" title="再入力お願い" @ok="onReRequest">
      <b-form-group label="サプライヤへ連絡事項コメント">
        <b-textarea v-model="reRequestForm.reRequestReason"></b-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import SupplierTsr from '@/model/check/SupplierTsr';
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
  props: {},
  data: () => {
    return {
      form: {
        current: {},
        tsr: {}
      },
      changeStatus: {

      },
      reRequestForm: {
        
      }
    }
  },
  mounted() {
    SupplierTsr.search(this.$route.params.supplierCode).then(result => {
      this.form = result.data;
    }).catch(reason => this.$errorMessage(reason));
  },
  computed: {
    address() {
      return this.detail.address1 + (this.detail.address2 ? this.detail.address2 : '')
    }
  },
  methods: {
    onReRequest(){
       SupplierTsr.reRequest(this.$route.params.supplierCode, this.reRequestForm).then( () =>{
        this.$successMessage("サプライヤさんへ再入力依頼しました。");
      }).catch(reason => this.$errorMessage(reason));
    },
    onTsrAllow(){
      SupplierTsr.change(this.$route.params.supplierCode, this.changeStatus).then( () =>{
        this.$successMessage("TSRの判断を変更いたしました。");
      }).catch(reason => this.$errorMessage(reason));
    }
  }
}
</script>

<style scoped lang="scss">

.supplier_detail {
  margin-left: 20px;
}
.supplier_detail__contents {
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background: $light-cyan-blue;
}
.supplier_detail__contents_title {
  margin: 10px;
  font-weight: bold;
}
.supplier_detail__current_status {
  margin: 10px 20px 50px 0;
  padding: 30px;
  border: 1px solid $very-light-gray;
}
.supplier_detail__contents_body {
    margin: 10px;
  }
.supplier_detail__contents_item {
  display: flex;
  align-items: flex-start;
  &--first {
    border-bottom: 1px solid $very-light-gray;
  }
}
.supplier_detail__contents_item_label {
  flex: 2;
  font-size: 16px;
  padding-right: 1rem;
  margin-top: 6px;
}
.supplier_detail__contents_item_value {
  flex: 10;
  margin-top: 6px;
}

.border-table td,
.border-table th {
  border: 1px solid $very-light-gray;
  border-collapse: collapse;
  background-clip: padding-box;
}

</style>
