<template>
  <div>
        <PageTitle :title="'バイヤ管理情報 > ' + (form.buyerCompanyGroupName || '≪未登録≫')">
            <router-link to="/system/buyer-info" class="btn btn-light border">戻る</router-link>
        </PageTitle>
        <div class="mt-4 mx-3 bg-light-blue p-4">
            <div class='form-item'>
                <label>企業コード</label>
                <b-col sm="2"><b-form-input v-model="form.buyerCompanyGroupCode" placeholder="例）DEMO-2"></b-form-input></b-col>
            </div>
            <div class='form-item mt-2'>
                <label>企業名</label>
                <b-col sm="4"><b-form-input v-model="form.buyerCompanyGroupName" placeholder="例）ジーニーラボ株式会社"></b-form-input></b-col>
            </div>
            <div class='form-item mt-2'>
                <label>企業住所</label>
                <b-col sm="4"><b-form-textarea v-model="form.buyerAddress" placeholder="例）東京都港区六本木一丁目"></b-form-textarea></b-col>
            </div>
            <div class='form-item mt-5'>
                <label>業種</label>
                <b-col sm="4"><b-form-textarea v-model="form.buyerIndustry" placeholder="例）業種１ > 業種２ > 業種３"></b-form-textarea></b-col>
            </div>            
            <div class='form-item mt-5'>
                <label>システム利用規約</label>
                <b-col sm="4">
                    <input-file v-model="attachmentFile" fileType='PDF'></input-file>
                </b-col>
            </div>
        </div>
        <div class="mt-4 mx-3 text-center">
            <b-button variant="primary" @click="onSave">保存</b-button>
        </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
import BuyerMasterInfo from "@/model/BuyerMasterInfo.js";
import InputFile from "@/components/fileManagement/InputFile.vue";
export default {
    components: { PageTitle, InputFile },
    data(){
        return {
            form: {

            },
            attachmentFile:[]
        };
    },
    mounted: function() {
        this.onLoad();
    },
    methods: {
        onLoad(){
            if(this.$route.params.id !== '0')
                BuyerMasterInfo
                    .get(this.$route.params.id)
                    .then(result => {
                        this.form = result.data
                        if(this.form.fileManagementCode){
                            this.attachmentFile = [{
                                fileName: this.form.fileName,
                                code: this.form.fileManagementCode
                            }]               
                        }
                    })
                    .catch(reason => this.$errorMessage(reason));
        },
        onSave(){
            this.form.fileName = this.attachmentFile[0].fileName;
            this.form.fileManagementCode = this.attachmentFile[0].code;
            BuyerMasterInfo
                .save(this.form)
                .then(() => {
                    this.$successMessage("バイヤー情報保存しました。");
                    this.$router.push('/system/buyer-info');
                })
                .catch(reason => this.$errorMessage(reason));
        }
    }
}
</script>

<style>
    .buyer-table{
        width: 100%;
        table-layout: fixed;
    }
    .buyer-table tr th {
        border: 1px solid #d3d3d3;
        padding: .5rem 1rem;
    }
    .buyer-table tr td {
        border: 1px solid #d3d3d3;
        padding: .5rem 1rem;
    }
</style>