<template>
  <div class="input-file">
    <table class="input-file-list" v-show="selectedFiles.length > 0">
      <tr v-for="(file, index) in selectedFiles" :key="file.code">
        <td>
          <a href="javascript:;" @click="downloadAttachment(file)">{{ file.fileName }}</a>
        </td>
        <span v-if="moveable">
          <span class="m-2">
            <a href="javascript:;" v-if="index !== 0" @click.prevent="moveFile(index, true)">
              <font-awesome-icon icon="arrow-up" />
            </a>
            <span v-else>&emsp;</span>
          </span>
          <span class="m-2">
            <a href="javascript:;" v-if="index !== selectedFiles.length-1" @click.prevent="moveFile(index, false)">
              <font-awesome-icon icon="arrow-down" />
            </a>
            <span v-else>&emsp;</span>
          </span>
        </span>
        <td v-show="!notDeleted && !estimateEditCode">
          <a href="javascript:;" @click.prevent="removeFile(index)">
            <font-awesome-icon icon="trash-alt" />
          </a>
        </td>
      </tr>
    </table>
    <input v-if="!notAttachment && !estimateEditCode && selectedFiles.length < 1" :disabled="!loaded" type="file" class="d-none" :id="'file' + _uid" ref="target" @change="selectFiles" :multiple="multiple" />
    <label v-if="!notAttachment && !estimateEditCode && selectedFiles.length < 1" :for="'file' + _uid" class="input-file-label text-nowrap">
      <font-awesome-icon icon="plus-circle" />
      <span class="ml-1">{{ labelAttachment }}&nbsp;</span>
      <slot></slot>
      <font-awesome-icon v-if="!loaded" icon="spinner" pulse />
    </label>
  </div>
</template>

<script>
import BuyerMasterInfo from "@/model/BuyerMasterInfo.js";
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    notAttachment: {
      type: Boolean,
      default: false
    },
    notDeleted: {
      type: Boolean,
      default: false
    },
    labelAttachment: {
      type: String,
      default: "添付ファイルを追加"
    },
    estimateEditCode: String,
    moveable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    fileType: {
      type: String,
      required: false,
      default: function() {
        return "";
      }
    },
    // VinhVN DEV2022-5802 custom msg error file type
    msgErrorFileType: {
      type: String,
      default: "PDF形式でのみ登録できます。"
    }
  },
  data() {
    return {
      loaded: true,
      selectedFiles: []
    };
  },
  created() {
    if (this.value && this.value.length > 0) {
      this.selectedFiles = this.value;
    }
  },
  watch: {
    value() {
      this.selectedFiles = this.value;
    }
  },
  methods: {
    selectFiles() {
      var files = Array.from(this.$refs.target.files);
      if (files.length === 0) {
        return;
      }
      for (let file of files) {
        if (file.size > 10485760) {
          this.$errorMessage("ファイルサイズが大きすぎます。10MB以下にしてください。");
          this.$refs.target.value = "";
          return;
        }
        if (this.selectedFiles.find(x => x.fileName === file.name)) {
          this.$errorMessage("既に同じ名称のファイルが選択されています。ご確認ください。");
          this.$refs.target.value = "";
          return;
        }
        if (this.fileType) {
          if (this.fileType.toLowerCase().indexOf(file.name.split(".").reverse()[0]) === -1) {
            this.$errorMessage(this.msgErrorFileType);
            console.log(this.$refs)
            this.$refs.target.value = "";
            return;
          }
        }
      }
      this.loaded = false;
      BuyerMasterInfo
        .upload(files[0],this.fileType)
        .then(response => {
            this.selectedFiles.push({
                code : response.data,
                fileName : files[0].name
            })
          for (var item of response.data.data) {
            this.selectedFiles.push(item.metadata);
          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.appCode === "FE-00004") {
           this.$errorMessage(this.$t("COMMON_MULTI_LANG.IMPORT_FAST_MSG_001"));
          }
        })
        .then(() => {
          this.loaded = true;
          //this.$refs.target.value = "";
          this.$emit("input", this.selectedFiles);
        });
    },
    removeFile(index) {
      this.beforeDeleted(index);
      this.selectedFiles.splice(index, 1);
      this.$emit("input", this.selectedFiles);
    },
    downloadAttachment(attachment) {
        console.log(attachment)
      return BuyerMasterInfo
        .download({
            fileManagementCode: attachment.code,
            fileName: attachment.fileName
        })
        .then(result => {   
            console.log(result.data.data)
            window.location = result.data.data
            //window.location = result.data.data.url;     
            // const blob = new Blob([result.data], { type: 'application/pdf' });
            // const link = document.createElement('a');
            // link.href = URL.createObjectURL(blob);
            // link.download = attachment.fileName;
            // link.click();
            // URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          // do nothing
        });
    },
    beforeDeleted(index) {
      this.$emit("beforeDeleted", this.selectedFiles[index]);
    },
    moveFile(index, moveUp) {
      // MKH007 fix bug J2TEST-584
      if (moveUp) {
        let fileChange = this.selectedFiles[index];
        this.selectedFiles.splice(index, 1, this.selectedFiles[index - 1]);
        this.selectedFiles.splice(index - 1, 1, fileChange);
      } else {
        let fileChange = this.selectedFiles[index];
        this.selectedFiles.splice(index, 1, this.selectedFiles[index + 1]);
        this.selectedFiles.splice(index + 1, 1, fileChange);
      }
    }
  }
};
</script>

<style scoped>
.input-file-label {
  color: #045eb4;
  cursor: pointer;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.input-file-label:hover {
  color: #1a9bd5;
}

.input-file-list {
  margin-bottom: 0.5rem;
  border: none !important;
}
.input-file-list td {
  border: none !important;
  background: none !important;
}
.input-file-list tr {
  border: none !important;
}
/* Only use for task https://jira.jienie.cloud/browse/DEV-3409 */
.table-remove-striped>.input-file-list>tr{
    background-color: #fff !important;
}
.table-remove-striped>.input-file-list> tr > td{
    padding: 0;
}
</style>
