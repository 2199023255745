const Message = {
  install(Vue) {
    // 1. add global method or property
    Vue.prototype.$errorMessage = function (error, message = 'システムエラーが発生しました。') {
      console.error(error);
      if (error.response && error.response.status == 400) {
        const h = this.$createElement
        const errorMessages = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, 
          error.response.data.errors
            .map(error => h('strong', { class: 'mr-2' }, error.message))
        )
        this.$root.$bvToast.toast([errorMessages], {
          title: "エラーが発生しました。",
          variant: "warning",
          noAutoHide: true,
          solid: true
        })
      } else {
        this.$root.$bvToast.toast(message, {
          title: "システムエラーが発生しました。",
          variant: "danger",
          solid: true
        })
      }
    }

    Vue.prototype.$successMessage = function (message, title = "登録・更新されました。") {
      this.$root.$bvToast.toast(message, {
        title: title,
        variant: "success",
        solid: true
      });
    }
    
    Vue.prototype.$warningMessage = function (message) {
      this.$root.$bvToast.toast(message, {
        title: "不正処理",
        variant: "warning",
        solid: true
      });
    }
  }
};
export default Message;
