import Model from './_base';

export default class SupplierSigner extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierSigner.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplierCode) {
        return Model.axios.get(this.url("/" + supplierCode + '/signer'));
    }

    static request(supplierCode) {
        return Model.axios.post(this.url("/" + supplierCode + '/signer'));
    }
}