<template>
  <div>
    <PageTitle title="被災管理">
      <router-link to="/system/safety/event" style="float:right;" class="mb-2 btn btn-primary"><font-awesome-icon icon="plus"/>新規登録</router-link>
    </PageTitle>
    <!-- search here -->
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <table class="search-table table table-bordered">
        <tr>
          <th><label for="search_name">名前</label></th>
          <td colspan="3">
            <input type="text" id="search_name" v-model="search.name" class="form-control col-5" placeholder="名前を入力してください">
          </td>
        </tr>
        <tr>
          <th><label for="search_category">災害区分</label></th>
          <td colspan="3">
            <b-checkbox v-model="search.category" name="category" native-value="1">災害</b-checkbox>
            <b-checkbox v-model="search.category" name="category" native-value="2">災害報告</b-checkbox>
            <b-checkbox v-model="search.category" name="category" native-value="3">災害予約</b-checkbox>
          </td>
        </tr>
        <tr>
          <th><label for="search_date_from">From日付</label></th>
          <td>
            <input type="date" id="search_date_from" v-model="search.start_date" class="form-control col-12" placeholder="日付を入力してください">
          </td>
          <th><label for="search_date_to">To日付</label></th>
          <td>
            <input type="date" id="search_date_to" v-model="search.end_date" class="form-control col-12" placeholder="日付を入力してください">
          </td>
        </tr>
      </table>
      <div class="controls mt-2 text-center">
        <button type="button" class="btn btn-primary" @click="search()">検索</button>
      </div>
    </div>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <table class="table table-bordered">
        <colgroup>
          <col width="5%"/>
          <col width="30%"/>
          <col width="30%"/>
          <col width="15%"/>
          <col width="20%"/>
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>被災タイトル</th>
            <th>状態</th>
            <th>発生日時</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td class="text-center">{{ index + 1 }}</td>
            <td><router-link :to="'/system/safety/events/' + item.id">{{ item.title }}</router-link></td>
            <td>{{ item.status }}</td>
            <td>{{ item.created_at }}</td>
            <td>
              <router-link :to="'/system/safety/event/' + item.id" class="btn btn-primary no-min btn-sm"><font-awesome-icon icon="edit"/></router-link>
              <button @click="deleteItem(item.id)" class="btn btn-danger no-min btn-sm ml-2"><font-awesome-icon icon="trash-alt"/></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
export default {
  components: {
    PageTitle
  },
  data() {
    return {
      search: {
        name: ""
      },
      items: [
        {
          id: 1,
          title: "災害発生",
          status: "発生中",
          created_at: "2020/01/01"
        },
      ]
    };
  },
}
</script>


<style scoped>
  .search-table {
    width: 100%;
  }
  .search-table th {
    text-align: center;
    padding-top: 8px;
    vertical-align: middle;
  }
  .search-table th label{
    margin:0;
  }
  .search-table td {
    padding: 8px;
  }
</style>