import Model from './_base';

export default class Term extends Model {
    static entity = 'terms';

    constructor(data) {
        super(Term.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getList(){
        return Model.axios.get(this.url(''));
    }
    
    static get(id){
        return Model.axios.get(this.url('/' + id));
    }
    
    static create(form,file){
        const formData = new FormData()
        formData.append('name', form.name)
        formData.append('isFile', form.isFile)
        formData.append('isNewFile', form.isNewFile)
        formData.append('file', file)
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return Model.axios.post(this.url(''),formData,config);
    }

    static update(id,form,file){        
        const formData = new FormData()
        formData.append('name', form.name)
        formData.append('isFile', form.isFile)
        formData.append('isNewFile', form.isNewFile)
        formData.append('file', file)
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return Model.axios.post(this.url('/' + id ),formData,config);
    }

    static delete(id){
        return Model.axios.delete(this.url('/' + id));
    }
    
    static download(id){
                return Model.axios.get(this.url('/' + id + '/download/pdf' ), { responseType: 'blob' });
    }    
}