import axios from 'axios';

export default class Model {
  static entity = '';
  static baseURL = process.env.VUE_APP_BACKEND_URL + 'api/';
  static baseURLAPI =  process.env.VUE_APP_BACKEND_URL;
  static axios = axios;

  constructor(entity){
    this.entity = entity;
  }

  static all(q, URL){
    return axios.get(URL ? URL : Model.baseURL + this.entity + q);
  }

  static get(id, URL){
    return axios.get(URL ? URL : Model.baseURL + this.entity + '/' + id);
  }
  
  post(URL){
    let data = Object.assign({}, this);
    delete data['entity'];
    return axios.post(URL ? URL : Model.baseURL + this.entity, data);
  }

  put(URL){
    let data = Object.assign({}, this);
    delete data['entity'];
    return axios.put(URL ? URL : Model.baseURL + this.entity, data);
  }

  save(){
    if(this.id){
      return this.put();
    }else{
      return this.post();
    } 
  }


  static url(url = ""){
    return Model.baseURL + this.entity + url;
  }

  static absolute(url){
    return Model.baseURLAPI  + url;
  }


  static urlServer(url){
    return Model.baseURLAPI + this.entity + url;
  }

  static delete(id, URL){
    return axios.delete(URL ? URL : Model.baseURL + this.entity + '/' + id);
  }

  
}