<template>
  <div>
    <div>
      <PageTitle title="DEMO用ステータス変更"></PageTitle>
    </div>
    <div class="p-4">
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_main_user_verified" :value="true" :unchecked-value="false" @change="onChange($event, 'is_main_user_verified')">主担当者メールアドレス確認済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_company_info_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_company_info_registered')">会社情報入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_users_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_users_registered')">担当者情報入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_account_created" :value="true" :unchecked-value="false" @change="onChange($event, 'is_account_created')">ID発行完了</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_category_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_category_registered')">対応する品目カテゴリ選択済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_product_name_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_product_name_registered')">品名入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_deliverable_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_deliverable_registered')">納品地域入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_verification_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_verification_registered')">検証情報入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_bank_account_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_bank_account_registered')">商品代金振込口座情報入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_buyer_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_buyer_registered')">取引するバイヤ選択済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_address_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_address_registered')">振替申込用紙を送付先入力済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_registration_completed" :value="true" :unchecked-value="false" @change="onChange($event, 'is_registration_completed')">一旦完了（審査開始）</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_address_verified" :value="true" :unchecked-value="false" @change="onChange($event, 'is_address_verified')">振替申込用紙を送付先確認済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_examination_completed" :value="true" :unchecked-value="false" @change="onChange($event, 'is_examination_completed')">サプライヤ審査完了</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_bank_transfer_account_registered" :value="true" :unchecked-value="false" @change="onChange($event, 'is_bank_transfer_account_registered')">振替口座登録済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_contract_signed" :value="true" :unchecked-value="false" @change="onChange($event, 'is_contract_signed')">契約署名済み</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_ng_completed" :value="true" :unchecked-value="false" @change="onChange($event, 'is_ng_completed')">口座振替手続きNG</b-form-checkbox></div>
      <div class="mt-2"><b-form-checkbox v-model="statuses.is_completed" :value="true" :unchecked-value="false" @change="onChange($event, 'is_completed')">口座振替手続きOK（取引開始）</b-form-checkbox></div>
    </div>
    <div class="p-4">
      <div>約款手続き</div>
      <b-button variant="primary" v-on:click="setContracts()">BHW共通の約款サプライヤへ手続き開始</b-button>
      <b-button class="ml-2" variant="primary" v-on:click="setBuyerContracts()">バイヤー特約をサプライヤへ手続き開始</b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>
import SupplierStatus from '@/model/SupplierStatus';

import PageTitle from "@/components/atoms/PageTitle.vue";


export default {
  components: { PageTitle },
  data: () => {
    return {
      statuses: {}
    }
  },
  mounted() {
   SupplierStatus.get(this.$route.params.supplierCode).then(result => {
    this.statuses = result.data;
   }).catch(reason => this.$errorMessage(reason));
  },
  methods: {
    setContracts(){
      SupplierStatus.setContracts(this.$route.params.supplierCode).then(result => {
        console.log(result);
        this.$successMessage("BHW共通の約款サプライヤへ手続き開始されました。")
      }).catch(reason => this.$errorMessage(reason));
    },
    setBuyerContracts(){
      SupplierStatus.setBuyerContracts(this.$route.params.supplierCode).then(result => {
        console.log(result);
        this.$successMessage("バイヤー特約をサプライヤへ手続き開始されました。")
      }).catch(reason => this.$errorMessage(reason));
    },
    onChange($event, statusName){
      SupplierStatus.set(this.$route.params.supplierCode, statusName, $event).then(result => {
        console.log(result);
      }).catch(reason => this.$errorMessage(reason));
    }
  }
}
</script>