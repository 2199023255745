<template>
    <div class="tsr-detail">
        <fieldset>
            <legend>会社情報</legend>
            <table>
                <colgroup>
                    <col width="15%"/>
                    <col width="40%"/>
                    <col width="40%"/>
                    <col width="5%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>項目名</th>
                        <th>サプライヤ登録情報</th>
                        <th>TSR情報</th>
                        <th>再依頼</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>法人番号</th>
                        <td :class="{'fail':failures.corporation_number === false}">
                            <TSREditableItem v-model="input.corporationNumber" :tsrValue="tsr.corporationNumber" type="text"/></td>
                        <td>{{tsr.corporationNumber}}</td>
                        <td><b-checkbox @change="request['法人番号'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>会社名</th>
                        <td :class="{'fail':failures.full_name === false}">
                            <TSREditableItem v-model="input.fullName" :tsrValue="tsr.fullName" type="text"/></td>
                        <td>{{tsr.fullName}}</td>
                        <td><b-checkbox @change="request['会社名'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td :class="{'fail':failures.address === false || failures.postal_code === false}">
                            <TSREditableItem v-on:action="$refs['inputAddress'].runAction($event)" v-model="input.postalCode" :tsrValue="tsr.postalCode" type="text"/>
                            <TSREditableItem :shared="true" ref="inputAddress" v-model="input.address" :tsrValue="tsr.address" type="textarea"/></td>
                        <td>{{tsr.postalCode}}<br/>{{tsr.address}}</td>
                        <td><b-checkbox @change="request['住所'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>代表者</th>
                        <td :class="{'fail':(
                            failures.ceo_name === false ||
                            failures.ceo_name_kana === false ||
                            failures.ceo_birth_date === false
                        )}">
                            <TSREditableItem v-on:action="$refs['inputCeoNameKana'].runAction($event); $refs['inputCeoBirth'].runAction($event)"  label="お名前（漢字）：" v-model="input.ceoName" :tsrValue="tsr.ceoName" type="text"/>
                            <TSREditableItem :shared="true" ref="inputCeoNameKana" label="お名前（カナ）：" v-model="input.ceoNameKana" :tsrValue="tsr.ceoNameKana" type="text"/>
                            <TSREditableItem :shared="true" ref="inputCeoBirth" label="生年月日：" v-model="input.ceoBirthDate" :tsrValue="tsr.ceoBirthDate" type="text"/></td>
                        <td>
                            お名前（漢字）：{{tsr.ceoName}}<br/>
                            お名前（カナ）：{{tsr.ceoNameKana}}<br/>
                            生年月日：{{tsr.ceoBirthDate}}</td>
                        <td><b-checkbox @change="request['代表者'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>TSR評点</th>
                        <td>C</td>
                        <td>{{tsr.tsrScore}}</td>
                        <td><b-checkbox/></td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset class="mt-3">
            <legend>会社詳細情報</legend>
            <table>
                <colgroup>
                    <col width="15%"/>
                    <col width="40%"/>
                    <col width="40%"/>
                    <col width="5%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>項目名</th>
                        <th>サプライヤ登録情報</th>
                        <th>TSR情報</th>
                        <th>再依頼</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>設立年月</th>
                        <td :class="{'fail':failures.establishment_date === false}">
                            <TSREditableItem v-model="input.establishmentDate" :tsrValue="tsr.establishmentDate"/></td>
                        <td>{{tsr.establishmentDate}}</td>
                        <td><b-checkbox @change="request['設立年月'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>資本金</th>
                        <td :class="{'fail':failures.capital === false}">
                            <TSREditableItem v-model="input.capital" :tsrValue="tsr.capital" type="currency" viewFilter="formatCapitalCurrencyView"/></td>
                        <td>{{tsr.capital | formatCapitalCurrencyView}}</td>
                        <td><b-checkbox @change="request['資本金'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>従業員数</th>
                        <td :class="{'fail':failures.employees_number === false}">
                            <TSREditableItem v-model="input.employeesNumber" :tsrValue="tsr.employeesNumber" type="text" viewFilter="formatHumans"/></td>
                        <td>{{tsr.employeesNumber | formatCurrency}}名</td>
                        <td><b-checkbox @change="request['従業員数'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>主要販売先</th>
                        <td :class="{'fail':failures.main_sales === false}">
                            <TSREditableItem 
                                v-model="input.mainSales" 
                                :tsrValue="tsr.mainSales && tsr.mainSales.join('\n')" 
                                type="list" 
                                viewFilter="formatList"
                                :getValue="function(){ return this.edited && this.edited.split('\n'); }"
                                :setValue="function(value){ this.edited = value && value.join('\n'); }"/>
                        </td>
                        <td v-html="tsr.mainSales && tsr.mainSales.join('</br>')"></td>
                        <td><b-checkbox @change="request['主要販売先'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>主な仕入先</th>
                        <td :class="{'fail':failures.main_suppliers === false}">
                            <TSREditableItem 
                                v-model="input.mainSuppliers" 
                                :tsrValue="tsr.mainSuppliers && tsr.mainSuppliers.join('\n')" 
                                type="list" 
                                viewFilter="formatList"
                                :getValue="function(){ return this.edited && this.edited.split('\n'); }"
                                :setValue="function(value){ this.edited = value && value.join('\n'); }"/>
                        </td>
                        <td v-html="tsr.mainSuppliers && tsr.mainSuppliers.join('</br>')"></td>
                        <td><b-checkbox @change="request['主な仕入先'] = $event"/></td>    
                    </tr>
                    <tr>
                        <th>主な株主</th>
                        <td :class="{'fail':failures.shareholders === false}">
                            <TSREditableItem 
                                v-model="input.shareholders" 
                                :tsrValue="tsr.shareholders && tsr.shareholders.join('\n')" 
                                type="list" 
                                viewFilter="formatList"
                                :getValue="function(){ return this.edited && this.edited.split('\n'); }"
                                :setValue="function(value){ this.edited = value && value.join('\n'); }"/>
                        </td>
                        <td v-html="tsr.shareholders && tsr.shareholders.join('</br>')"></td>
                        <td><b-checkbox @change="request['主な株主'] = $event"/></td>
                    </tr>
                    <tr>
                        <th>取引銀行</th>
                        <td :class="{'fail':failures.banks === false}">
                            <TSREditableItem 
                                v-model="input.banks" 
                                :tsrValue="tsr.banks && tsr.banks.map((b, i) => { return i % 2 === 0 ? ('[' + b + ']') : b + '\n'}).join('')" 
                                type="list" 
                                viewFilter="formatList"
                                :getValue="function(){ return this.edited && this.edited.split('\n'); }"
                                :setValue="function(value){ this.edited = value && value.join('\n'); }"/>
                        </td>
                        <td v-html="tsr.banks && tsr.banks.map((b, i) => { return i % 2 === 0 ? ('[' + b + ']') : b + '<br/>'}).join('')"></td>
                        <td><b-checkbox @change="request['取引銀行'] = $event"/></td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset class="mt-3">
            <legend>決算情報</legend>
            <table class="small">
                <colgroup>
                    <col width="5%"/>
                    <col width="10%"/>
                    <col width="10%"/>
                    <col width="13%"/>
                    <col width="13%"/>
                    <col width="13%"/>
                    <col width="13%"/>
                    <col width="13%"/>
                    <col width="14%"/>
                    <col width="4%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th rowspan="2">操作</th>
                        <th colspan="2">決算年月</th>
                        <th colspan="2">売上</th>
                        <th colspan="2">税引前利益</th>
                        <th colspan="2">税引後利益</th>
                        <th rowspan="2">再依頼</th>
                    </tr>
                    <tr>
                        <th>入力</th>
                        <th>TSR</th>
                        <th>入力</th>
                        <th>TSR</th>
                        <th>入力</th>
                        <th>TSR</th>
                        <th>入力</th>
                        <th>TSR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(perfomance, i) in performances" v-bind:key="i">
                        <td><TSREditableItem 
                                v-on:action="
                                    $refs['pClosingDate_' + i][0].runAction($event);
                                    $refs['pSales_' + i][0].runAction($event);
                                    $refs['pProfit_' + i][0].runAction($event);
                                    $refs['pProfitAfterTax_' + i][0].runAction($event)" 
                                :controller="true"
                                type="text"/></td>
                        <td :class="{'fail':failures.performance === false}">
                            <TSREditableItem 
                                :ref="'pClosingDate_' + i"
                                v-model="perfomance.closingDate" 
                                :tsrValue="perfomance.tsr && perfomance.tsr.closingDate" 
                                :shared="true"
                                :noControls="true"
                                type="text"/>
                        </td>
                        <td>{{perfomance.tsr ? perfomance.tsr.closingDate : '-'}}</td>
                        <td :class="{'fail':failures.performance === false}">
                            <TSREditableItem 
                                :shared="true" :noControls="true"
                                :ref="'pSales_' + i"
                                v-model="perfomance.sales" 
                                :tsrValue="perfomance.tsr && perfomance.tsr.sales"
                                viewFilter="formatCapitalCurrencyView"
                                type="currency"/>
                        </td>
                        <td>{{perfomance.tsr && perfomance.tsr.sales | formatCapitalCurrencyView}}</td>
                        <td :class="{'fail':failures.performance === false}">
                            <TSREditableItem 
                                :shared="true" :noControls="true"
                                :ref="'pProfit_' + i"
                                v-model="perfomance.profit" 
                                :tsrValue="perfomance.tsr && perfomance.tsr.profit"
                                viewFilter="formatCapitalCurrencyView"
                                type="currency"/>
                        </td>
                        <td>{{perfomance.tsr && perfomance.tsr.profit | formatCapitalCurrencyView}}</td>
                        <td :class="{'fail':failures.performance === false}">
                            <TSREditableItem 
                                :shared="true" :noControls="true"
                                :ref="'pProfitAfterTax_' + i"
                                v-model="perfomance.profitAfterTax" 
                                :tsrValue="perfomance.tsr && perfomance.tsr.profitAfterTax"
                                viewFilter="formatCapitalCurrencyView"
                                type="currency"/>
                        </td>
                        <td>{{perfomance.tsr && perfomance.tsr.profitAfterTax | formatCapitalCurrencyView}}</td>
                        <td><b-checkbox @change="request['決算情報(' + perfomance.closingDate + ')'] = $event"/></td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</template>
<script>
import TSREditableItem from './TSREditableItem';




export default {
    components: {TSREditableItem},
    props: ["input", "tsr", "request"],
    data: function(){
        return {
            changeStatus:{},
            performances:[]
        }
    },
    computed: {
        failures: function(){

            if(this.tsr.failedReason)
                return JSON.parse(this.tsr.failedReason)
            else
                return {}
        }
    },
    watch: {
        input: function(){
            this.load();
        },
        tsr: function(){
            this.load();
        }
    },
    methods:{
        onTsrAllow(){
            this.$emit('tsrAllow', this.changeStatus);
        },
        runBatch(){

        },
        load(){
            if(this.tsr.performances && this.input.performances){
                let performances = this.input.performances;
                this.tsr.performances.forEach(p => {
                    let found = performances.find(tp => tp.closingDate && tp.closingDate.slice(0, -3) === p.closingDate.slice(0, -3));
                    if(found){
                        found.tsr = p;
                    }else{
                        performances.push({
                            closingDate: p.closingDate,
                            tsr: p
                        });
                    }
                });
                this.performances = performances;
            }
            
        }
    },
    filters: {
        industryFilter(value){
            if(!value) return '≪未登録≫';
            return value.map(i => i.split(',')).map(i => '[' + i[4] + ']' + i[5]).join('\n');
        }
    }
}
</script>
<style scoped lang="scss">
    table{
        width: 100%;
        table-layout: fixed;
    }
    table tr td, table tr th{
        border: 1px solid #000;
        padding: 0.5rem 0.5rem;
    }
    table thead th{
        background: #FCE4D6;
    }
    table tbody th{
        background: #fdf4ee;
    }
    table td { background: #FFF; }
    table .fail{ 
        color: $red;
    }
    table tr td:nth-child(4){
        text-align: center;
    }
</style>