<template>
  <div class="performance">
    <div class="performance__sub-title">
      <h1>取引実績<span class="performance__sub-title_supplement">(検収済みデータで集計)</span></h1>
    </div>
    <div class="performance__contents">
      <div class="performance__contents_container performance__contents_container_title">
        <div class="performance__contents_container_item performance__contents_container_item--title"></div>
        <div class="performance__contents_container_item performance__contents_container_item--count_title">件数</div>
        <div class="performance__contents_container_item performance__contents_container_item--count">金額</div>
      </div>
      <div class="performance__contents_container performance__contents_container_last_year">
        <div class="performance__contents_container_item performance__contents_container_item--title">過去1年間</div>
        <div class="performance__contents_container_item performance__contents_container_item--count_title">1,800件</div>
        <div class="performance__contents_container_item performance__contents_container_item--count">8,000,000円</div>
      </div>
      <div class="performance__contents_container">
        <div class="performance__contents_container_item performance__contents_container_item--title">open</div>
        <div class="performance__contents_container_item performance__contents_container_item--count_title">800件</div>
        <div class="performance__contents_container_item performance__contents_container_item--count">1,000,000円</div>
      </div>
      <div class="performance__contents_container">
        <div class="performance__contents_container_item performance__contents_container_item--title">既存</div>
        <div class="performance__contents_container_item performance__contents_container_item--count_title">1,000件</div>
        <div class="performance__contents_container_item performance__contents_container_item--count">7,000,000円</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .performance {
    border-top: 1px solid $very-light-gray;
    margin-top: 20px;
  }
  .performance__sub-title {
    margin-top: 20px;
    font-weight: bold;
  }
  .performance__sub-title_supplement{
    font-size: 12px;
  }
  .performance__contents {
    flex-direction: column;
    background: $light-cyan-blue;
    padding: 20px;
  }
  .performance__contents_container {
    display:flex;
    justify-content: flex-start;
  }
  .performance__contents_container_title {
    font-weight: bold;
  }
  .performance__contents_container_last_year {
    font-weight: bold;
  }
  .performance__contents_container_item {
    color: $black;
    &--title {
      flex-basis:20%;
    }
    &--supplement {
      flex-basis:20%;
    }
    &--count_title {
      flex-basis:10%;
    }
    &--count {
      flex-basis:10%;
    }
  }
</style>