<template>
    <div>
        <CompanySimpleHeader :companyCode="this.$route.params.supplierCode" title="サプライヤ審査"/>
        <PageTitle title="サプライヤ審査"></PageTitle>
        <!-- <div class="px-4 py-2">
            <fieldset>
                <legend>システム情報</legend>
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="80%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>再入力依頼日時</th>
                            <td>再入力依頼されていません。</td></tr>
                        
                    </tbody>
                </table>
            </fieldset>
        </div> -->
        <div class="px-4 py-2">
            <div class="examination-detail bg-light-blue p-4">
                <h4>TSR比較表</h4>
                <TSR :input="tsrForm.current" :tsr="tsrForm.tsr" :request="tsrForm.request" @tsrAllow="onTsrAllow"/>
            </div>
        </div>
        <div class="px-4 py-2">
            <div class="examination-detail bg-light-blue p-4">
                <h4>RPA結果</h4>
                <RPA :input="rpaForm" @refresh="load"/>
            </div>
        </div>
        <div class="px-4 py-2">
            <div class="examination-detail bg-light-blue p-4">
                <h4>反社チェック</h4>
                <fieldset class="mt-3">
                    <AntiSociety/>
                </fieldset>
            </div>
        </div>
        <div class="px-4 py-2">
            <div class="bg-light-blue p-4">
                <p>サプライヤ宛に送信される再入力依頼メールにコメントを追加する事ができます</p>
                <div class="row">
                    <div class="col">
                        <b-button variant="light" @click="onOpenRequestModal();">再入力依頼</b-button>
                    </div>
                    <div class="col text-center">
                        <b-button variant="success" size="lg" @click="$refs['examination-modal'].show();">サプライヤ審査を完了とする</b-button>
                    </div>
                    <div class="col text-right">
                        <b-button variant="danger" @click="runBatch">審査バッチを再実行</b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="re-request-modal" title="再入力お願い" @ok="onReRequest" size="lg">
            <div class="bg-white">
                <b-alert variant="warning" show>サプライヤへ連絡事項メールに書いている通りメールがサプライヤに送信されます。</b-alert>
                <b-form-group label="サプライヤへ連絡事項メール">
                    <p><span class="badge badge-secondary">${companyName}</span> <span class="badge badge-secondary">${userName}</span>様</p>
                    <p>(送信日時：<span class="badge badge-secondary">${sentDate}</span>)</p>
                    <p></p>
                    <p>いつもお世話になっております。</p>
                    <p>登録情報の再入力を下さりありがとうございました。</p>
                    <p>登録内容を確認したところ、再度ご確認頂きたい項目がございました。</p>
                    <p>恐れ入りますが、下記項目をご確認の上ご入力頂けます様お願い致します。</p>
                    <p></p>
                    <p>---------------------------------------</p>
                    <p>ログインURL<br/>
                    <span class="badge badge-secondary">${LoginURL}</span>
                    </p>
                    <p>---------------------------------------</p>
                    
                    <b-textarea v-model="reRequestForm.reRequestReason" style="height: 250px;"></b-textarea>
                    <p class="mt-3">---------------------------------------</p>
                    <p>本メールはシステムより発信されています。本メールに返信しないで下さい。。</p>
                    <p>---------------------------------------</p>
                </b-form-group>
            </div>
        </b-modal>
        <b-modal ref="examination-modal" title="このサプライヤを審査完了にしますか？" @ok="onExamFinish">
            このサプライヤを審査完了にしますか？
        </b-modal>
    </div>
    
</template>

<script>
import TSR from '@/components/organism/examination/TSR.vue';
import RPA from '@/components/organism/examination/RPA.vue';
import PageTitle from "@/components/atoms/PageTitle.vue";
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';
import AntiSociety from '@/components/organism/examination/ANTISOCIETY.vue';

import SupplierVerification from '@/model/check/SupplierVerification.js';
import SupplierTsr from '@/model/check/SupplierTsr';



export default {
    components: { TSR, RPA, PageTitle, CompanySimpleHeader, AntiSociety },
    data: function(){
        return {
            tsrForm: {
                current: {},
                tsr:{}
            },
            rpaForm: {
                company: {},
                licenses: [],
                certificates: [],
                patents: []
            },
            reRequestForm: {
                reRequestReason: ""
            },
            examinationForm: { }
        }
    },
    created: function(){
        this.load();
    },
    methods: {
        load(){
            Promise.all([
                SupplierTsr.search(this.$route.params.supplierCode),
                SupplierVerification.getVerification(this.$route.params.supplierCode)
            ]).then(results => {
                let tsrData = results[0].data;
                tsrData.request = {};
                this.tsrForm = tsrData;
                this.rpaForm = results[1].data;
            }).catch(reason => this.$errorMessage(reason));
        },
        onReRequest(){
            SupplierVerification.reRequestInput(this.$route.params.supplierCode, {
                remarks: this.reRequestForm.reRequestReason
            }).then(() => {
                this.$successMessage("再入力依頼しました。");
                this.load();
            }).catch(reason => this.$errorMessage(reason));
        },
        onExamFinish(){
            SupplierVerification.finishExam(this.$route.params.supplierCode, this.tsrForm).then(() => {
                this.$successMessage("審査を完了にしました。");
                this.load();
                this.$router.push({name:'SupplierDetail', params: { supplierCode: this.$route.params.supplierCode } })
            }).catch(reason => this.$errorMessage(reason));
        },
        runBatch(){
            if(confirm('審査バッチを再実行しますか？')){
                SupplierVerification.rerunBatch(this.$route.params.supplierCode).then(() => {
                    this.$successMessage("審査完了のメール通知をお待ちください。", "審査バッチを再実行依頼しました。");
                    this.load();
                    this.$router.push({name:'SupplierDetail', params: { supplierCode: this.$route.params.supplierCode } })
                }).catch(reason => this.$errorMessage(reason));
            }
        },

        onOpenRequestModal(){
           let message = '';
           this.reRequestForm.reRequestReason = "";
           for(const key in this.tsrForm.request){
               if(this.tsrForm.request[key]) message += key + ' : 再入力お願いします。 \n'
           }
           this.$set(this.reRequestForm, 'reRequestReason', message);
           this.$refs['re-request-modal'].show();
        },

        onTsrAllow(changeStatus){
            SupplierTsr.change(this.$route.params.supplierCode, changeStatus).then( () =>{
                this.$successMessage("TSRの判断を変更いたしました。");
                this.load();
            }).catch(reason => this.$errorMessage(reason));
        }
    }
}
</script>

<style>
    
</style>