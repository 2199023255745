export default class {
  list = [
    { text: '会員', value: true },
    { text: '非会員', value: false }
  ]

  findTextBy(value) {
    return this.list.filter(status => status.value == value)[0].text
  }

  static create() {
    return new this()
  }
}