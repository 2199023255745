<template>
  <div class="page">
    <div class="container">
      <h1>404 - Page not found</h1>
    </div>
  </div>
</template>

<script>
export default {
  components: {  },
  data: () => ({
    form: {}
  }),
  methods:{
    onSubmit(event){
        event.preventDefault();
        console.log(this.$data.form);
        // TODO: LOGIN LOGIC HERE
        this.$store.commit("login", {
          accessToken: "access_token",
          user: {
            role: {}
          }
        });
        this.$router.push({
          path: "/dashboard",
          params: {}
        });
      },
  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 1em;
}

</style>