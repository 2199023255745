<template>
  <div class="buyer">
    <div class="buyer__sub-title">
      <h1>バイヤ</h1>
    </div>
    <div class="buyer__contents">
      <div>企業グループ： 15グループ</div>
      <div>会社： 250社</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

  .buyer {
    border-top: 1px solid $very-light-gray;
    margin-top: 20px;
  }
  .buyer__sub-title {
    margin-top: 20px;
  }

  .buyer__contents{
    display: flex;
    background: $light-cyan-blue;
    flex-direction: column;
    justify-content: stretch;
    padding: 20px;
  }
</style>