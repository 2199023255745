import Model from '@/model/_base';

export default class SupplierVerification extends Model {
    static entity = 'verification';

    constructor(data) {
        super(SupplierVerification.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getVerification(supplierCode, query){
        return Model.axios.get(this.url('/supplier/' + supplierCode), query);
    }

    static reentryRequest(supplierCode){
        return Model.axios.post(this.url('/reentryRequest/supplier/' + supplierCode));
    }

    static change(supplierCode, data) {
        return Model.axios.post(this.url('/change/supplier/' + supplierCode), data);
    }

    static downloadEvidence(fileCode){
        return Model.axios.get(this.absolute('api/rpa/evidence?fileCode='+fileCode), { responseType: 'blob' });
    }    

    static finishExam(supplierCode, data){
        return Model.axios.post(this.url('/' + supplierCode + '/complete-examination'), data);
    }

    static reRequestInput(supplierCode, data){
        return Model.axios.post(this.url('/' + supplierCode + '/re-examination-request'), data);
    }

    static rerunBatch(supplierCode){
        return Model.axios.post(this.url('/' + supplierCode + '/restart-batch'));
    }
}