<template>
  <div class="registration__flow">
    <ul>
      <li :class="{active:true, done:status.isMainUserVerified}">
        {{status.isMainUserVerified?'メールアドレス確認済み':'メールアドレス確認待ち'}}
      </li>
      <li :class="{active:true, done:status.isAccountCreated}">
        {{status.isAccountCreated?'入会申込待ち(Ａ情報登録済み)':'入会申込待ち(Ａ情報登録待ち)'}}
      </li>
      <li :class="{active:status.isAccountCreated, done:status.isRegistrationCompleted}">
        {{status.isRegistrationCompleted?'入会申込完了(Ｂ情報登録済み)':'入会申込中(Ｂ情報登録待ち)'}}
      </li>
      <li :class="{active:status.isRegistrationCompleted, done:status.isExaminationCompleted}">
        {{status.isExaminationCompleted?'審査完了':'審査待ち'}}
      </li>
      <template v-if="isOpenSupplier">
        <li :class="{active:status.isExaminationCompleted, done:status.isAddressVerified}">
          {{status.isAddressVerified?'口座振替依頼書送付済':'口座振替依頼書送付待ち'}}
        </li>
        <li :class="{active:status.addressVerified, done:status.isBankTransferAccountRegistered}">
          {{status.isBankTransferAccountRegistered?'振替口座登録済':'振替口座未登録'}}
        </li>
      </template>
      <li :class="{active:status.isBankTransferAccountRegistered, done:status.isContractSigned}">
        {{status.isContractSigned?'契約署名済':'契約署名待ち'}}
      </li>
      <li v-if="!status.isNgCompleted" :class="{active:status.isContractSigned, done:status.isCompleted}">
        <template v-if="isOpenSupplier">
          {{status.isCompleted?'口座振替手続き完了':'口座振替手続き待ち'}}
        </template>
        <template v-else>
          {{status.isCompleted?'取引中':'取引開始待ち'}}
        </template>
      </li>
      <li v-if="status.isNgCompleted" :class="{active:false, fail:status.isNgCompleted}">
        ※手続きNG
      </li>
    </ul>   
  </div>
</template>

<script>
export default {
  props: ['status', 'isOpenSupplier'],
}
</script>

<style lang="scss">
  .registration__flow{
    ul{
      list-style: none;
      li {
        border-bottom: 1px dashed #d3d3d3;
        &:before{
          content: "\2022";
          color: #d3d3d3;
          font-weight: bold;
          font-size: 2rem;
          line-height: 1rem;
          vertical-align: middle;
          display: inline-block;
          width: 1em; 
          margin-left: -1em;
        }
        &.active:before{
          color: $green; 
        }
        &.done:before{
          color: $blue;
        }
        &.fail:before {
          color: $red;
        }
      }
    }
  }
</style>