<template>
  <div>
    <CompanySimpleHeader :companyCode="this.$route.params.supplierCode" title="サプライヤー情報"/>
    <PageTitle title="サプライヤー情報"></PageTitle>
    <div class="px-4 py-2">
      <RegistrationStatusFlow :status="registrationStatus" />
      <CompanyDetailHeader :company="company.basic"/>
      <div class="mt-2 p-3 bg-light-blue">
        <h5 class="mb-0" v-b-toggle.company-summary>
          会社詳細情報
          <font-awesome-icon class="float-right" icon="sort-down"/>
        </h5>
        <b-collapse id="company-summary" class="p-2 mt-2">  
          <table class="table border-table">
            <tbody>
              <tr>
                <th class="background-body">設立年月日</th>
                <td>{{company.summary.establishmentDateYear + '年' + company.summary.establishmentDateMonth + '月'}}</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>{{company.summary.capital | numberFormat}}円</td>
              </tr>
              <tr>
                <th class="background-body">従業員数</th>
                <td>{{company.summary.employeesNumber | numberFormat}}人</td>
              </tr>
              <tr>
                <th class="background-body">取引通貨</th>
                <td>«未登録»</td>
              </tr>
              <tr>
                <th class="background-body">業種</th>
                <td>
                  <p v-for="(industry, index) in company.industries" :key="index">
                    <span>{{industry.topIndustryName}}</span><br/>
                    <span class="ml-2">➞{{industry.midIndustryName}}</span><br/>
                    <span class="ml-4">➞{{industry.lowIndustryName}}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <th class="background-body">工場</th>
                <td>
                  <p v-if="!company.factories || company.factories.length <= 0">«未登録»</p>
                  <p v-for="(factory, index) in company.factories" :key="index">
                    <b class="mr-2">{{factory.name}}</b>{{factory.address}}
                  </p>
                </td>
              </tr>
              <tr>
                <th class="background-body">事業所（本社、工場以外）</th>
                <td>
                  <p v-if="!company.offices || company.offices.length <= 0">«未登録»</p>
                  <p v-for="(office, index) in company.offices" :key="index">
                    <b class="mr-2">{{office.name}}</b>{{office.address}}
                  </p>
                </td>
              </tr>
              <tr>
                <th class="background-body">主要販売先</th>
                <td>
                  <p v-if="!company.sellers || company.sellers.length <= 0">«未登録»</p>
                  <p v-for="(seller, index) in company.sellers" :key="index">{{seller.name}}</p>
                </td>
              </tr>
              <tr>
                <th class="background-body">主な仕入先</th>
                <td>
                  <p v-if="!company.suppliers || company.suppliers.length <= 0">«未登録»</p>
                  <p v-for="(supplier, index) in company.suppliers" :key="index">{{supplier.name}}</p>
                </td>
              </tr>
              <tr>
                <th class="background-body">主な株主</th>
                <td>
                  <p v-if="!company.shareholders || company.shareholders.length <= 0">«未登録»</p>
                  <p v-for="(shareholder, index) in company.shareholders" :key="index">{{shareholder.name}}</p>
                </td>
              </tr>
              <tr>
                <th class="background-body">取引銀行</th>
                <td>
                  <p v-if="!company.banks || company.banks.length <= 0">«未登録»</p>
                  <p v-for="(bank, index) in company.banks" :key="index">{{bank.name}}</p>
                </td>
              </tr>
              <tr>
                <th class="background-body">取り扱いメーカー</th>
                <td>
                  <p v-if="!company.makers || company.makers.length <= 0">«未登録»</p>
                  <p v-for="(maker, index) in company.makers" :key="index">{{maker.name}}</p>
                </td>
              </tr>
              <tr>
                <th class="background-body">決算情報</th>
                <td>
                  <p v-if="!company.financialInfos || company.financialInfos.length <= 0">«未登録»</p>
                  <table v-if="company.financialInfos">
                    <thead>
                      <tr>
                        <th>決算年月</th>
                        <th>売上</th>
                        <th>経常利益</th>
                        <th>税引後利益</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(financialInfo, index) in company.financialInfos" :key="index">
                        <td>{{financialInfo.year + '年' + financialInfo.month + '月'}}</td>
                        <td>{{financialInfo.sales | numberFormat}}円</td>
                        <td>{{financialInfo.ordinaryProfit | numberFormat}}円</td>
                        <td>{{financialInfo.profitAfterTax | numberFormat}}円</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th class="background-body">納品担当地域</th>
                <td v-if="detail.companyArea">
                  {{
                  detail.companyArea.map(i=>i.areaName).join(', ')
                  }}
                </td>
              </tr>
              <tr>
                <th class="background-body">取扱い品目カテゴリ</th>
                <td>{{detail.supplierParrentCategory}}</td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </div>
      <div class="mt-2 p-3 bg-light-blue">
        <h5 class="mb-0" @click="onPreview">
          PR情報
          <font-awesome-icon class="float-right" icon="external-link-alt"/>
        </h5>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
import SupplierDetail from "@/model/SupplierDetail";
import SupplierRegistrationStatus from "@/model/SupplierRegistrationStatus";

import RegistrationStatusFlow from '@/components/organism/RegistrationStatusFlow'
import PageTitle from "@/components/atoms/PageTitle.vue";

import CompanyDetailHeader from '@/components/organism/CompanyDetailHeader.vue';
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';

export default {
  components: { PageTitle,  RegistrationStatusFlow, CompanySimpleHeader, CompanyDetailHeader },
  props: {},
  data: function(){
    return {
      detail: {},
      registrationStatus: {},
      company: {
        basic: {},
        summary: {},
        banks: [],
        factories: [],
        financialInfos: [],
        industries: [],
        manufacturers: [],
        offices: [],
        sellers: [],
        shareholders: [],
        suppliers: []
      }
    }
  },
  mounted() {
    this.getDetail()
    this.getRegistrationStatus()
  },
  computed: {
    address() {
      return this.detail.address1 + (this.detail.address2 ? this.detail.address2 : '')
    }
  },
  methods: {
    getDetail() {
      SupplierDetail.get(this.$route.params.supplierCode).then(result => {
        this.company = result.data;
      }).catch(reason => this.$errorMessage(reason));
    },
    getRegistrationStatus() {
      Promise.all([
        SupplierRegistrationStatus.get(this.$route.params.supplierCode),
      ]).then((results) => {
        if (!results[0].data.length) this.registrationStatus = {}
        this.registrationStatus = results[0].data.registrationStatus
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    onPreview(){
      
    }
  }
}
</script>