<template>
  <div class="registration">
    <div class="registration__flow">
      
      <FlowItem :active="status.isAccountCreated" :done="status.isAccountCreated">
        <template v-slot:description>
          {{status.isAccountCreated?'入会申込待ち(Ａ情報登録済み)':'入会申込待ち(Ａ情報登録待ち)'}}
        </template>
      </FlowItem>
      
      <div class="registration__divider"></div>
      
      <FlowItem :active="status.isAccountCreated" :done="status.isRegistrationCompleted">
        <template v-slot:description>
          {{status.isRegistrationCompleted?'入会申込完了(Ｂ情報登録済み)':'入会申込中(Ｂ情報登録待ち)'}}
        </template>
      </FlowItem>

      <div class="registration__divider"></div>

      <FlowItem :active="status.isRegistrationCompleted" :done="status.isExaminationCompleted">
        <template v-slot:description>
          {{status.isExaminationCompleted?'審査完了':'審査待ち'}}
        </template>
      </FlowItem>

      <div class="registration__divider"></div>

      <template v-if="isOpenSupplier">

        <FlowItem :active="status.addressVerified" :done="status.addressVerified">
          <template v-slot:description>
            口座振替依頼書送付済
          </template>
        </FlowItem>
        
        <div class="registration__divider"></div>
        
        <FlowItem :active="status.bankTransferAccountRegistered" :done="status.bankTransferAccountRegistered"> 
          <template v-slot:description>
            振替口座登録済
          </template>
        </FlowItem>
        
        <div class="registration__divider"></div>
        
      </template>

      <FlowItem :active="status.contractSigned" :done="status.contractSigned">
        <template v-slot:description>
          契約署名済
        </template>
      </FlowItem>
      
      <div class="registration__divider"></div>
      
      <FlowItem :active="status.completed" :done="status.completed">
        <template v-slot:description>
          口座振替手続き完了
          <span v-if="status.ngCompleted" class="registration__ng">※手続きNG</span>
        </template>
      </FlowItem>
      
    </div>
  </div>
</template>

<script>
import FlowItem from '@/components/molecule/FlowItem.vue'

export default {
  props: ['status', 'isOpenSupplier'],
  components: { FlowItem }
}
</script>

<style scoped lang="scss">
  .registration {
    &__flow {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-left: 25px;
      padding-right: 25px;
    }

    &__divider {
      border-bottom: 4px solid $light-gray;
      flex-grow: 1; 
    }

    &__ng {
      color: crimson;
    }
    
  }
</style>