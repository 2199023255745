<template>
    <div class="rpa-detail">
        <fieldset>
            <legend>許認可情報</legend>
            <div class="rpa-row">
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold">
                    許認可
                </div>
                <div style="flex:1">
                    <table class="bg-white w-100 rpa-item-table">
                        <tr>
                        <th style="background: #fce4d6;">項目</th>
                        <th style="background: #fce4d6;">サプライヤ登録情報</th>
                        <th style="background: #fce4d6;">検索結果
                            <a 
                            class="text-muted"
                            style="float:right;"
                            target="_blank" 
                            v-b-tooltip.hover 
                            title="RPAエビデンスを表示"
                            :href="apiUrl + 'verification/' + input.company.companyCode">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                            </a>
                        </th>
                        </tr>
                    </table>
                </div>
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold; width: 64px; border-right: 2px solid #000;">
                    再依頼
                </div>
            </div>
            <div v-if="input.licenses.length > 0">
                <RPAItem v-for="license in input.licenses" :key="license.id" :result="license" :company="input.company" @refresh="onRefresh"/>
            </div>
            <div v-else>
                <p>許認可情報が登録されてません。</p>
            </div>
        </fieldset>
        <fieldset class="mt-3">
            <legend>認証情報</legend>
            <div class="rpa-row">
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold">
                    許認可
                </div>
                <div style="flex:1">
                    <table class="bg-white w-100 rpa-item-table">
                        <tr>
                            <th style="background: #fce4d6;">項目</th>
                            <th style="background: #fce4d6;">サプライヤ登録情報</th>
                            <th style="background: #fce4d6;">検索結果
                                <a 
                                class="text-muted"
                                style="float:right;"
                                target="_blank" 
                                v-b-tooltip.hover 
                                title="RPAエビデンスを表示"
                                :href="apiUrl + 'verification/' + input.company.companyCode">
                                <font-awesome-icon icon="eye"></font-awesome-icon>
                                </a>
                            </th>
                        </tr>
                    </table>
                </div>
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold; width: 64px; border-right: 2px solid #000;">
                    再依頼
                </div>
            </div>
            <div v-if="input.certificates.length > 0">
                <RPAItem v-for="license in input.certificates" :key="license.id" :result="license" :company="input.company" @refresh="onRefresh"/>
            </div>
            <div v-else>
                <p>認証情報が登録されてません。</p>
            </div>
        </fieldset>
        <fieldset class="mt-3">
            <legend>特許情報</legend>
            <div class="rpa-row">
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold">
                    許認可
                </div>
                <div style="flex:1">
                    <table class="bg-white w-100 rpa-item-table">
                        <tr>
                        <th style="background: #fce4d6;">項目</th>
                        <th style="background: #fce4d6;">サプライヤ登録情報</th>
                        <th style="background: #fce4d6;">検索結果
                            <a 
                            class="text-muted"
                            style="float:right;"
                            target="_blank" 
                            v-b-tooltip.hover 
                            title="RPAエビデンスを表示"
                            :href="apiUrl + 'verification/' + input.company.companyCode">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                            </a>
                        </th>
                        </tr>
                    </table>
                </div>
                <div class="rpa-header" style="background: #fce4d6; font-weight:bold; width: 64px; border-right: 2px solid #000;">
                    再依頼
                </div>
            </div>
            <div v-if="input.certificates.length > 0">
                <RPAItem v-for="license in input.patents" :key="license.id" :result="license" :company="input.company" @refresh="onRefresh"/>
            </div>
            <div v-else>
                特許情報が登録されてません。
            </div>
        </fieldset>
    </div>
</template>
<script>

import RPAItem from '@/components/molecule/rpa/RPAItem.vue';
export default {
    components: { RPAItem },
    props: ["input"],
    computed: {
            
        apiUrl(){
        return process.env.VUE_APP_API_WORKER_URL;
        },
    },
    methods: {
        onRefresh(){
            this.$emit('refresh');
        }
    }

}
</script>
<style>
    .rpa-table{
        width: 100%;
        table-layout: fixed;
    }
    .rpa-table td, .rpa-table th{
        border: 1px solid #000;
        background: #fff;
        padding: 0.5rem 1rem;
    }
    .rpa-table tr > td:nth-child(1) { background: #FCE4D6; }
    .rpa-table tr > td:nth-child(2) {  }
    .rpa-table tr > th { background: #FCE4D6; }
    
</style>