<template>
  <div>
    <PageTitle title="共通取引基本約款 一覧"></PageTitle> 
    <div class="term">
      <div class="mt-3">
        <router-link :to="{ name: 'TermAdd' }">
          <b-button variant="primary" size="md">
            <font-awesome-icon :icon="['far', 'arrow-alt-circle-right']"></font-awesome-icon>
            新規追加
          </b-button>
        </router-link>
      </div>
      <table class="term-list table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="id text-center">ID</th>
            <th class="name text-center">約款名</th>
            <th class="buttons text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="termList.length === 0">
            <td class="text-left" colspan="4">
              <LoadingList :loaded="loadedList" :list="termList" emptyData="現在データはありません。"></LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in termList" :key="`${item[dataType.detailKey]}${index}`">
            <td class="text-center">{{item.id}}</td>
            <td><router-link :to="{ name: 'TermEdit',params :{ id: item.id} }">{{item.name}}</router-link></td>
            <td class="text-center">
              <b-button class="mr-3" variant="success" size="sm" v-on:click="downloadFile(item)">
              <font-awesome-icon :icon="['far', 'arrow-alt-circle-right']"></font-awesome-icon>
              ダウンロード
              </b-button>
              <b-button variant="danger" size="sm" v-on:click="deleteTerm(item.id,index)">
              <font-awesome-icon :icon="['far', 'arrow-alt-circle-right']"></font-awesome-icon>
              削除
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getTermList"></pagination>
    </div>
  </div>
</template>

<style scoped lang="scss">
.term{
  padding:20px;
}
.term-list{
  .id{
    width:70px;
  }
  .buttons{
    width:380px;
  }
}
</style>

<script>
import Term from "@/model/Term";
import Pagination from "@/components/molecule/Pagination.vue";
import LoadingList from "@/components/atoms/LoadingList.vue";
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { Pagination, LoadingList,PageTitle },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: true,
      dataType: {},
      termList: [],
      query: ""
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.show()
  },
  methods: {    
    show() {
      this.getTermList()
    },
    getTermList() {
      Promise.all([
        Term.getList(),
      ]).then((results) => {
        if (!results[0].data.length) this.termList = {}
        this.termList = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    downloadFile(item) {
      Term.download(item.id).then(result => {
        const blob = new Blob([result.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.name + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(reason => this.$errorMessage(reason));
    },
    deleteTerm(id,index) {      
      this.$swal.confirm("約款を削除します。よろしいですか？", undefined, () => {
        Promise.all([
          Term.delete(id),
        ]).then(
          this.termList.splice(index,1)
        )
      })
    },
  }
}
</script>