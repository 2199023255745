import Vue from 'vue'
import moment from 'moment'

Vue.filter('toMonth', function (date) {
  return moment(date).format('YYYY/MM')
})
Vue.filter('toDay', function (date) {
  return moment(date).format('YYYY/MM/DD')
})
Object.defineProperty(Vue.prototype, "$moment", {
  value: moment
});
