<template>
  <div class="pb-5">
    <PageTitle title="アンケート">
      <router-link to="/system/safety/questionnaire" class="btn btn-link border ml-3">アンケート一覧へ戻る</router-link>
    </PageTitle>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <!-- basic info for questionnaire -->
      <fieldset>
        <legend style="font-size: 1.1rem;">アンケート基本情報</legend>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">アンケート名</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" v-model="questionnaire.name" placeholder="アンケート名を入力してください" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">通知方法</label>
          <div class="col-sm-3">
            <b-checkbox v-model="questionnaire.contact_method.email" class="d-inline">メール</b-checkbox>
            <b-checkbox v-model="questionnaire.contact_method.sms" class="ml-3 d-inline">SMS</b-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">応答方法</label>
          <div class="col-sm-3">
            <b-checkbox v-model="questionnaire.respond_method.form" class="d-inline">フォーム</b-checkbox>
            <b-checkbox v-model="questionnaire.respond_method.tel" class="ml-3 d-inline">電話</b-checkbox>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">送信設定</label>
          <div class="col-sm-3">
            <select class="form-control" v-model="questionnaire.type">
              <option value="0">選択してください</option>
              <option value="1">手動</option>
              <option value="2">スケージュール</option>
              <option value="3">エベント</option>
            </select>
          </div>
        </div>
        <div class="form-group row" v-if="questionnaire.type === '2'">
          <label class="col-sm-2 col-form-label">スケージュール</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" v-model="questionnaire.schedule" placeholder="スケージュールをCRONフォーマット通り入力してください。" />
          </div>
        </div>
        <div class="form-group row" v-if="questionnaire.type === '3'">
          <label class="col-sm-2 col-form-label">エベント</label>
          <div class="col-sm-4">
            <select class="form-control" v-model="questionnaire.event">
              <option value="0">選択してください</option>
              <option value="1">地震（SECOMリールタイム被災情報）</option>
              <option value="2">被災（SECOMリールタイム被災情報）</option>
              <option value="3">津波（SECOMリールタイム被災情報）</option>
            </select>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="mt-4 mx-3 bg-light-blue p-4">
      <div class="row">
        <div class="col col-6">
          <!-- Question list -->
          <fieldset>
            <legend style="font-size: 1.1rem;">アンケート項目一覧</legend>
            <div 
              v-for="(question, index) in questionnaire.questions" :key="question.id"
              :class="{
                'alert': true,
                'alert-hover': true,
                'alert-light': !question.selected, 
                'alert-info': question.selected
              }"
              @click="selectQuestion(question)">
              <b-badge variant="light">{{ index + 1 }}</b-badge>
              <b-badge style="min-width: 60px;" variant="success" class="ml-1">{{questionTypes.find(q => q.value === question.type).text}}</b-badge>
              <b-badge style="min-width: 60px;" :variant="question.required ? 'danger' : 'secondary'" class="ml-1">{{question.required ? '必須' : '任意'}}</b-badge>
              {{question.text}}
            </div>
            <div class="alert alert-light">
              <b-button variant="light" class="btn-block" @click="addQuestion()">項目を追加</b-button>
            </div>
          </fieldset>
                
        </div>
        <div class="col col-6">
          <fieldset v-if="selectedQuestion">
            <legend style="font-size: 1.1rem;">アンケート項目詳細</legend>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">項目名</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" v-model="selectedQuestion.text" placeholder="項目名を入力してください" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">説明書</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" v-model="selectedQuestion.description" placeholder="項目名を入力してください" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">項目タイプ</label>
              <div class="col-sm-8">
                <select class="form-control" v-model="selectedQuestion.type">
                  <option value="0">選択してください</option>
                  <option v-for="type in questionTypes" :value="type.value" :key="type.value">{{type.text}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row" v-if="selectedQuestion.type === 5 || selectedQuestion.type === 6">
              <label class="col-sm-4 col-form-label">選択肢</label>
              <div class="col-sm-8">
                <table>
                  <tr>
                    <td>選択値</td>
                    <td>選択テックスト</td>
                    <td></td>
                  </tr>
                  <tr v-for="(option, index) in selectedQuestion.options" :key="option.value">
                    <td style="width:30%">
                      <input type="text" class="form-control" v-model="option.value" placeholder="選択肢を入力してください" />
                    </td>
                    <td>
                      <input type="text" class="form-control" v-model="option.text" placeholder="選択肢を入力してください" />
                    </td>
                    <td>
                      <b-button variant="light" class="no-min" style="min-width: 90px;" @click="removeOption(index)">削除</b-button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <b-button variant="light" class="" @click="addOption()">選択肢を追加</b-button>
                    </td>
                  </tr>
                </table>
                
              </div>
            </div>
            <div class="form-group row" v-if="selectedQuestion.type === 2">
              <label class="col-sm-4 col-form-label">最小値</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" v-model="selectedQuestion.min" placeholder="最小値を入力してください" />
              </div>
            </div>
            <div class="form-group row" v-if="selectedQuestion.type === 2">
              <label class="col-sm-4 col-form-label">最大値</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" v-model="selectedQuestion.max" placeholder="最大値を入力してください" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">必須</label>
              <div class="col-sm-8">
                <b-checkbox v-model="selectedQuestion.required" />
              </div>
            </div>
            <div class="controls text-center">
              <b-button variant="outline-primary" @click="updateQuestion()">更新</b-button>
              <b-button variant="outline-danger" class="ml-2" @click="removeQuestion()">削除</b-button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="controls mt-4 text-center">
      <b-button variant="primary" class="btn-primary" @click="save()">保存</b-button>
      <b-button variant="danger" class="btn-danger ml-2" @click="cancel()">キャンセル</b-button>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
export default {
  components: {
    PageTitle
  },
  data(){
    return {
      questionnaire: {
        name: "",
        type: 0,
        event: 0,
        schedule: "",
        questions: [
          {
            id: 1,
            type: 5,
            text: "本人の安否",
            options: [
              { text: "安全", value: "ANZEN" },
              { text: "軽傷", value: "KEISHO" },
              { text: "重傷", value: "JYUSHO" }
            ],
            required: true
          },
          {
            id: 2,
            type: 5,
            text: "取引可否",
            options: [
              { text: "可能", value: "KANOU" },
              { text: "不可能", value: "FUKANOU" },              
            ],
            required: true
          }
        ],
        contact_method:{
          form: false,
          email: false,
          phone: false,
          sms: false
        },
        respond_method: {
          form: false,
          email: false,
          phone: false,
          sms: false
        }
      },
      selectedQuestion: null,
      questionTypes: [
        { value: 1, text: 'テキスト'},
        { value: 2, text: '数値'},
        { value: 3, text: '日付'},
        { value: 4, text: '日時'},
        { value: 5, text: '選択'},
        { value: 6, text: '複数選択'},
      ]
    }
  },
  methods: {
    selectQuestion(question){
      this.questionnaire.questions.forEach(q => {
        q.selected = false;
      });
      question.selected = true;
      this.selectedQuestion = question;
      this.$forceUpdate();
    },
    addQuestion(){
      this.questionnaire.questions.push({
        id: this.questionnaire.questions.length + 1,
        type: 1,
        text: "項目名をここに書いてください。",
        new: true,
        options: [
          {
            text: "",
            value: ""
          }
        ]
      });
      this.selectQuestion(this.questionnaire.questions[this.questionnaire.questions.length - 1]);
    },
    updateQuestion(){
      this.selectedQuestion.updated = true;
      this.questionnaire.questions.forEach(q => {
        if(q.id === this.selectedQuestion.id){
          q.text = this.selectedQuestion.text;
          q.description = this.selectedQuestion.description;
          q.type = this.selectedQuestion.type;
          q.required = this.selectedQuestion.required;
          q.min = this.selectedQuestion.min;
          q.max = this.selectedQuestion.max;
          q.options = this.selectedQuestion.options;
        }
      });
      this.selectedQuestion = null;
      this.$forceUpdate();
    },
    removeQuestion(){
      this.questionnaire.questions = this.questionnaire.questions.filter(q => q.selected === false);
      this.selectedQuestion = null;
    },
    addOption(){
      this.selectedQuestion.options.push({
        text: "",
        value: ""
      });
    },
    removeOption(index){
      this.selectedQuestion.options = this.selectedQuestion.options.filter((o, i) => i !== index);
    },

    save(){
      this.$errorMessage(new Error("Not yet implemented yet."), "この機能はまだ実現されていません。");
      this.$router.push('/system/safety/questionnaire');
    },
    cancel(){
      this.$router.push('/system/safety/questionnaire');
    }
  }
}
</script>

<style>
  .alert-hover:hover{
    cursor: pointer;
    background: #d1ecf1;
    border-color: #bee5eb;
    color: #0d566f;
  }
</style>