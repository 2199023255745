<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="取引有効管理"/>
    <PageTitle title="取引有効管理"></PageTitle>
    <div class="bg-light-blue mx-4 mt-2 p-3">
      <b-form-checkbox-group v-model="form.businessImpossibleTypes">
        <h5>被災情報</h5>
        <b-form-checkbox value="victim">被災しました。</b-form-checkbox>
        <div class="form-item">
          <label>被災区分</label>
          <div class="col-3">
            <b-form-radio-group
              v-bind:disabled="findBusinessImpossibleType('victim')"
              v-model="form.victimType" :options="victimTypes" />
          </div>
        </div>
        <div class="form-item">
          <label>営業停止期間</label>
          <div class="col-3">
            <b-form-input v-bind:disabled="findBusinessImpossibleType('victim')" class="business_available__business_stop_date_title_value" v-model="form.victimBusinessStopEndDate" />
          </div>
        </div>
        <hr class="my-4"/>
        <h5>倒産情報</h5>
        <b-form-checkbox value="bankruptcy">倒産しました。</b-form-checkbox>
        <hr class="my-4"/>
        <h5>その他</h5>
        <b-form-checkbox value="stop">指名停止</b-form-checkbox>
      </b-form-checkbox-group>
      <b-alert variant="warning" show class="my-4">
        <p>チェックを入れると取引中のバイヤ企業に通知メールが送付されます。</p>
        <p>倒産にチェックを入れると「取引不可」となり、見積依頼時にサプライヤを検索出来なくなります。</p>
      </b-alert>
      <div>
        <b-button variant="danger" size="md" v-on:click="onSave">
          保存
        </b-button>
      </div>
    </div>  
  </div>
</template>

<style></style>

<script>
import SupplierBusinessAvailable from '@/model/check/SupplierBusinessAvailable'
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  props: {},
  components: { PageTitle, CompanySimpleHeader },
  data: () => {
    return {
      searchItems: {
      },
      form: {
        businessImpossibleTypes: []
      },
      victimTypes: [
        { text: '赤', value: 'red' },
        { text: 'ピンク', value: 'pink' },
        { text: '白', value: 'white' }
      ],
    }
  },
  mounted() {
    this.getSearchItems()
  },
  methods: {
    findBusinessImpossibleType(target) {
      return this.form.businessImpossibleTypes.filter(type => type == target).length != 1
    },
    getSearchItems() {
      Promise.all([
        SupplierBusinessAvailable.get(this.$route.params.supplierCode),
      ]).then((results) => {
        if (!results[0].data.length) this.searchItems = {}
        this.form = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },
    onSave() {
      this.$swal.confirm("保存してもよろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierBusinessAvailable.save(this.$route.params.supplierCode, this.form)
        ]).then(() => {
          this.$swal.success("完了しました", undefined, () => {});
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    }
  }
}
</script>