import Model from '@/model/_base';

export default class SupplierAntiSocial extends Model {
    static entity = 'anti-socials';

    constructor(data) {
        super(SupplierAntiSocial.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static search(query){
        return Model.axios.post(this.url('/'), query);
    }
    static save(judge){
        return Model.axios.put(this.url('/' + judge.supplierCode), judge);
    }
}