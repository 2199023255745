<template>
    <div class="detail">
        <PageTitle title="料金管理 取引手数料管理"></PageTitle>

        <div class="detail_download">
            <b-button variant="primary" size="md" style="flex:none;" class="detail_header_actions_item  w-10" :disabled="items.supplierPaymentList.length === 0" @click="downloadDataList">ダウンロード</b-button>
        </div>
        <div class="detail_payment_info">
            <div class="row">
                <div class="col col-sm-12 col-md-12 col-lg-12"><h4><b>{{ items.targetMonthly }}支払分</b></h4></div>
            </div>
            <div class="row">
                <div class="col col-sm-3 col-md-3 col-lg-3"><h4><b>{{ items.buyerCompanyDisplayCode }}</b></h4></div>
                <div class="col col-sm-2 col-md-2 col-lg-2"><h4><b>取引合計金額(税込) </b></h4></div>
                <div class="col col-sm-7 col-md-7 col-lg-7"><h4><b>¥{{ items.sumTotalAmountWithTax | numberFormat }}</b></h4></div>
            </div>
            <div class="row" v-for="(transactionDivisionHeader, index) in items.totalPaymentClosingDivisionList" :key="index + 'sum-division' + transactionDivisionHeader.divisionCode">
                <div class="col col-sm-3 col-md-3 col-lg-3"></div>
                <div class="col col-sm-2 col-md-2 col-lg-2">{{ transactionDivisionHeader.divisionName }}</div>
                <div class="col col-sm-7 col-md-7 col-lg-7">¥{{ transactionDivisionHeader.divisionAmount | numberFormat }}</div>
            </div>
        </div>
        <div v-if="items.supplierPaymentList">
            <table class="table table-setting table-bordered table-striped mt-2 mb-0">
                <thead>
                  <tr>
                    <th class="text-center">サプライヤ</th>
                    <th class="text-center">支払金額（税込）</th>
                    <th class="text-center" v-for="(transactionDivisionHeader, index) in items.totalPaymentClosingDivisionList" :key="index + 'tableTitle' + transactionDivisionHeader.divisionCode">
                        {{ transactionDivisionHeader.divisionName }}
                    </th>
                    <!-- <th class="text-center" width="15%">サプライヤへの振込予定日</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(supplierPayment, index) in items.supplierPaymentList" :key="index + supplierPayment.supplierDisplayCode">
                    <td class="align-middle">{{ supplierPayment.supplierDisplayCode }}<br/>{{ supplierPayment.supplierName }}</td>
                    <td class="text-right">
                      ¥{{ supplierPayment.totalAmountWithTax | numberFormat}}
                    </td>
                    <td class="text-right" v-for="transactionDivisionSetting in supplierPayment.transactionFeeDivisionList" :key="index + transactionDivisionSetting.divisionCode + transactionDivisionSetting.divisionName + supplierPayment.supplierDisplayCode">
                        ¥{{ transactionDivisionSetting.divisionAmount | numberFormat }}
                    </td>
                    <td>
                        <b-button variant="primary" size="md" class="detail_header_actions_item  w-10 mr-3" :disabled="items.supplierPaymentList.length === 0" @click="downloadPDF">PDFダウンロード</b-button>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
        <LoadingList :loaded="loadedList" :list="items.supplierPaymentList" emptyData="現在データがございません。"></LoadingList>
        <div class="card-body text-center">
            <button type="button" class="btn btn-light btn-fixed-width" @click="toTransactionFeeManagement">戻る</button>
        </div>

    </div>
</template>

<script>
import PaymentClosing from "@/model/PaymentClosing.js";
import PageTitle from "@/components/atoms/PageTitle.vue";
import LoadingList from "@/components/atoms/LoadingList.vue"

export default {
    components: { PageTitle, LoadingList },

    props: {
    },
    data: ()=>{
        return {
            loadedList: true,
            targetMonthly: null,
            companyDisplayCode: null,
            items: {
                buyerCompanyDisplayCode: "",
                targetMonthly: "",
                sumTotalAmountWithTax: 0,
                totalPaymentClosingDivisionList: [],
                supplierPaymentList: []
            }
        }
    },
    mounted(){
        this.targetMonthly = this.$route.params.targetMonthly;
        this.companyDisplayCode = this.$route.params.companyDisplayCode;
        if(this.targetMonthly && this.companyDisplayCode){
            this.loadSupplierList(this.targetMonthly, this.companyDisplayCode);
        }
    },
    methods:{
        loadSupplierList(targetMonthly, companyDisplayCode){
            this.loadedList = false;
            PaymentClosing.getDetailPaymentDivision(targetMonthly, companyDisplayCode).then(response => {
                this.items = response.data;
            })
            .catch(() => {
                // nothing
            });
            this.loadedList = true
        },
        toTransactionFeeManagement() {
            this.$router.push({ 
                params: {
                    targetMonthly: this.targetMonthly
                },
                name: "TransactionFeeManagement"
            });
        },
        downloadDataList(){
            PaymentClosing.exportExcelPaymentDivisionDetail(this.targetMonthly, this.companyDisplayCode).then(response => {
                var blob = new Blob([response.data])
                if (window.navigator.msSaveOrOpenBlob) {
                  var decodedFileName = decodeURI(response.headers["x-file-name"])
                  window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
                } else {
                  const link = document.createElement('a')
                  var url = (window.URL || window.webkitURL).createObjectURL(blob)
                  link.href = url
                  link.download = decodeURI(response.headers["x-file-name"])
                  link.click()
                  link.remove()
                }
            })
            .catch(() => {
                this.$swal.error(undefined, "Error!");
            });
        },
        downloadPDF(){

        }
    }
}

</script>

<style scoped lang="scss">
.detail {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}
.detail_download {
    display: flex;
    flex-direction: row;
}
.detail_header_actions_item {
    margin: 5px;
    min-width: 220px;
    margin-left: auto;
}
</style>