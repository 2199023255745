<template>
  <div>
    <PageTitle title="振替データ管理"></PageTitle>
    <div class="transfer">
      <nav class="nav nav-tabs border-bottom-0">
        <router-link class="transfer__menu transfer__menu_last_month" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Request', params: {'currentMonth': lastMonth, 'currentYear': lastYear} }" >{{lastMonth}}月請求</router-link>
        <router-link class="transfer__menu transfer__menu_last_month" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Collect', params: {'currentMonth': lastMonth, 'currentYear': lastYear}  }">{{lastMonth}}月回収</router-link>
        <router-link class="transfer__menu transfer__menu_last_month" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Uncollected', params: {'currentMonth': lastMonth, 'currentYear': lastYear}  }">{{lastMonth}}月未回収</router-link>
        <router-link class="transfer__menu transfer__menu_current" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Next', params: { 'currentMonth': currentMonth, 'currentYear': currentYear }}">{{currentMonth}}月請求</router-link>
        <router-link class="transfer__menu" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Stop' }">資格停止</router-link>
        <router-link class="transfer__menu" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_Past', params: { 'currentMonth': currentMonth, 'currentYear': currentYear } }">請求・回収一覧</router-link>
        <router-link class="transfer__menu" active-class="transfer__menu--active" :to="{ name: 'TransferDataManagement_History' }">請求DL・UP履歴</router-link>
      </nav>
      <router-view @updateMonth='updateMonth'></router-view>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/atoms/PageTitle.vue";
import TransferDataManagement from '@/model/TransferDataManagement'  

export default {
  data: () => {
    return {
      lastMonth: null,
      currentMonth: null,
      currentYear: null,
      lastYear: null
    }
  },
  props: {},
  components: { PageTitle },
  mounted() {
    this.defaultMonth();
  },
  methods: {
    defaultMonth(){
      TransferDataManagement.getMonth().then(response => {
        this.lastMonth = response.data.body.lastMonth;
        this.currentMonth = response.data.body.currentMonth;
        this.currentYear = response.data.body.currentYear;
        this.lastYear = response.data.body.lastYear;
      })
      .catch(() =>{
      });
    },
    updateMonth(data) {
      this.lastMonth = data.lastMonth;
      this.currentMonth = data.currentMonth;
      this.currentYear = data.currentYear;
      this.lastYear = data.lastYear;
    }
  }
}
</script>

<style scoped lang="scss">

.transfer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.transfer__menu {
  display: block;
  border: 1px solid $very-light-gray;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  color: $black;
  text-decoration: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.transfer__menu:not(.transfer__menu--active) {
  margin: 10px 0 -1px 0; 
}

.transfer__menu_last_month {
  background-color: $blue;
}
.transfer__menu_current {
  background-color: $yellow;
}

</style>