<template>
  <div class="registration">
    <PageTitle title="手数料率登録"></PageTitle>
    <div class="form-inline mb-3">
      <label class="registration__header">手数料率:</label> 
      <div class="list-inline-item">
        <input v-if="isAutoDivide"
          type="number" v-model="commissionRateInput"
          class="form-control" :class="{ 'has-error': commissionRateError }"
          @input="commissionRateError = false;" />
        <span v-else>{{ commissionRate }}</span>%
        <div v-show="isAutoDivide && commissionRateError" class="text-danger small text-left">手数率 must be greater than 0 and smaller than 100.</div>
      </div>
    </div>
    <div>
      <div class="custom-control custom-switch">
        <label class="mr-3">按分情報</label>
        <label class="mr-5">按分入力</label>
        <input type="checkbox" class="custom-control-input" id="isAutoDivide" v-model="isAutoDivide" />
        <label class="custom-control-label font-weight-bold" for="isAutoDivide"></label>
        <label class="ml-1">均等</label>
        <b-button 
          variant="warning" size="sm"
          class="registration__button-add" 
          :disabled="divisionItems.length === 5" 
          @click="add()">按分追加</b-button>
      </div>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center" style="width:5%">NO</th>
            <th class="text-center" style="width:20%">按分コード</th>
            <th class="text-center" style="width:25%">按分名</th>
            <th class="text-center" style="width:35%">按分率</th>
            <th class="text-center" style="width:15%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in divisionItems" :key="index">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">
              <template v-if="item.isNew && item.isEdit">
                <input
                  type="text" v-model="item.code" maxlength="30"
                  class="form-control" :class="{ 'has-error': item.codeError }"
                  @input="item.codeError = false;" />
                <div v-show="item.codeError" class="text-danger small text-left">必須項目です。</div>
              </template>
              <template v-else>
                {{ item.code }}
              </template>
            </td>
            <td class="text-center">
              <template v-if="item.isEdit">
                <input 
                  type="text" v-model="item.name" maxlength="60"
                  class="form-control" :class="{ 'has-error': item.nameError }"
                  @input="item.nameError = false;" />
                <div v-show="item.nameError" class="text-danger small text-left">必須項目です。</div>
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </td>
            <td class="text-center">
              <template v-if="item.isEdit && !isAutoDivide">
                <div style="display: flex;" >
                  <input 
                    type="number" v-model="item.rate" 
                    class="form-control mr-0" :class="{ 'has-error': item.rateError }"
                    @input="formatRate(item)" />%
                </div>
                <div v-show="item.rateError" class="text-danger small text-left">按分率 must be greater than 0 and smaller than 100.</div>
              </template>
              <template v-else>
                {{ item.rate }}%
              </template>
            </td>
            <td class="text-center">
              <template v-if="item.isEdit">
                <a  href="javascript:;" class="mr-3" @click="applyItem(item)">
                  <font-awesome-icon icon="check-circle" /> &nbsp; OK </a>
                <a v-if="item.isEdit && divisionItems.length != 1 || (divisionItems.length == 1 && editMap[index].code)" href="javascript:;" class="ml-3" @click="revertItem(index, item)">
                  <font-awesome-icon icon="times" /> &nbsp; 取消 </a>
              </template>
              <template v-else>
                <a  href="javascript:;" class="mr-3" @click="editItem(index, item)">
                  <font-awesome-icon icon="edit" /> &nbsp; 編集 </a>
                <a v-if="divisionItems.length != 1" href="javascript:;" class="ml-3" @click="remove(index, item)">
                  <font-awesome-icon icon="trash-alt" /> &nbsp; 削除 </a>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="registration__button-save btn-primary" @click="onSave">保存</button>
    </div>
    
  </div>
</template>

<script>
import PaymentClosing from "@/model/PaymentClosing";

import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
  data() {
    return {
      commissionRateInput: 0,
      commissionRateError: false,
      isAutoDivide: false,
      divisionItems: [],
      deleteList: [],
      editMap: {}
    }
  },
  computed: {
    commissionRate() {
      let total = 0;
      for (let item of this.divisionItems) {
        total += parseFloat(item.rate ? item.rate : 0);
      }
      return total;
    }
  },
  watch: {
    commissionRate() {
      if (!this.isAutoDivide) { this.commissionRateInput = this.commissionRate; }
    },
    isAutoDivide() {
      this.commissionRateError = false;
    },
    commissionRateInput() {
      if (this.commissionRateInput > 100) { 
        this.commissionRateInput = 100; 
      } else if (this.commissionRateInput < 0) { 
        this.commissionRateInput = 0; 
      } else { 
        this.commissionRateInput = parseFloat(this.commissionRateInput); 
      }
      this.divideRate();
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      PaymentClosing.loadCommissionRateRegistration()
      .then(Response => {
        if (Response.data && Response.data.length > 0) {
          this.divisionItems = [];
          for (let item of Response.data) {
            let division = {
              code: item.code,
              name: item.name,
              rate: item.rate,
              isEdit: false,
              codeError: false,
              nameError: false,
              rateError: false,
              deleteFlg: item.deleteFlg
            }
            this.divisionItems.push(division);
          }
        }
      })
      .catch(() => {
        // do nothing
      });
      if (this.divisionItems.length < 1 ) {
        this.add();
      }
      for (let i = 0; i < this.divisionItems.length; i++) {
        this.editMap[i] = Object.assign({}, this.divisionItems[i]);
      }
    },
    add() {
      if (this.divisionItems.length === 5) { return; }
      let newItem = {
        code: '',
        name: '',
        rate: 0,
        isNew: true,
        isEdit: true,
        codeError: false,
        nameError: false,
        rateError: false,
        deleteFlg: false
      }
      this.divisionItems.push(newItem);
      this.editMap[this.divisionItems.length-1] = Object.assign({}, newItem);
      this.divideRate();
    },
    remove(index, item) {
      // add to delete list
      item.deleteFlg = true;
      this.deleteList.push(item);

      // remove from division list
      this.divisionItems.splice(index, 1);
      this.divideRate();
    },
    editItem(index, item) {
      item.isEdit = true;
      this.editMap[index]["code"] = item.code;
      this.editMap[index]["name"] = item.name;
      this.editMap[index]["rate"] = item.rate;
    },
    applyItem(item) {
      // check validate
      if (!item.code) item.codeError = true;
      if (!item.name) item.nameError = true;
      if (!this.isAutoDivide && !item.rate) {
        item.rateError = true;
      }

      if (!item.codeError && !item.nameError && !item.rateError) {
        item.isEdit = false;
      }
    },
    revertItem(index, item) {
      let checkAllNull = true;
      if (this.editMap[index]["code"] || this.editMap[index]["name"] || this.editMap[index]["rate"]) {
        checkAllNull = false;
      }
      if (checkAllNull && this.divisionItems.length != 1) {
        this.divisionItems.splice(index, 1);
      } else {
        item.isEdit = false;
        item.code = this.editMap[index]["code"];
        item.name = this.editMap[index]["name"];
        item.rate =  this.editMap[index]["rate"];
      }
      this.divideRate();
    },
    onSave() {
      // revert item edited but not save
      for (let i = 0; i < this.divisionItems.length; i++) {
        if (this.divisionItems[i].isEdit) {
          this.revertItem(i, this.divisionItems[i]);
        }
      }
      // check validate
      if (!this.commissionRate) {
        if (this.isAutoDivide) { return this.commissionRateError = true; }
        else { return this.$swal.error(undefined, '手数率 must be greater than 0 and smaller than 100.'); }
      }

      // send data to BE
      var listItems = [];
      for (let item of this.divisionItems) {
        listItems.push(item);
      }
      for (let item of this.deleteList) {
        listItems.push(item);
      }
      PaymentClosing.saveCommissionRate(listItems)
      .then(() => {
        this.deleteList = [];
        this.editMap = {};
        this.$swal.success("手数料率登録が完了しました", undefined, () => {
          this.load(); 
        })
      })
      .catch(reason => {
        this.$errorMessage(reason);
      });
    },
    formatRate(item) {
      item.rateError = false;
      if (item.rate > 100) { 
        item.rate = 100; 
      } else if (item.rate < 0) { 
        item.rate = 0; 
      } else {
        item.rate = parseFloat(item.rate);
      }
    },
    divideRate() {
      if (this.isAutoDivide) {
        var division = this.commissionRateInput / this.divisionItems.length;
        for (let item of this.divisionItems) {
          item.rate = division;
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">
.registration {
  border: 1px solid $very-light-gray;
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
}
.registration__header {
  display: flex;
  margin-left: 15%;
  margin-right: 10px;
}
.registration__button-add {
  // display: flex;
  float: right;
  margin-right: 10%;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-width: auto;
}
.registration__button-save {
  min-width: 80px;
  margin-left: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center !important;
}
/deep/ .has-error {
  border-color: #bf1601 !important;
}
</style>