import Model from './_base';

export default class SupplierSafety extends Model {
    static entity = 'safety';

    constructor(data) {
        super(SupplierSafety.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static post(query){
        return Model.axios.post(this.url('/'), query);
    }

    static downloadSafety(query){
        return Model.axios.post(this.url('/download'), query);
    }
}