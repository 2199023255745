<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="変更履歴"/>
    <PageTitle title="変更履歴"></PageTitle>
    <div class="m-4 p-3">
      <History :supplierCode="$route.params.supplierCode" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
import CompanySimpleHeader from "@/components/organism/CompanySimpleHeader.vue"
import PageTitle from "@/components/atoms/PageTitle.vue";
import History from '../../../components/organism/examination/History.vue';

export default {
  components: { PageTitle, CompanySimpleHeader, History  }
}
</script>