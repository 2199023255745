<template>
  <div>
    <b-navbar class="header" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand to="/" v-if="!$store.state.logged"><img src="../../assets/logo.png"/> 管理者</b-navbar-brand>
      <b-navbar-brand to="/dashboard" v-if="$store.state.logged"><img src="../../assets/logo.png"/> 管理者</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <div class="text-white" v-if="$store.state.logged">
            <div class="row">
              <div class="col-1 d-flex justify-content-center align-items-center"><font-awesome-icon icon="user-circle" style="font-size: 2rem;" class="float-left"></font-awesome-icon></div>
              <div class="col text-center">
                <span>{{$store.state.user.name || 'プレースホルダ名'}}</span>
              </div>
            </div>
          </div>
          <b-nav-item-dropdown right class="d-flex align-items-center justify-content-center" v-if="$store.state.logged">
            <template v-slot:button-content >
              <font-awesome-icon icon="cog"></font-awesome-icon>
            </template>
            <b-dropdown-item v-on:click="logout">ログアウト</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  methods: {
    logout(){
      this.$store.commit('logout');
      this.$router.push('/');
    }
  }
}
</script>

<style scoped lang="scss">
  .header{
    height: 70px;
    position: fixed;
    z-index: 1000;
    width: 100%;
    border-bottom: $red solid 4px;
    img{
      width: auto;
    }
  }
</style>
