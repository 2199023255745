<template>
  <div class="supplier">
    <div class="supplier__sub-title">
      <h1>サプライヤ</h1>
    </div>
    <div class="supplier__contents">
      <div class="supplier__contents_container supplier__contents_container_title">
        <div class="supplier__contents_container_item supplier__contents_container_item--title">登録法人者数：</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">合計</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">1,500社</div>
      </div>
      <div class="supplier__contents_container">
        <div class="supplier__contents_container_item supplier__contents_container_item--supplement">（法人番号で集計）</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">open</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">500社</div>
      </div>
      <div class="supplier__contents_container">
        <div class="supplier__contents_container_item supplier__contents_container_item--title"></div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">既存</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">1,000社</div>
      </div>
      <div class="supplier__contents_container supplier__contents_container_title">
        <div class="supplier__contents_container_item supplier__contents_container_item--title">サプライヤ登録数：</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">合計</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">3,000社</div>
      </div>
      <div class="supplier__contents_container">
        <div class="small supplier__contents_container_item supplier__contents_container_item--supplement">（サプライヤコードで集計）</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">open</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">1,200社</div>
      </div>
      <div class="supplier__contents_container">
        <div class="small supplier__contents_container_item supplier__contents_container_item--title"></div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count_title">既存</div>
        <div class="supplier__contents_container_item supplier__contents_container_item--count">1,800社</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .supplier {
    border-top: 1px solid $very-light-gray;
    margin-top: 20px;
  }
  .supplier__sub-title {
    margin-top: 20px;
    font-weight: bold;
  }
  .supplier__contents {
    flex-direction: column;
    background: $light-cyan-blue;
    padding: 20px;
  }
  .supplier__contents_container {
    display:flex;
    justify-content: flex-start;
  }
  .supplier__contents_container_title {
    font-weight: bold;
  }
  .supplier__contents_container_item {
    color: #3f4242;
    &--title {
      flex-basis:20%;
    }
    &--supplement {
      flex-basis:20%;
      font-size: 12px;
    }
    &--count_title {
      flex-basis:10%;
    }
    &--count {
      flex-basis:10%;
    }
  }
</style>