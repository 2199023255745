<template>
  <div>
    <div v-if="isCompact">
      {{getCompactMessage()}}
    </div>
    <div v-else>
      <ul>
        <li v-for="(s, index) in status" :key="index">{{texts[s]}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status", "isCompact"],
  data(){
    return {
      texts: {
        isMainUserVerified: "主担当者メールアドレス確認済み",
        isCompanyInfoRegistered: "会社基本情報入力済み",
        isUsersRegistered: "担当者情報入力済み",
        isAccountCreated: "ID発行完了",
        isCompanyAddonRegistered: "会社詳細情報入力済み",
        isCategoryRegistered: "対応する品目カテゴリ選択済み",
        isProductNameRegistered: "品名入力済み",
        isDeliverableRegistered: "納品地域入力済み",
        isVerificationRegistered: "検証情報入力済み",
        isBankAccountRegistered: "商品代金振込口座情報入力済み",
        isBuyerRegistered: "取引するバイヤ選択済み",
        isAddressRegistered: "振替申込用紙を送付先入力済み",
        isRegistrationCompleted: "一旦完了（審査開始）",
        isExaminationCompleted: "サプライヤ審査完了",
        isAddressVerified: "振替申込用紙を送付先確認済み",
        isBankTransferAccountRegistered: "振替口座登録済み",
        isContractSigned: "契約署名済み",
        isNgCompleted: "口座振替手続きNG",
        isCompleted: "口座振替手続きOK（取引開始）"
      }
    }
  },
  methods: {
    getCompactMessage(){
      if(this.status.isCompleted) return "運用中";
      else if(this.status.isNgCompleted) return "DECLINED";
      else if(this.status.isAddressVerified) return "運用開始待ち";
      else if(this.status.isExaminationCompleted) return "振替申込用紙を送付先確認待ち";
      else if(this.status.isRegistrationCompleted) return "審査中";
      else return "サプライヤ登録手続き中";
    }
  }

}
</script>

<style>

</style>