<template>
  <div class="flow-item">
    <div class="flow-item__icon-container" :class="{'has-icon': hasIcon, 'active': active, 'done': done, 'no-input':noInput}">
      <slot name="icon"></slot>
    </div>
    <div class="flow-item__text" v-if="hasDescription">
      <div class="flow-item__arrow-up"></div>
      <span>
        <slot name="description"></slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['active', 'noInput', 'done'],
  computed: {
    hasIcon(){
      return !!this.$slots['icon']
    },
    hasTitle(){
      return !!this.$slots['title']
    },
    hasDescription(){
      return !!this.$slots['description']
    }
  }
}
</script>
<style lang="scss" scoped>
  .flow-item {
    text-align: center;
    
    &__text {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      color: $black;
      text-align: center;
      margin-top: .8rem;
      background: $light-cyan-blue;
      padding: .3rem .4rem .3rem .4rem;
      max-width: 5rem;
      min-height: 6rem;
    }

    &__arrow-up {
      width: 0;
      height: 0; 
      margin-top: -0.9rem;
      margin-bottom: 0.5rem;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $light-cyan-blue;
      align-self : center;
    }

    .flow-item__icon-container {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $blue;
      color: $white;
      &.has-icon{
        width: 45px;
        height: 45px;
        text-align: center;
        font-size: 1.5rem;
        
        padding-top: 0.2rem;
      }

      &.active {
        background: $red;
      }
      &.done {
        background: $green;
      }
      &.no-input { 
        background: $light-gray;
        color: $gray;
      }
    }
  }
</style>