<template>
  <div>
    <PageTitle title="共通取引基本約款 編集"></PageTitle>
    <div class="term mx-3 mx-lg-5">
      <div class="term__file_group mb-3">
        <label class="term__file_label">約款名称</label>
        <div class="term__file_body">
          <b-form-group class="term-input-group">
            <p class="term__file_offset mb-0"><b-form-input type="text" v-model="term.name" /></p>
            <span v-if="alert.name" class="search-alert text-danger">約款ファイル名は1字以上100字以内の間で入力してください。</span>
          </b-form-group>
        </div>
      </div>
      <div class="term__file_group">
        <label class="term__file_label">約款ファイル</label>
        <div class="term__file_body">
          <b-form-file id="file" v-model="pdfFile" placeholder="-- 変更の場合は新しい約款ファイルを選択 --"></b-form-file>
        </div>
      </div>
      <div class="text-center mt-5">
        <b-button variant="primary" size="lg" v-on:click="onSave">
          <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
          契約約款を更新
        </b-button>
        <br />
        <b-button variant="light" class="mt-2" :to="{ name : 'Terms' }">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          キャンセル
        </b-button>
      </div>  
    </div>
  </div>
</template>

<style scoped lang="scss">
  
.term {
  margin: 10px;
}

.term__file {
  padding: 10px;
  background: $light-cyan-blue;
}
.term__file_group {
  display: flex;
  align-items: flex-start;
  &--first {
    border-bottom: 1px solid $very-light-gray;
  }
}
.term__file_label {
  flex: 2;
  font-size: 16px;
  padding-right: 1rem;
  margin-top: 6px;
}
.term__file_body {
  flex: 10;
}
.term__file_offset {
  margin-top: 6px;
}

</style>

<script>
import Term from "@/model/Term";
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
  data: () => {
    return {
      form: {},
      term:{},
      alert:{
        name:false,
      },
      pdfFile:null,
    }
  },
  mounted() {
    this.show()
  },
  props: {},
  methods: {
    show() {
      this.getTerm()
    },
    getTerm() {
      Promise.all([
        Term.get(this.$route.params.id),
      ]).then((results) => {
        if (!results[0].data.length) this.term = {}
        this.term = results[0].data
      }).catch(reason => {
        this.$errorMessage(reason)
      })
    },   
    async updateTerm(){
      this.form.id = this.$route.params.id;
      this.form.name= this.term.name;
      this.form.isFile = 1;
      this.form.isNewFile = 0;
      if(this.pdfFile!=null){
        this.form.isNewFile = 1;
      }
      await Term.update(this.form.id,this.form,this.pdfFile);
    },

    onSave() {
      this.alert.name = false
      if(!this.term.name || (this.term.name.length < 1 || this.term.name.length > 100)){
        this.alert.name = true
      }

      if(!this.alert.name){
        this.$swal.confirm("約款を更新します。よろしいですか？", undefined, () => {        
          Promise.resolve(
          ).then(() =>this.updateTerm()
          ).then(()=>{
            this.$router.push("/system/terms");
            this.$swal.success("約款の更新が完了しました", undefined, () => {});
          
          }).catch(reason => {
              this.$errorMessage(reason);
          });
        })
      }
    }
  }
}
</script>