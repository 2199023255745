<template>
    <b-overlay>
        <div class="anti-society-detail">
            <table class="antisociety-table">
                <colgroup>
                    <col width="30%"/>
                    <col width="70%"/>
                    <!-- <col width="20%"/> -->
                </colgroup>
                <thead>
                    <tr>
                        <th>反社チェック対象者</th>
                        <th>結果</th>
                        <!-- <th>操作</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>代表者</b><br/>
                            名称: 鈴木太郎（スズキタロウ）<br/>
                            生年月日: 1977-02-01<br/>
                        </td>
                        <td>
                            <b-badge variant="success" size="lg">反社疑いなし</b-badge>
                            <pre class="border mt-1 p-3 bg-light-blue">検索結果：０件</pre>
                        </td>
                        <!-- <td>
                            <b-button variant="danger"><font-awesome-icon icon="play"/>再確認実行</b-button>
                            <b-button class="mt-2" variant="success"><font-awesome-icon icon="check-circle"/>判断変更</b-button>
                        </td> -->
                    </tr>
                    <tr>
                        <td>
                            <b>役員</b><br/>
                            役員太郎（ヤクインタロウ）<br/>
                            生年月日: 1967-12-05<br/>
                        </td>
                        <td>
                            <b-badge variant="warning" size="lg">反社疑いあり</b-badge>
                            <pre class="border mt-1 p-3 bg-light-blue">
検索結果：1件
-----------------
・朝日新聞(2012-02-05) => 役員太郎: 調査されています。(https://news.google.co.jp/archive/2021-02-05/etc...)
</pre>
                        </td>
                        <!-- <td>
                            <b-button variant="danger"><font-awesome-icon icon="play"/>再確認実行</b-button>
                            <b-button class="mt-2" variant="success"><font-awesome-icon icon="check-circle"/>判断変更</b-button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>  
    </b-overlay>
    
</template>
<script>

export default {
    props: ["input"]
    }
</script>
<style>
    .antisociety-table{
        width: 100%;
        table-layout: fixed;
    }
    .antisociety-table td, .antisociety-table th{
        border: 1px solid #000;
        background: #fff;
        padding: 0.5rem 1rem;
    }
    .antisociety-table tr > td:nth-child(1) { background: #FCE4D6; }
    .antisociety-table tr > th { background: #FCE4D6; }
    
</style>