<template>
  <li class="nav-item sidebar-menu-item main-nav-item">
    <a class="nav-link sidebar-menu-item-link" v-on:click="openSubMenu">
      <slot name="icon" />
      <span class="menu-text" :class="{'open': isExtended}">
        <slot name="link">Link Placeholder</slot>
      </span>
      <span class="sub-menu-control" v-if="!isSolo" :class="{'open': isExtended}">
        <font-awesome-icon icon="chevron-up" v-if="isSubOpen"></font-awesome-icon>
        <font-awesome-icon icon="chevron-down" v-if="!isSubOpen"></font-awesome-icon>
      </span>
    </a>
    <div class="sub-menu" :class="{'open': isSubOpen && isExtended}" v-on:click="selectedLink()">
      <b-nav vertical>
        <slot></slot>
      </b-nav>
    </div>
  </li>
</template>

<script>
export default {
  props: ['routerLink', 'text', 'isExtended', 'isExternal', 
          'selectedLink', 'openMenu', 'isSolo'],
  data: function () {
    return {
      isSubOpen: false
    }
  },
  methods: {
    openSubMenu () {
      if(this.isSolo){
        this.openMenu();
      }else if(this.isExtended) {
        this.isSubOpen = !this.isSubOpen;
      } else {
        this.openMenu()
        this.isSubOpen = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .sidebar-menu-item {
    .sidebar-menu-item-link{
      width: 100%;
      height: 42px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 1rem;
      padding-right: 1rem;
      color: $blue;
      .menu-text{
        flex:1;
        display:none;
        padding-left:0.6rem;
        padding-right:0.5rem;
        transition: all 0.5s ease-in;
        &.open{
          display:block;
        }
      }
      .sub-menu-control{
        display:none;
        &.open{
          display:block;
        }
      }
    }
    
    .sub-menu{
      display:none;
      background: $white;
      font-size: 0.9rem;
      padding-left: 1.5rem;
      &.open{
        display: block;
      }
      a{
        color: $black;
        &:hover{
          color: $blue;
        }
      }
    }
  }
</style>
