import Model from './_base';

export default class PaymentClosing extends Model {
    static entity = 'payment-closing';

    constructor(data) {
        super(PaymentClosing.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static loadCommissionRateRegistration(){
        return Model.axios.get(this.url('/load-commission-rate-registration'), { notLoading: true });
    }

    static saveCommissionRate(form){
        return Model.axios.post(this.url('/save-commission-rate'), form);
    }

    
    static loadPaymentNoticeSource(){
        return Model.axios.get(this.url('/load-payment-notice-source'), { notLoading: false });
    }

    static savePaymentNoticeSource(form){
        return Model.axios.post(this.url('/save-payment-notice-source'), form);
    }


    static getTargetMonthly(){
        return Model.axios.get(this.url('/get-target-monthly'), { notLoading: true });
    }

    static loadTransactionFeeManagement(targetMonthly) {
        return Model.axios.get(this.url('/load-transaction-fee-management/' + targetMonthly));
    }
    
    static getDetailPaymentDivision(targetMonthly, companyDisplayCode){
        return Model.axios.get(this.url('/get-detail-payment-division?targetMonthly=' + targetMonthly + '&companyDisplayCode=' + companyDisplayCode));
    }

    static exportExcelPaymentDivisionDetail(targetMonthly, companyDisplayCode){
        return Model.axios.get(this.url('/download/detail-division-list?targetMonthly=' + targetMonthly + '&companyDisplayCode=' + companyDisplayCode), { responseType: "blob"});
    }

    static exportZipTransactionPaymentClosing(targetMonthly) {
        return Model.axios.get(this.url('/download/transaction-payment-closing-list?targetMonthly=' + targetMonthly), { responseType: "blob"});
    }

}