<template>
  <div>
    <p class="small">
      <span class="text-success">■</span>サプライヤ
      <span class="text-warning ml-2">■</span>管理者
      <span class="text-info ml-2">■</span>自動
    </p>
    <div class="history-form">
      <template v-if="compact">
        <div class="text-muted">
          <font-awesome-icon icon="ellipsis-v"/>
          <router-link :to="{ name: 'SupplierDetailHistory', params: { supplierCode: $route.params.supplierCode } }" class="ml-2 small text-muted">もっと履歴を見る</router-link>
        </div>
      </template>
      
      <details class="history-item" v-for="h in history" :key="h.history.version">
        <summary class="header">
          <span 
            class="badge"
            :style="{ background: h.history.eventColor, color: '#FFF'}" 
            v-b-tooltip.hover 
            :title="'作成者: '+h.history.createUserName">{{h.history.createDate | formatDateTime}}</span>
          <b-badge class="ml-2" variant="light">ver.{{h.history.version}}</b-badge>
          <span class="ml-2 small">{{h.history.eventLog}}</span>
        </summary>
        <div class="data small" v-if="h.data">
          <table class="history-data-table">
            <colgroup>
              <col width="30%"/>
              <col width="60%"/>
            </colgroup>
          </table>
          <table class="history-data-table">
            <colgroup>
              <col width="30%"/>
              <col width="60%"/>
            </colgroup>
            <template>
              <tr v-for="(value, key) in h.data.toData" :key="key">
                <td>{{key}}</td>
                <td v-if="Array.isArray(value)" v-html="value.join('<br/>')"></td>
                <td v-else>
                  <span class="text-muted strikethrough mr-2" v-if="h.data.fromData && h.data.fromData[key]">{{h.data.fromData[key]}}</span>
                  {{value}}
                </td>
              </tr>
            </template>
          </table>
        </div>
      </details>
    </div>
  </div>
</template>

<style>
  .data{
    
    margin: 0.5rem 0.25rem;
    padding: 0.5rem 0.5rem;
  }
  .history-item {
    margin-top: 1rem;
    
  }
  .history-data-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
  }
  .history-data-table tr td{
    border:1px solid #757575;
    padding: 0rem .25rem;
  }
  .strikethrough{
    text-decoration:line-through;
  }
</style>

<script>
import CompanyChangeHistoryModel from '@/model/CompanyChangeHistory';
export default {
  props: ["supplierCode", "compact"],
  data: function(){
    return {
      history:[]
    };
  },
  created(){
    this.load();
  },
  methods:{
    load(){
      let request;
      if(this.compact) request = CompanyChangeHistoryModel.peek(this.supplierCode);
      else request = CompanyChangeHistoryModel.get(this.supplierCode);
      request.then(result => {
        for(let i=0; i < result.data.length; i++){
          if(result.data[i].data){
            if(result.data[i].data.fromData){
              result.data[i].data.fromData = JSON.parse(result.data[i].data.fromData);
            }
            if(result.data[i].data.toData){
              result.data[i].data.toData = JSON.parse(result.data[i].data.toData);
            }
          }
        }
        this.history = result.data;
      }).catch(reason => this.$errorMessage(reason));
    }
  }
}
</script>
