import Model from './_base';

export default class CompanyChangeHistory extends Model {
    static entity = 'supplier';

    constructor() {
        super(CompanyChangeHistory.entity);
    }

    static get(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/history'));
    }

    static peek(supplierCode, count = 3){
        return Model.axios.get(this.url('/' + supplierCode + '/history/peek?count=' + count));
    }
}