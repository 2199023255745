<template>
  <div class="tsr_existence">
    <div class="p-4">
      <SearchFree placeholder="サプライヤコード、サプライヤ名、主担当者名、主担当者メールアドレス"
        maxlength="100"
        v-model="form.conditions.fullText"
        @search="getSearchItems" />
      <div class="mt-2 row">
        <div class="col-auto pt-2">
          <b-form-radio-group v-model="form.conditions.byTSR.hasTSRInfo" :options="hasTsrOptions"></b-form-radio-group>
        </div>
        <div class="col">
          <b-button variant="primary" size="md" v-on:click="onDownload">ダウンロード</b-button>
        </div>
      </div>
    </div>
    <div class="tsr_existence__collect">
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"/>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center">サプライヤー</th>
            <th class="text-center">最終更新日時</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="form.result.length == 0">
            <td class="text-left" colspan="4">
              <LoadingList :loaded="loaded" :list="form.result" emptyData="データがございません。">
              </LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in form.result" :key="`${index}`">
            <td style="width:50%">
              <router-link :to="{ name: 'SupplierTSRDetail', params: { supplierCode: item.companyCode } }">
                 <span v-on:click="saveSupplier(item)">
                    {{item.companyDisplayCode}}<br />
                    {{item.corporationNumber || '«未登録»'}}<br />
                    {{item.companyName || '«未登録»'}}<br />
                    {{item.mainUserName || '«未登録»'}}<br />
                    {{item.mainUserEmail || '«未登録»'}}</span>
              </router-link>
            </td>
            <td class="tsr_existence__list_date">{{item.lastUpdateDate||'«未処理»'}}</td>
            <td class="tsr_existence__list_operation">
              <font-awesome-icon class="supplier_all__memo" :icon="['far', 'edit']"
                v-on:click="showMemo(item.companyCode)"></font-awesome-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"/>
    </div>
    <modal name="memo-modal" :draggable="true" :resizable="true" :scrollable="true" height="auto">
      <Memo :supplierCode="form.memoSupplierCode" />
    </modal>
  </div>
</template>

<style scoped lang="scss">
  @import "@/assets/css/table.scss";
</style>

<script>
  import SupplierSearch from '@/model/SupplierSearch'
  import SupplierTsr from '@/model/check/SupplierTsr'

  import Pagination from "@/components/molecule/Pagination.vue"
  import SearchFree from "@/components/molecule/SearchFree.vue"
  import LoadingList from "@/components/atoms/LoadingList.vue"
  import Memo from "@/components/organism/Memo.vue"

  export default {
    components: {
      Pagination,
      SearchFree,
      LoadingList,
      Memo
    },
    props: ['saveSupplier'],
    data: () => {
      return {
        form: {
          conditions: {
            byScore: {},
            byCompany: {},
            byOther: {},
            byTSR: {},
            isOpenGuideExclusion: [],
            isOpenSupplier: []
          },
          paging: {
            sorting: []
          },
          result: []
        },
        loaded: true,
        hasTsrOptions: [
          { text: 'TSR情報あり', value: true },
          { text: 'TSR情報なし', value: false }]
      }
    },
    created() {
      if(this.$store.state.pages.tsr){
        this.form.conditions = this.$store.state.pages.tsr.conditions || this.form.conditions;
        this.form.paging = this.$store.state.pages.tsr.paging || this.form.paging;
      }
      this.getSearchItems();
    },
    methods: {
      getSearchItems() {
        SupplierSearch.getTSR(this.form).then(result => {
          this.form = result.data;
          this.loaded = true;
          this.$store.commit('pages', { 
            tsr: {
              paging: this.form.paging,
              conditions: this.form.conditions  
            }
          });
        }).catch(reason => { this.$errorMessage(reason) });
      },
      onDownload() {
        Promise.all([
          SupplierTsr.download(this.form.query),
        ]).then((results) => {
          const fileName = 'TSR情報.csv'
          var blob = new Blob([results[0].data])
          if (window.navigator.msSaveOrOpenBlob) {
            var decodedFileName = decodeURI(fileName)
            window.navigator.msSaveOrOpenBlob(blob, decodedFileName)
          } else {
            const link = document.createElement('a')
            var url = (window.URL || window.webkitURL).createObjectURL(blob)
            link.href = url
            link.download = decodeURI(fileName)
            link.click()
            link.remove()
          }
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      },
      showMemo: function (supplierCode) {
        this.form.memoSupplierCode = supplierCode
        this.$modal.show('memo-modal');
      },
      hideMemo: function () {
        this.$modal.hide('memo-modal');
      },
    }
  }
</script>