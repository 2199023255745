<template>
  <div class="collect">
    <div class="collect__search">
      <input type="text" class="form-control w-50" placeholder="サプライヤコード、サプライヤ名" v-model="keyWord" maxlength="100" />
      <button type="button" class="btn btn-warning ml-3" @click="getSearchItems()">
        <font-awesome-icon icon="search" />
      </button>
    </div>
    <div class="collect__summary">
      <div>請求合計金額：{{ totalAmountRequestTransferFee | numberFormat }}円</div>
      <div>回収済み金額：{{ totalAmountTransferredFee | numberFormat }}円</div>
      <div>未収金額：{{ totalAmountUnTransferFee | numberFormat }}円</div>
    </div>
    <Pagination class="mt-3 mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
    <table class="table table-setting table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th class="text-center" style="width:5%">催促<input class="reminder__checkbox reminder__checkbox--all checkbox-large" type="checkbox" v-on:click="onCheckReminderAll" /></th>
          <th class="text-center" style="width:25%">サプライヤ</th>
          <th class="text-center" style="width:25%">対象期間</th>
          <th class="text-center" style="width:25%">請求金額</th>
          <th class="text-center" style="width:10%">振替日</th>
          <th class="text-center" style="width:10%">振替結果</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in searchItems" :key="`${item[dataType.detailKey]}${index}`">
          <td class="text-center"><input class="reminder__checkbox reminder__checkbox--item checkbox-large" type="checkbox" /></td>
          <td>{{item.supplierDisplayCode}}<br />{{item.supplierName}}</td>
          <td class="text-center">{{item.targetPeriodYearFrom}}/{{item.targetPeriodMonthFrom}} ～ {{item.targetPeriodYearTo}}/{{item.targetPeriodMonthTo}}</td>
          <td class="text-right">{{item.requestFee | numberFormat}}円</td>
          <td>{{item.requestDate | toDay}}</td>
          <td>{{ item.transferStatusName || '未収' }}</td>
        </tr>
      </tbody>
    </table>
    <LoadingList :loaded="loadedList" :list="searchItems" emptyData="現在データがございません。"></LoadingList>
    <div v-if="searchItems.length !== 0" class="text-left">
      <b-button variant="primary" size="md" v-on:click="onReminder">
        催促
      </b-button>
    </div>
    <Pagination class="mx-0 w-100" :pagination="pagination" @loadList="getSearchItems"></pagination>
  </div>
</template>

<script>
import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import TransferDataManagement from '@/model/TransferDataManagement'  

export default {
  components: { Pagination, LoadingList },
  data: () => {
    return {
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
        sort: null,
        sortOrder: true
      },
      loadedList: true,
      dataType: {},
      searchItems: [],
      totalAmountRequestTransferFee: 0,
      totalAmountTransferredFee: 0,
      totalAmountUnTransferFee: 0,
      keyWord: "",
      currentMonth: null,
      currentYear: null
    }
  },
  props: {},
  watch: {},
  mounted() {
    this.getSearchItems()
  },
  methods: {
    async getSearchItems(loadAll = false) {
      if (loadAll) {
        this.pagination.totalItemCount = 0;
        this.pagination.currentPage = 1;
      }
      if(!this.$route.params.currentMonth){
        const response = await TransferDataManagement.getMonth();
        if (response) {
           this.currentMonth = response.data.body.lastMonth;
           this.currentYear = response.data.body.lastYear;
         }
      }else{
           this.currentMonth = this.$route.params.currentMonth;
           this.currentYear = this.$route.params.currentYear;
      }
      TransferDataManagement.getLastMonthIsNotTransferList({pagination: this.pagination, queries: {
        keyWord: this.keyWord, 
        currentMonth: this.currentMonth, 
        currentYear: this.currentYear 
      }})
      .then(response => {
        this.searchItems = response.data.items.dataList;
        this.totalAmountRequestTransferFee = response.data.items.totalAmountRequestTransferFee;
        this.totalAmountTransferredFee = response.data.items.totalAmountTransferredFee;
        this.totalAmountUnTransferFee = response.data.items.totalAmountUnTransferFee;
        this.pagination = response.data.pagination;
      })
      .catch(() =>{

      });
    },
    onCheckReminderAll() {
      const all = document.querySelector(".reminder__checkbox--all")
      const item = document.getElementsByClassName("reminder__checkbox--item")
      if (all.checked) item.forEach(element => element.checked = true)
      if (!all.checked) item.forEach(element => element.checked = false)
    },
    onReminder() {
      this.$swal.confirm("催促メールを送信してもよろしいでしょうか？", undefined, () => {
        this.$swal.success("催促メールを送信しました", undefined, () => {
          
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

.collect {
  border: 1px solid $very-light-gray;
  padding: 10px;
}
.collect__search {
  display: flex;
  margin: 10px;
}
.collect__summary {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.reminder__checkbox {
  margin: 5px;
}
.reminder__stop-member-ship {
  font-size: 12px;
  color: $red;
}

</style>