import Model from './_base';

export default class SupplierMemo extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierMemo.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static get(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/memo'));
    }
    static save(supplierCode, form){
        return Model.axios.post(this.url('/' + supplierCode + '/memo'), form);
    }
}