<template>
  <div>
    <div id="main-sidebar" :class="{'open': isMainMenuOpen, sidebar: true}">
      <b-nav vertical>
        <b-nav-item v-on:click="openMenu" class="main-nav-item text-center main-menu-toggle">
          <font-awesome-icon class="menu-icon" :class="{'is-visible': !isMainMenuOpen}" icon="bars"></font-awesome-icon>
          <font-awesome-icon class="menu-icon align-right" :class="{'is-visible':  isMainMenuOpen}" icon="times"></font-awesome-icon>
        </b-nav-item>
        <SidebarLink :selected-link='selectedLink'
                     :is-extended='isMainMenuOpen'
                     :openMenu='openMenu'>
          <template v-slot:icon>
            <font-awesome-icon icon="tags"></font-awesome-icon>
          </template>
          <template v-slot:link>サプライヤ管理</template>
          <b-nav-item :to="{ name: 'SupplierList_All' }">すべて</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierList_RegisterStatus' }">登録進捗</b-nav-item>
          <!-- 
          <b-nav-item :to="{ name: 'SupplierList_AutoVerificationNg' }">自動検証NG</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierList_TsrExistence' }">TSR情報有無</b-nav-item>
          <b-nav-item :to="{ name: 'SupplierList_AntiCompanyCheckResult' }">反社チェック結果</b-nav-item> 
          -->
        </SidebarLink>
        <SidebarLink :selected-link='selectedLink'
                     :is-extended='isMainMenuOpen'
                     :openMenu='openMenu'>
          <template v-slot:icon>
            <font-awesome-icon icon="credit-card" v-on:click="openMenu"></font-awesome-icon>
          </template>
          <template v-slot:link>Biz会費請求管理</template>
          <b-nav-item to="/fee/transfer-data-management/status/next">振替データ・請求書管理</b-nav-item>
          <!-- <b-nav-item to="/fee/membership-bill-management">請求書管理</b-nav-item> -->
        </SidebarLink>
        <SidebarLink :selected-link='selectedLink'
                     :is-extended='isMainMenuOpen'
                     :openMenu='openMenu'>
          <template v-slot:icon>
            <font-awesome-icon icon="file-invoice-dollar" @click="openMenu"></font-awesome-icon>
          </template>
          <template v-slot:link>Biz取引手数料管理</template>
          <b-nav-item to="/fee/transaction-fee-management">請求回収代行管理</b-nav-item>
        </SidebarLink>
        <SidebarLink :selected-link='selectedLink'
                     :is-extended='isMainMenuOpen'
                     :openMenu='openMenu'>
          <template v-slot:icon>
            <font-awesome-icon icon="bell" v-on:click="openMenu"></font-awesome-icon>
          </template>
          <template v-slot:link>安否確認</template>
          <b-nav-item to="/system/safety/events">被災管理</b-nav-item>
          <b-nav-item to="/system/safety/questionnaire">アンケート一覧</b-nav-item>
        </SidebarLink>
        <SidebarLink :selected-link='selectedLink'
                     :is-extended='isMainMenuOpen'
                     :openMenu='openMenu'>
          <template v-slot:icon>
            <font-awesome-icon icon="wrench" v-on:click="openMenu"></font-awesome-icon>
          </template>
          <template v-slot:link>システム設定</template>
          <b-nav-item to="/fee/registration">取引手数料率設定</b-nav-item>
          <b-nav-item to="/system/terms">規約設定</b-nav-item>
          <b-nav-item to="/system/category">標準カテゴリ設定</b-nav-item>
          <b-nav-item to="/system/search-item-management">検索用品名設定</b-nav-item>
          <b-nav-item to="/system/payment-notice-source">請求回収代行会社設定</b-nav-item>
          <b-nav-item to="/system/buyer-info">バイヤ情報設定</b-nav-item>
          <b-nav-item to="/fee/membership-fee-management">会費設定</b-nav-item>
        </SidebarLink>
      </b-nav>
    </div>
  </div>
</template>

<script>
import SidebarLink from '@/components/molecule/sidebar/SidebarLink'

export default {
  computed: {
    isMainMenuOpen(){
      return this.$store.state.isMainMenuOpen;
    }
  },
  components: {
    SidebarLink
  },
  methods: {
    openMenu: function() {
      this.$store.state.isMainMenuOpen = !this.$store.state.isMainMenuOpen ;
    },
    selectedLink: function () {
      this.$store.state.isMainMenuOpen = true;
    },
  }
}
</script>

<style scoped lang="scss">
  .sidebar{
    position:fixed;
    top: 70px;
    left: 0;
    width: 84px;
    height: calc(100vh - 70px);
    bottom: 0;
    overflow-y: auto;
    background: $light-cyan-blue;
    box-shadow: 1px 1px 2px 0px $light-gray;
    transition: width 0.1s ease-in;
    z-index: 500;
    &.open{
      width: 296px;
    }
    .main-menu-toggle {
      border-bottom: 1px solid $very-light-gray;
    }
    
    .main-nav-item {
      min-height: 42px;
      &:hover{
        background: $white;
      }
      > a {
        font-size: 1.5rem;
        color: $blue;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .menu-icon {
        display:none;
        &.is-visible{
          display:inline ;
          align-self: center;
        }
        &.align-right{
          align-self:flex-end;
        }
      }
    }
  }
</style>
