<template>
  <div>
    <CompanySimpleHeader :companyCode="$route.params.supplierCode" title="取引先バイヤ企業"/>
    <div>
      <PageTitle title="取引先バイヤ企業"></PageTitle>
    </div>
    <div class="buyer">
      <div class="buyer__contents">
        <div class="buyer__contents_title">
          既存バイヤ一覧
        </div>
        <div class="buyer__contents_body" v-if="buyer.existing.length == 0">
          取引先がありません
        </div>
        <div class="buyer__contents_body" v-if="buyer.existing.length > 0">
          <table class="table table-setting table-bordered mt-3 col-12 col-md-8">
            <thead>
              <tr>
                <th class="text-center no">No.</th>
                <th class="text-center name">対象バイヤ</th>
                <th class="text-center status">契約状態</th>
                <th class="text-center">バイヤ設定内容</th>
              </tr>
            </thead>
            <tbody>
              <tr  :class="`row${index%2}`" class="status-icon" v-for="(item, index) in buyer.existing" :key="`existing${index}`">
                <td class="text-center align-middle">{{index+1}}</td>
                <td class="align-middle">{{item.buyerInfo.buyerName}}</td>
                <td class="text-center align-middle">                  
                  <div class="status-signed_binded">
                    契約済み<br>＜有効＞
                  </div>
                </td>
                <td>
                  <p class="mb-0">
                  <span v-if="item.isRecommendation == true && item.isImportant == true">[推奨・重要]</span>
                  <span v-else-if="item.isRecommendation == true">[推奨]</span>
                  <span v-else-if="item.isImportant == true">[重要]</span>
                  </p>
                  <p v-if="item.isRecommendation == true || item.isImportant == true">{{item.importantComment}}</p>

                  <p class="mb-0">
                  <span v-if="item.isNotImpossibleBusiness == true">[取引不可]</span>
                  </p>
                  <p v-if="item.isNotImpossibleBussiness == true ">{{item.impossibleBusiness}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="buyer">
      <div class="buyer__contents">
        <div class="buyer__contents_title">
          openバイヤ一覧
        </div>
        <div class="buyer__contents_body" v-if="buyer.open.length == 0">
          取引先がありません
        </div>
        <div class="buyer__contents_body" v-if="buyer.open.length > 0 && openDetail.length > 0">
          <table class="table table-setting table-bordered mt-3">
            <thead>
              <tr>
                <th class="text-center no">No.</th>
                <th class="text-center name">対象バイヤ</th>
                <th class="text-center status">契約状態</th>
                <th class="text-center" colspan="2">取引情報</th>
                <th class="text-center">特約</th>
                <th class="text-center">バイヤ設定内容</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in buyer.open" >
              <tr :class="`row${index%2}`" :key="`open${index}`">
                <td class="text-center align-middle" :rowspan="openDetail[index]['size']">{{index+1}}</td>
                <td class="align-middle" :rowspan="openDetail[index]['size']">{{item.buyerInfo.buyerName}}</td>
                <td class="status-icon text-center align-middle" :rowspan="openDetail[index]['size']">
                  <div class="status-wait_sign" v-if="
                    item.companyContractDest == null || 
                    item.companyContractDest == null || 
                    item.companyContractDest.companyContractBuyer == null ||
                    item.companyContractDest.companyContractBuyer.CompanyContractSign == null 
                      || item.companyContractDest.companyContractBuyer.CompanyContractSign.signStatus == 0">
                    取引希望あり<br>＜署名待ち＞
                  </div>
                  <div class="status-signed_binded" v-else-if="item.companyContractDest.companyContractBuyer.CompanyContractSign.signStatus == 1">
                    契約済み<br>＜有効＞
                  </div>
                  <div class="status-signed_not_binded" v-else-if="item.isNotImpossibleBusiness == true">
                    契約済み<br>＜無効＞
                  </div>
                </td>
                <td class="text-center align-middle">
                  <template v-if="openDetail[index]['details'].length>0">
                    <span class="flag-circle" v-if="openDetail[index]['details'][0]['flag']">〇</span>
                    <span class="flag-cross" v-else-if="!openDetail[index]['details'][0]['flag']">✕</span>                    
                  </template>
                </td>
                <td>
                  <template v-if="openDetail[index]['details'].length>0">
                  <p class="my-1">評価：{{openDetail[index]['details'][0]['evaluation']}}</p>
                  <p class="my-1">備考：{{openDetail[index]['details'][0]['text']}}</p>
                  </template>
                </td>
                <td class="text-center align-middle" :rowspan="openDetail[index]['size']">
                  <div v-if="item.companyContractDest != null">
                    <span v-if="item.companyContractDest.companyContractBuyer.CompanyContractSign != null 
                        && item.companyContractDest.companyContractBuyer.CompanyContractSign.signStat == 1">
                      <a>特約有</a>
                    </span>
                  </div>
                  <span v-else>特約無</span>
                </td>
                <td :rowspan="openDetail[index]['size']">
                  <div v-if="item.openBuyer != null">
                    <p class="mb-0">
                    <span v-if="item.openBuyer.isRecommendation == true && item.openBuyer.isImportant == true">[推奨・重要]</span>
                    <span v-else-if="item.openBuyer.isRecommendation == true">[推奨]</span>
                    <span v-else-if="item.openBuyer.isImportant == true">[重要]</span>
                    </p>
                    <p v-if="item.openBuyer.isRecommendation == true || item.openBuyer.isImportant == true">{{item.openBuyer.importantComment}}</p>

                    <p class="mb-0">
                    <span v-if="item.openBuyer.isNotImpossibleBusiness == true">[取引不可]</span>
                    </p>
                    <p v-if="item.openBuyer.isNotImpossibleBussiness == true ">{{item.openBuyer.impossibleBusiness}}</p>
                  </div>
                </td>
              </tr>              
                <template v-if="openDetail[index]['details'].length>1">                  
                  <template v-for="(detail, index2) in openDetail[index]['details'].splice(0,1)">
                    <tr :class="`row${index%2}`" :key="`detail${index}-${index2}`">
                      <td class="text-center align-middle">
                          <span  class="flag-circle" v-if="openDetail[index]['details'][index2]['flag']">〇</span>
                          <span  class="flag-cross" v-else-if="!openDetail[index]['details'][index2]['flag']">✕</span>
                      </td>
                      <td class="align-middle">
                        <p class="my-1">評価：{{openDetail[index]['details'][index2]['evaluation']}}</p>
                        <p class="my-1">備考：{{openDetail[index]['details'][index2]['text']}}</p>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table> 
        </div>
      </div>
    </div>
    <div class="buyer">
      <div class="buyer__contents">
        <div class="buyer__contents_title">
          取引を希望しないバイヤ一覧
        </div>
        <div class="buyer__contents_body" v-if="buyer.refuse.length == 0">
          取引を希望しないバイヤはいません
        </div>
        <div class="buyer__contents_body" v-if="buyer.refuse.length > 0">
          <table class="table table-setting table-bordered mt-3 col-12 col-md-8">
            <thead>
              <tr>
                <th class="text-center no">No.</th>
                <th class="text-center name">対象バイヤ</th>
                <th colspan="2" class="text-center status">契約状態</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="`row${index%2}`" v-for="(item, index) in buyer.refuse" :key="`refuse${index}`">
                <td class="text-center align-middle">{{index+1}}</td>
                <td class="align-middle">{{item.buyerInfo && item.buyerInfo.buyerName}}</td>
                
                <td class="status-icon text-center align-middle">
                  <div class="status-signed_not_binded">
                    契約済み<br>＜無効＞
                  </div>
                </td>
                <td class="text-center align-middle">取引希望をしない</td>
              </tr>
            </tbody>
          </table> 
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

table{
  th{    
    background-color:#81b9e7;
  }
  td{
    border-color:#cfcaca;
  }
  .row0{    
    background-color:#d9eeff;
  }
  .row1{    
    background-color:#e9e9e9;
  }
  .no{
    width:10px;
  }
  .name{
    width:300px;
  }
  .status{
    width:200px;
  }
  span.flag-circle{
    font-weight: bold;
    color:#021479;
  }
  span.flag-cross{
    font-size:18px;
    font-weight: bolder;
    color:#9e0101;
  }

  .status-icon{
    div{
      color:#fdfdfd;
      padding:2px 0 2px 0;      
    }
    .status-wait_sign{
      background-color:#077503;
    }
    .status-signed_binded{
      background-color:#061775;
    }
    .status-signed_not_binded{
      background-color:#883f02;
    }
    .status-refuse{
      background-color:#883f02;
    }
    
  } 
  
}

.buyer {
  margin: 10px;
  border-radius: 0;
  border: none;
  box-shadow: 1px 0 1px rgba(204, 204, 204, 0.5);
  .buyer__comment {
    padding: 10px;
  }
  .buyer__contents {
    padding: 10px;
    background: $light-cyan-blue;
  }
  .buyer__contents_title {
    margin: 10px;
    font-weight: bold;
  }
  .buyer__contents_body {
    margin: 10px;
  }
  .buyer__contents_body_business {
    display: flex;
  }
  .buyer__contents_body_business_status {
    margin-right: 10px;
  }
  .buyer__contents_body_business_status_icon {
    font-size: 14px;
    overflow: hidden;
    margin: 1rem !important;
    padding: 10px;
    &--ok {
      background-color: $green;
    }
    &--warning {
      background-color: $yellow;  
    }
    &--ng {
      background-color: $red;
    }
  }
  .buyer__contents_item {
    display: flex;
    align-items: flex-start;
    &--first {
      border-bottom: 1px solid $very-light-gray;
    }
  }
  .buyer__contents_item_label {
    flex: 2;
    font-size: 16px;
    padding-right: 1rem;
    margin-top: 6px;
  }
  .buyer__contents_item_value {
    flex: 10;
    margin-top: 6px;
  }
}

</style>

<script>
import SupplierBusinessBuyer from "@/model/SupplierBusinessBuyer";
import CompanySimpleHeader from '@/components/organism/CompanySimpleHeader.vue';
import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { CompanySimpleHeader, PageTitle },
  data: () => {
    return {
      openDetail:[],
      buyer: {
        existing: [],        
        open: [],        
        refuse: []
      }
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      Promise.all([
        SupplierBusinessBuyer.getExisting(this.$route.params.supplierCode),
        SupplierBusinessBuyer.getOpen(this.$route.params.supplierCode),
        SupplierBusinessBuyer.getRefuse(this.$route.params.supplierCode),
      ]).then((results) => {
        if(!results[0].data.length) this.buyer.existing = []
        this.buyer.existing = results[0].data

        if(!results[1].data.length) this.buyer.open = []
        this.buyer.open = results[1].data

        this.openDetail = Array(this.buyer.open.length)
        for(var i=0;this.buyer.open.length>i;i++){
          //test code
          var test_array = [];
          if(i%2 == 1){
            test_array = [{flag:true,evaluation:"A",text:"foooooooooo"}]
            this.openDetail[i] = {buyerCode:this.buyer.open[i].buyerCode,companyCode:this.buyer.open[i].companyCode,details:test_array,size:1};
          }else{            
            test_array = [{flag:false,evaluation:"B",text:"foooooooooo"},{flag:true,evaluation:"S",text:"baaaaaaaaaaaaaaaaar"}]
            this.openDetail[i] = {buyerCode:this.buyer.open[i].buyerCode,companyCode:this.buyer.open[i].companyCode,details:test_array,size:2};
          }
        }        
        
        if(!results[2].data.length) this.buyer.refuse = []
        
        this.buyer.refuse = results[2].data

      }).catch(reason => {
        this.$errorMessage(reason)
      })
    }
  }
}
</script>