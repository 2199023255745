import Vue from "vue";
import Router from "vue-router";
import $store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: () => import("./pages/Login"),
      meta: { unauthenticated: true }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./pages/Login"),
      meta: { unauthenticated: true }
    },
    {
      path: "/notfound",
      name: "NotFound",
      component: () => import("./pages/NotFound")
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./pages/Dashboard.vue")
    },
    /*
    --------------------------------------------------
    // Manage supplier list
    --------------------------------------------------
    */
    {
      path: "/supplier",
      name: "SupplierList",
      component: () => import("@/pages/supplier/SupplierList.vue"),
      children: [
        {
          path: "/supplier/all",
          name: "SupplierList_All",
          component: () => import("@/pages/supplier/list/All.vue")
        },
        {
          path: "/supplier/register-status",
          name: "SupplierList_RegisterStatus",
          component: () => import("@/pages/supplier/list/RegisterStatus.vue")
        },
        {
          path: "/supplier/auto-verification-ng",
          name: "SupplierList_AutoVerificationNg",
          component: () => import("@/pages/supplier/list/AutoVerificationNg.vue")
        },
        {
          path: "/supplier/tsr-existence",
          name: "SupplierList_TsrExistence",
          component: () => import("@/pages/supplier/list/TsrExistence.vue")
        },
        {
          path: "/supplier/anti-company-check-result",
          name: "SupplierList_AntiCompanyCheckResult",
          component: () => import("@/pages/supplier/list/AntiCompanyCheckResult.vue")
        }
      ]
    },
    /*
    --------------------------------------------------
    // Manage supplier detail
    --------------------------------------------------
    */
    {
      path: "/supplier/:supplierCode",
      name: "SupplierDetail",
      component: () => import("./pages/supplier/detail/Detail.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/exam",
      name: "SupplierTSRDetail",
      component: () => import("./pages/supplier/detail/DetailTSR.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/examination",
      name: "SupplierExamDetail",
      component: () => import("./pages/supplier/detail/Examination.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/rpa",
      name: "SupplierRPADetail",
      component: () => import("./pages/supplier/detail/DetailRPA.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/anti-social",
      name: "SupplierAntiSocial",
      component: () => import("./pages/supplier/detail/DetailAntiSocial.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/bank",
      name: "SupplierDetailBank",
      component: () => import("./pages/supplier/detail/Bank.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/signer",
      name: "SupplierDetailSigner",
      component: () => import("./pages/supplier/detail/Signer.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/buyer",
      name: "SupplierDetailBuyer",
      component: () => import("./pages/supplier/detail/Buyer.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/business-available",
      name: "SupplierDetailBusinessAvailable",
      component: () => import("./pages/supplier/detail/BusinessAvailable.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/withdraw",
      name: "SupplierDetailWithdraw",
      component: () => import("./pages/supplier/detail/Withdraw.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/withdraw/edit",
      name: "SupplierDetailWithdrawEdit",
      component: () => import("./pages/supplier/detail/WithdrawEdit.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/history",
      name: "SupplierDetailHistory",
      component: () => import("./pages/supplier/detail/History.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    {
      path: "/supplier/:supplierCode/status",
      name: "SupplierDetailStatus",
      component: () => import("./pages/supplier/detail/Status.vue"),
      meta: {
        group: 'SupplierDetail'
      },
      props: true
    },
    /*
    --------------------------------------------------
    // Manage fee
    --------------------------------------------------
    */
    {
      path: "/fee/membership-fee-management",
      name: "MembershipFeeManagement",
      component: () => import("./pages/fee/MembershipFeeManagement.vue"),
      props: true
    },
    {
      path: "/fee/membership-fee-management/:id",
      name: "MembershipFeeManagementEdit",
      component: () => import("./pages/fee/MembershipFeeManagementEdit.vue"),
      props: true
    },
    {
      path: "/fee/transfer-data-management",
      name: "TransferDataManagement",
      component: () => import("./pages/fee/TransferDataManagement.vue"),
      children: [
        {
          path: "/fee/transfer-data-management/status/request",
          component: () => import("@/pages/fee/transfer/Request.vue"),
          name: "TransferDataManagement_Request"
        },
        {
          path: "/fee/transfer-data-management/status/collect",
          component: () => import("@/pages/fee/transfer/Collect.vue"),
          name: "TransferDataManagement_Collect"
        },
        {
          path: "/fee/transfer-data-management/status/uncollected",
          component: () => import("@/pages/fee/transfer/Uncollected.vue"),
          name: "TransferDataManagement_Uncollected"
        },
        {
          path: "/fee/transfer-data-management/status/next",
          component: () => import("@/pages/fee/transfer/Next.vue"),
          name: "TransferDataManagement_Next",
        },
        {
          path: "/fee/transfer-data-management/status/stop",
          component: () => import("@/pages/fee/transfer/Stop.vue"),
          name: "TransferDataManagement_Stop"
        },
        {
          path: "/fee/transfer-data-management/status/past",
          component: () => import("@/pages/fee/transfer/Past.vue"),
          name: "TransferDataManagement_Past"
        },
        {
          path: "/fee/transfer-data-management/status/history",
          component: () => import("@/pages/fee/transfer/History.vue"),
          name: "TransferDataManagement_History"
        }
      ]
    },
    {
      path: "/fee/transaction-fee-management",
      name: "TransactionFeeManagement",
      component: () => import("./pages/fee/TransactionFeeManagement.vue"),
    },
    {
      path: "/fee/transaction-fee-management/transaction/transaction-fee-detail/:targetMonthly/:companyDisplayCode",
      component: () => import("@/pages/fee/transaction/TransactionFeeDetail.vue"),
      name: "TransactionFeeManagement_Detail"
    },
    {
      path: "/fee/registration",
      name: "FeeRegistration",
      component: () => import("./pages/fee/Registration.vue")
    },
    {
      path: "/fee/membership-bill-management",
      name: "MembershipBillManagement",
      component: () => import("./pages/fee/MembershipBillManagement.vue"),
      props: true
    },
    /*
    --------------------------------------------------
    // Manage system
    --------------------------------------------------
    */
    {
      path: "/system/payment-notice-source",
      name: "PaymentNoticeSource",
      component: () => import("@/pages/fee/PaymentNoticeSource.vue")
    },
    {
      path: "/system/terms",
      name: "Terms",component: () => import("./pages/system/term/Terms.vue"),
      children: [
      ]
    },
    {
      path: "/system/terms/add",
      name: "TermAdd",
      component: () => import("@/pages/system/term/TermAdd.vue")
    },
    {
      path: "/system/terms/:id/edit",
      name: "TermEdit",
      component: () => import("@/pages/system/term/TermEdit.vue")
    },
    {
      path: "/system/category",
      name: "BasicItemCategory",
      component: () => import("./pages/system/category/BasicItemCategory.vue")
    },
    {
      path: "/system/search-item-management",
      name: "SearchItemManagement",
      component: () => import("./pages/system/itemmanagement/SearchItemManagement.vue")
    },
    {
      path: "/system/buyer-info",
      name: "BuyerInfoManagement",
      component: () => import("@/pages/system/BuyerInfo.vue")
    },
    {
      path: "/system/buyer-info/:id",
      name: "BuyerInfoManagementEdit",
      component: () => import("@/pages/system/BuyerInfoEdit.vue")
    },
    {
      path: "/system/safety/questionnaire",
      name: "QuestionnaireManagement",
      component: () => import("@/pages/system/safety/QuestionnaireList.vue")
    },
    {
      path: "/system/safety/questionnaire/:id",
      name: "QuestionnaireManagementEdit",
      component: () => import("@/pages/system/safety/Questionnaire.vue")
    },
    {
      path: "/system/safety/events",
      name: "EventManagement",
      component: () => import("@/pages/system/safety/EventList.vue")
    },
    {
      path: "/system/safety/events/:id",
      name: "EventDetailManagement",
      component: () => import("@/pages/system/safety/Event.vue")
    }
    
  ]
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    if(document.getElementById("main-sidebar")){
      document.getElementById("main-sidebar").className = "sidebar";
      $store.state.isMainMenuOpen = false;
    }
  }, 100);
  
  if (!$store.state.logged && !to.meta.unauthenticated) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else if ($store.state.logged && to.meta.unauthenticated) {
    next({ path: '/dashboard' });
  } else {
    next();
  }
  
});


export default router;