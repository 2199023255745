<template>
  <div>
    <PageTitle title="会費設定"></PageTitle>
    <div class="membership">
      <div class="membership__fee">
        <div class="membership__fee_group">
          <label class="membership__fee_label">会費月額</label>
          <div class="membership__fee_body">
            <p class="membership__fee_offset m-0">
              <input-comma 
                placeholder="0,000" 
                v-model="detail.amountFee" 
                @change="errors.errorAmountFee = false; $forceUpdate();"
                :class="{'has-error': hasError && errors.errorAmountFee}" />円
            </p>
            <span class="text-danger text-left" v-show="hasError && errors.errorAmountFee">{{ errors.messageAmountError }}</span>
          </div>
        </div>
        <div class="membership__fee_group">
          <label class="membership__fee_label">消費税</label>
          <div class="membership__fee_body">
            <p class="membership__fee_offset m-0">
              <input-comma 
                placeholder="0" 
                v-model="detail.amountFeeTax" 
                @change="errors.errorAmountFeeTax = false; $forceUpdate();"
                :class="{'has-error': hasError && errors.errorAmountFeeTax}" />%
            </p>
            <span class="text-danger text-left" v-show="hasError && errors.errorAmountFeeTax">{{ errors.messageAmountTaxError }}</span>
          </div>
        </div>
        <div class="membership__fee_group">
          <label class="membership__fee_label">適用開始日</label>
          <div class="membership__fee_body">
            <div class="membership__fee_offset m-0">
              <datepicker 
                v-model="detail.startDate" 
                required typeable 
                wrapper-class="list-inline-item"
                @selected="errors.errorStartDate = false; $forceUpdate();"
                @input="errors.errorStartDate = false; $forceUpdate();"
                :input-class="{'has-error': hasError && errors.errorStartDate}"
                :disabledDates="{ to: new Date(Date.now() - 8640000) }">
              </datepicker>※一度設定したら変更できないようにする
            </div>
            <span class="text-danger text-left" v-show="hasError && errors.errorStartDate">{{ errors.messageStartDateError }}</span>
          </div>
        </div>
        <div class="membership__fee_group">
          <label class="membership__fee_label">振替日</label>
          <div class="membership__fee_body">
            <select class="membership__fee_offset m-0" v-model="detail.transferDate" >
              <option v-for="n of 31" :key="n" :value="n">{{ n }}</option>
            </select>日
          </div>
        </div>
        <div class="membership__fee_group">
          <label class="membership__fee_label">振替月数</label>
          <div class="membership__fee_body">
            <select class="membership__fee_offset m-0" v-model="detail.targetPeriod" >
              <option v-for="n of 12" :key="n" :value="n">{{ n }}</option>
            </select>か月
          </div>
        </div>
        <div class="membership__fee_group">
          <label class="membership__fee_label">無料月数</label>
          <div class="membership__fee_body">
            <p class="membership__fee_offset m-0">
              <input 
                type="number"
                v-model.number="detail.freeMonth" 
                @change="errors.errorFreeMonth = false; $forceUpdate();"
                :class="{'has-error': hasError && errors.errorFreeMonth}" />か月
            </p>
            <span class="text-danger text-left" v-show="hasError && errors.errorFreeMonth">必須項目です。</span>
          </div>
        </div>
        <div class="membership__fee_group" style="display:none;">
          <label class="membership__fee_label">委託者コード</label>
          <div class="membership__fee_body">
            <p class="membership__fee_offset m-0">
              <input 
                maxlength="6"
                v-model.number="detail.trusteeCode" 
                @change="errors.errorTrusteeCode = false; $forceUpdate();"
                :class="{'has-error': hasError && errors.errorTrusteeCode}" />
            </p>
            <span class="text-danger text-left" v-show="hasError && errors.errorTrusteeCode">必須項目です。</span>
          </div>
        </div>
        <div class="membership__fee_group" style="display:none;">
          <label class="membership__fee_label">委託者名</label>
          <div class="membership__fee_body">
            <p class="membership__fee_offset m-0">
              <input 
                maxlength="40"
                v-model.number="detail.trusteeName" 
                @change="errors.errorTrusteeName = false; $forceUpdate();"
                :class="{'has-error': hasError && errors.errorTrusteeName}" />
            </p>
            <span class="text-danger text-left" v-show="hasError && errors.errorTrusteeName">必須項目です。</span>
          </div>
        </div>
        <div class="text-center mt-5">
          <b-button variant="primary" size="lg" v-on:click="onSave">
            <font-awesome-icon :icon="['far', 'circle']"></font-awesome-icon>
            保存
          </b-button>
          <br />
          <b-button variant="light" class="mt-2" :to="{ name : 'MembershipFeeManagement' }">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            キャンセル
          </b-button>
        </div>  
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  
.membership {
  margin: 10px;
  border-radius: 0;
  border: none;
  box-shadow: 1px 0 1px rgba(204, 204, 204, 0.5);
}

.membership__fee {
  padding: 10px;
  background: $light-cyan-blue;
}
.membership__fee_group {
  display: flex;
  align-items: flex-start;
  &--first {
    border-bottom: 1px solid $very-light-gray;
  }
}
.membership__fee_label {
  flex: 2;
  font-size: 16px;
  padding-right: 1rem;
  margin-top: 6px;
}
.membership__fee_body {
  flex: 10;
}
.membership__fee_offset {
  margin-top: 6px;
}
/deep/ .has-error {
  border-color: #bf1601 !important;
}

</style>

<script>
import MembershipFeeManagement from "@/model/MembershipFeeManagement";

import PageTitle from "@/components/atoms/PageTitle.vue";

export default {
  components: { PageTitle },
   data: () => {
    return {
      hasError: false,
      errors: {},
      detail: {
        transferDate: 1,
        targetPeriod: 3
      }
    }
  },
  mounted() {
    this.loadDetail()
  },
  methods: {
    loadDetail() {
      MembershipFeeManagement.loadEdit(this.$route.params.id).then(result => {
        this.detail = result.data;
        if (!this.detail.transferDate) {
          this.detail.transferDate = 1;
        }
        if (!this.detail.targetPeriod) {
          this.detail.targetPeriod = 3;
        }
        if (!this.detail.freeMonth) {
          this.detail.freeMonth = 2;
        }
      }).catch(reason => this.$errorMessage(reason));
    },
    onSave() {
      let validate = this.validateAll();
      if (validate) {
        this.$swal.confirm("会費を設定してもよろしいでしょうか？", undefined, () => {
        Promise.all([
          MembershipFeeManagement.saveSetting(this.detail),
        ]).then(() => {
          this.$swal.success("会費設定が完了しました", undefined, () => {
            this.$router.push({ name: "MembershipFeeManagement" });  
          })
        }).catch(reason => {
            this.$errorMessage(reason);
          });
        })
      }
    },
    validateAll() {
      if (this.detail.amountFee && this.detail.startDate && this.detail.freeMonth && 
        this.detail.trusteeCode && this.detail.trusteeName) {
        return true;
      } else {
        this.hasError = true;
        let messageStartDateError = '';
        let messageAmountError = '';
        let messageAmountTaxError = '';
        let errorAmountFee = false;
        let errorAmountFeeTax = false;
        let errorStartDate = false;
        let errorFreeMonth = false;
        let errorTrusteeCode = false;
        let errorTrusteeName = false;
        var currentDate = new Date();
        currentDate = this.$moment(currentDate).format('yyyy-MM-DD');

        if (!this.detail.amountFee) {
          errorAmountFee = true;
          if (this.detail.amountFee <= 0) {
            messageAmountError = 'Amount Fee must greater than 0!!!';
          } else {
            messageAmountError = '必須項目です。';
          }
        }

        if (!this.detail.amountFeeTax) {
          errorAmountFeeTax = true;
          if (this.detail.amountFeeTax <= 0) {
            messageAmountTaxError = 'Amount Fee must greater than 0!!!';
          } else {
            messageAmountTaxError = '必須項目です。';
          }
        }

        if (!this.detail.startDate) {
          errorStartDate = true;
          messageStartDateError = '必須項目です。';
        } else if (this.detail.startDate < currentDate) {
          errorStartDate = true;
          messageStartDateError = 'Past date!!!';
        }

        if (!this.detail.freeMonth) {
          errorFreeMonth = true;
        }

        if (!this.detail.trusteeCode) {
          errorTrusteeCode = true;
        }

        if (!this.detail.trusteeName) {
          errorTrusteeName = true;
        }

        this.errors = Object.assign(this.errors, {
          errorAmountFee: errorAmountFee,
          errorAmountFeeTax: errorAmountFeeTax,
          errorStartDate: errorStartDate,
          errorFreeMonth: errorFreeMonth,
          errorTrusteeCode: errorTrusteeCode,
          errorTrusteeName: errorTrusteeName,
          messageStartDateError: messageStartDateError,
          messageAmountError: messageAmountError,
          messageAmountTaxError: messageAmountTaxError
        });

        this.$forceUpdate();
      }
    }
  }
}
</script>