import Model from './_base';

export default class SupplierBusinessBuyer extends Model {
    static entity = 'supplier';

    constructor(data) {
        super(SupplierBusinessBuyer.entity);
        if(data){
            Object.assign(this, data);
            return;
        }
    }

    static getExisting(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/buyer/existing'));
    }

    static getRefuse(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/buyer/refuse'));
    }

    static getOpen(supplierCode){
        return Model.axios.get(this.url('/' + supplierCode + '/buyer/open'));
    }
}