<template>
  <div>
    <div class="m-3">
      <SearchFree placeholder="サプライヤコード、サプライヤ名、主担当者名、主担当者メールアドレス"
        maxlength="100"
        v-model="form.conditions.fullText"
        @search="getSearchItems" />
      <div class="mt-2 row">
        <div class="col-auto">
          <MembershipCheckBox :model="form.conditions" />
        </div>
        <div class="col ml-3">
          <b-checkbox class="d-inline-block" v-model="form.conditions.byAntiSocial.hasNoDoubt">反社疑いなし</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byAntiSocial.isEvident">反社確定</b-checkbox>
          <b-checkbox class="d-inline-block ml-4" v-model="form.conditions.byAntiSocial.hasDoubt">反社疑いあり</b-checkbox>
        </div>
      </div>
    </div>
    <div class="collect">
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"/>
      <table class="table table-setting table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th class="text-center">サプライヤ</th>
            <th class="text-center">ステータス</th>
            <th class="text-center">最後更新日時</th>
            <th class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="form.result.length == 0">
            <td class="text-left" colspan="5">
              <LoadingList :loaded="loaded" :list="form.result" emptyData="データがございません。"></LoadingList>
            </td>
          </tr>
          <tr v-for="(item, index) in form.result" :key="`${index}`">
            <td>
              <router-link :to="{ name: 'SupplierDetailBusinessAvailable', params: { supplierCode: item.companyCode } }">
                <span v-on:click="saveSupplier(item)">
                    {{item.companyDisplayCode}}<br />
                    {{item.corporationNumber || '«未登録»'}}<br />
                    {{item.companyName || '«未登録»'}}<br />
                    {{item.mainUserName || '«未登録»'}}<br />
                    {{item.mainUserEmail || '«未登録»'}}</span>
              </router-link>
            </td>
            <td>
              <div>
                <p>確認された人数: {{item.antiSocietyInfo.checkedNames || '«未処理»'}}</p>
                <p>確認日時: {{item.antiSocietyInfo.checkedAt || '«未処理»'}}</p>
              </div>
              <div>
                <p>
                  <template v-if="item.antiSocialType == 'doubt'">
                    <span class="anti_social__check_result anti_social__check_result--doubt">疑いあり</span>
                    <b-button class="anti_social__change_button" variant="primary" v-on:click="show(item)">修正</b-button>
                  </template>
                  <template v-if="item.antiSocialType == 'anti'">
                    <span class="anti_social__check_result anti_social__check_result--anti">反社確定</span>
                    <b-button class="anti_social__change_button" variant="primary" v-on:click="show(item)">修正</b-button>
                  </template>
                  <template v-if="item.antiSocialType == 'none'">
                    <span class="anti_social__check_result anti_social__check_result--none">疑いなし</span>
                    <b-button class="anti_social__change_button" variant="primary" v-on:click="show(item)">修正</b-button>
                  </template>
                </p>
              </div>
            </td>
            <td>{{item.supplierDisplayCode}}<br />{{item.supplierName}}<br />{{item.personName}}<br />{{item.mailAddress}}</td>
            <td><font-awesome-icon class="anti_social__memo" 
                                   :icon="['far', 'edit']"
                                   v-on:click="showMemo(item.companyCode)"></font-awesome-icon></td>
          </tr>
        </tbody>
      </table>
      <Pagination class="mt-3 mx-0 w-100" :pagination="form.paging" @loadList="getSearchItems"/>
    </div>
    <modal name="anti-social-update-modal"
       :draggable="true"
       :resizable="true"
       height="400">
      <div class="anti_social__change-contents">
        <!--<div class="anti_social__change-contents_information">
          <p class="anti_social__change-contents_information_content">法人番号：{{form.judge.corporationNumber}}</p>
          <p class="anti_social__change-contents_information_content">社名：{{form.judge.supplierName}}</p>
          <p class="anti_social__change-contents_information_content">代表者名：{{form.judge.personName}}</p>
        </div>
        <p>
          <b-form-radio-group v-model="form.judge.antiSocialType">
            <b-form-radio value="none">反社疑いなし</b-form-radio>
            <b-form-radio value="doubt">
              <div>反社疑いあり「反社疑いありの場合は理由を選択してください」</div>
              <DoubtReasonSelect :model="form.judge" />
              <div class="anti_social__doubt_explanation">
                <p class="anti_social__doubt_explanation_content">「反社疑いあり」と設定した場合：</p>
                <p class="anti_social__doubt_explanation_content">・Biz取引のサプライヤには入金お断りの通知メールが送信されます。</p>
                <p class="anti_social__doubt_explanation_content">・直取引のバイヤには通知メールが送付され、「疑いあり」表示されます。</p>
              </div>
            </b-form-radio>
            <b-form-radio value="anti">反社確定</b-form-radio>
          </b-form-radio-group>
        </p>
        <div class="anti_social__update_actions">
          <b-button variant="primary" size="mt-2" v-on:click="onSave">判定</b-button>
          <b-button variant="light" size="mt-2" v-on:click="hide">閉じる</b-button>
        </div>-->
      </div>  
    </modal>
    <modal name="memo-modal"
       :draggable="true" 
       :resizable="true"
       :scrollable="true"
       height="auto">
       <Memo :supplierCode="form.memoSupplierCode" />
    </modal>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/table.scss";

</style>

<script>
import SupplierSearch from '@/model/SupplierSearch'
import SupplierAntiSocial from '@/model/antisocial/SupplierAntiSocial'

import Pagination from "@/components/molecule/Pagination.vue"
import LoadingList from "@/components/atoms/LoadingList.vue"
import SearchFree from "@/components/molecule/SearchFree.vue"
import MembershipCheckBox from "@/components/atoms/membership/MembershipCheckBox.vue"
import Memo from "@/components/organism/Memo.vue"
//import DoubtReasonSelect from "@/components/atoms/antisocial/DoubtReasonSelect.vue"

export default {
  components: { 
    Pagination, LoadingList, SearchFree, 
    MembershipCheckBox, Memo, //DoubtReasonSelect
  },
  props: [ 'saveSupplier' ],
  data: () => {
    return {
      form: {
        conditions: {
          byScore: {},
          byCompany: {},
          byOther: {},
          byAntiSocial: {},
          isOpenGuideExclusion: [],
          isOpenSupplier: []
        },
        paging: {
          sorting: []
        },
        result: []
      },
      loaded: true,
      antiSocialCheckResults: [
        { text: '反社疑いなし', value: 'none' },
        { text: '反社確定', value: 'anti' },
        { text: '反社疑いあり', value: 'doubt' }
      ],
      membership: [
        { text: '会員', value: true },
        { text: '非会員', value: false }
      ]
    }
  },
  created() {
    if(this.$store.state.pages.antisocial){
        this.form.conditions = this.$store.state.pages.antisocial.conditions || this.form.conditions;
        this.form.paging = this.$store.state.pages.antisocial.paging || this.form.paging;
      }
      this.getSearchItems();
  },
  methods: {
    getSearchItems() {
      SupplierSearch.getAntiSocial(this.form).then(result => {
        this.form = result.data;
        this.loaded = true;
        this.$store.commit('pages', { 
          antisocial: {
            paging: this.form.paging,
            conditions: this.form.conditions  
          }
        });
      }).catch(reason => { this.$errorMessage(reason) });
    },
    show : function(item) {
      this.form.judge = Object.assign({}, this.form.judge, {
        supplierCode: item.companyCode,
        supplierName: item.supplierName,
        personName: item.personName,
        corporationNumber: item.corporationNumber,
        antiSocialType: item.antiSocialType,
        antiDoubtReason: item.antiDoubtReason
      })
      this.$modal.show('anti-social-update-modal');
    },
    hide : function () {
      this.$modal.hide('anti-social-update-modal');
    },
    onSave() {
      this.$swal.confirm("反社区分を更新します。よろしいでしょうか？", undefined, () => {
        Promise.all([
          SupplierAntiSocial.save({
            supplierCode: this.form.judge.supplierCode,
            antiSocialType: this.form.judge.antiSocialType,
            antiDoubtReason: this.form.judge.antiDoubtReason,
          })
        ]).then(() => {
          this.$swal.success("更新しました", undefined, () => {
            this.getSearchItems()
          });
        }).catch(reason => {
          this.$errorMessage(reason)
        })
      });
    },
    showMemo : function(supplierCode) {
      this.form.memoSupplierCode = supplierCode
      this.$modal.show('memo-modal');
    },
    hideMemo : function () {
      this.$modal.hide('memo-modal');
    },
  }
}
</script>