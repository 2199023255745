<template>
  <input type="text" v-model="text" @keydown="keyDown" @blur="$emit('change')" />
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    maxlength: {
      type: String,
      default: "9"
    },
    defaultValue: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      text: this.getOriginNumber(this.value).toLocaleString("en-US"),
      stopInput: false,
      selectionReverseIndex: null
    };
  },
  watch: {
    value(value, previousValue) {
      if (value && value.toString().length > this.maxlength) {
        value = previousValue;
      }
      this.stopInput = value && value.toString().length.toString() === this.maxlength;

      this.text = this.getOriginNumber(value).toLocaleString("en-US");
    },
    text() {
      // get new text
      var originNumber = this.getOriginNumber(this.text.replace(/(,)|(-)/g, "") || this.defaultValue);
      var newText = originNumber.toLocaleString("en-US");

      // handle selection
      this.selectionReverseIndex = this.text.length - this.$el.selectionStart;
      if (newText !== this.text) {
        let selectionIndex = newText.length - this.selectionReverseIndex;
        this.$nextTick(() => {
          this.$el.selectionStart = this.$el.selectionEnd = selectionIndex;
        });
      }

      // update value
      this.text = newText;
      this.$emit("input", originNumber);
    }
  },
  methods: {
    getOriginNumber(value) {
      var originNumber = parseInt(value);
      if (isNaN(originNumber)) {
        originNumber = "";
      }

      return originNumber;
    },
    keyDown(e) {
      if (
        // Allow: delete, backspace, tab, escape, enter, F5
        [46, 8, 9, 27, 13, 116].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl + A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl + C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl + X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }

      // Prevent if not allow input
      if (this.stopInput && this.$el.value.substring(this.$el.selectionStart, this.$el.selectionEnd) === "") {
        e.preventDefault();
        return;
      }

      // Ensure that it is a number
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && // not is number
        (e.keyCode < 96 || e.keyCode > 105) // not is number
      ) {
        e.preventDefault();
      }
    }
  }
};
</script>
